import { useMutation, useQuery } from '@apollo/client';
import { Button, Popconfirm, Space, Table, Typography } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container } from '../../components';
import { Access, OrderByDirection } from '../../lib/graphql/globalTypes';
import { DELETE_PRODUCT_CATEGORY } from '../../lib/graphql/mutation/products';
import { DeleteProductCategory, DeleteProductCategoryVariables } from '../../lib/graphql/mutation/products/__generated__/DeleteProductCategory';
import { PRODUCT_CATEGORIES } from '../../lib/graphql/query/products';
import { ProductCategories as ProductCategoriesData, ProductCategoriesVariables } from '../../lib/graphql/query/products/__generated__/ProductCategories';
import { Viewer } from '../../lib/types';
import { authorized, displayErrorMessage, displaySuccessNotification } from '../../lib/utils';
import { AddEditProductCategory } from '../../modals';
import { productCategoriesStyles } from './styles';

interface Props {
  viewer: Viewer
}

const { Text } = Typography;

export const ProductCategories: React.FC<Props> = ({ viewer }) => {
  const styles = productCategoriesStyles();

  const [modalActive, setModalActive] = useState<boolean | string>(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [order, setOrder] = useState({ field: 'name', direction: OrderByDirection.ASC });
  const { data, loading, refetch } = useQuery<ProductCategoriesData, ProductCategoriesVariables>(PRODUCT_CATEGORIES, {
    variables: {
      page,
      total: 20,
      orderBy: order,
    },
  });

  const [deleteProductCategory] = useMutation<DeleteProductCategory, DeleteProductCategoryVariables>(DELETE_PRODUCT_CATEGORY, {
    onCompleted: (productCategory) => {
      if (productCategory.deleteProductCategory) {
        displaySuccessNotification('Product Category deleted successfully!');
        refetch();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Error deleting Product Category');
      }
    },
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    authorized({
      viewer,
      access: Access.ALTER_STOCK_REQUESTS,
    }) ? {
        title: 'Actions',
        key: 'actions',
        render: (productCategory: {id: string, name: string}) => (
          <Space>
            <Button
              type="link"
              onClick={() => setModalActive(productCategory.id)}
            >
              Edit
            </Button>
            <Popconfirm
              title="Are you sure you want to delete this category?"
              onConfirm={() => deleteProductCategory({ variables: { id: productCategory.id } })}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link">
                <Text type="danger">
                  Delete
                </Text>
              </Button>
            </Popconfirm>
          </Space>
        ),
      } : {},
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTableChange = (pagination: any, _filters: unknown, sorter: any) => {
    if (pagination?.current) {
      setPage(pagination.current);
    }
    if (pagination?.size) {
      setLimit(pagination.size);
    }
    if (sorter?.columnKey) {
      setOrder({
        field: sorter.columnKey,
        direction: sorter.order === 'ascend' ? OrderByDirection.ASC : OrderByDirection.DESC,
      });
    }
  };

  const onModalClose = () => {
    setModalActive(false);
    refetch();
  };

  return (
    <Container className={styles.container}>
      <Link to="/stock-requests">
        Go Back To Stock Requests
      </Link>
      <div className={styles.titleContainer}>
        <h1>View All Product Categories</h1>
        {
            authorized({
              viewer,
              access: Access.ALTER_STOCK_REQUESTS,
            }) && (
              <Button
                type="primary"
                onClick={() => setModalActive(true)}
              >
                Create Product Category
              </Button>
            )
          }

      </div>

      <div className={styles.sectionContainer}>
        <Table
          columns={columns}
          dataSource={data?.productCategories?.items}
          scroll={{ x: 'max-content' }}
          loading={loading}
          pagination={{
            defaultPageSize: limit,
            defaultCurrent: page,
            total: data?.productCategories?.total,
          }}
          onChange={handleTableChange}
          rowKey="id"
        />
      </div>
      {
        modalActive && (
          <AddEditProductCategory
            active={modalActive}
            onClose={onModalClose}
          />
        )
      }
    </Container>
  );
};
