import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { Container } from '../../components';
import { Access, Role } from '../../lib/graphql/globalTypes';
import { Viewer } from '../../lib/types';
import { authorized } from '../../lib/utils';
import { pricingDocsStyles } from './styles';
import { GPC_PRICING_DOCUMENT } from '../../lib/graphql/query/gpcPricing';
import { GpcPricingDocument } from '../../lib/graphql/query/gpcPricing/__generated__/GpcPricingDocument';
import { base } from '../../styles';

interface Props {
  viewer: Viewer
}


export const PricingDocs: React.FC<Props> = ({ viewer }) => {
  const styles = pricingDocsStyles();

  const [generateGpcPricingDocument] = useLazyQuery<GpcPricingDocument>(GPC_PRICING_DOCUMENT, {
    onCompleted: (d) => {
      if (d.gpcPricingDocument) {
        const fetchDataModified = `data:application/xlsx;base64,${d.gpcPricingDocument}`;
        const a = document.createElement('a');
        a.href = fetchDataModified;
        a.download = 'pricing-document.xlsx';
        a.click();
      }
    },
  });
  return (
    <Container className={styles.container}>
      <p className={styles.overTitle}>Pricing Documents</p>
      <div className={styles.titleContainer}>
        <h1>Generate A Pricing Document</h1>
      </div>

      <div className={styles.sectionContainer}>
        <div className={styles.linksContainer}>
          {
            authorized({
              viewer,
              access: Access.ALTER_STOCK_REQUESTS,
              role: Role.ADMIN,
            }) && (
              <React.Fragment>
                <div>
                  <Link to="/pricing-docs/gpc-rates">
                    <Button
                      type="primary"
                    >
                      GPC Rates
                    </Button>
                  </Link>
                  <Link to="/pricing-docs/gpc-equipment">
                    <Button
                      type="primary"
                    >
                      GPC Equipment
                    </Button>
                  </Link>
                </div>
                <div
                  style={{
                    marginTop: base(2),
                  }}
                >
                  <Button
                    type="primary"
                    onClick={() => generateGpcPricingDocument()}
                  >
                    Generate Pricing Document
                  </Button>
                </div>
              </React.Fragment>

            )
          }
        </div>
      </div>
    </Container>
  );
};
