import { useQuery } from '@apollo/client';
import { Button, Space, Table } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { QaFeedbackStatus, Role } from '../../../../lib/graphql/globalTypes';
import { QA_FEEDBACKS } from '../../../../lib/graphql/query/qaFeedbacks';
import { QaFeedbacks, QaFeedbacksVariables } from '../../../../lib/graphql/query/qaFeedbacks/__generated__/QaFeedbacks';
import { Viewer } from '../../../../lib/types';
import { authorized } from '../../../../lib/utils';
import { CreateUpdateQaFeedback } from '../../../../modals';
import { colors } from '../../../../styles';
import { techFeedbacksTableStyles } from './styles';

interface Props {
  status: 'pending' | 'open' | 'closed';
  viewer: Viewer;
}

export const TechFeedbackTable: React.FC<Props> = ({
  status,
  viewer,
}) => {
  const styles = techFeedbacksTableStyles();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const { data, refetch } = useQuery<QaFeedbacks, QaFeedbacksVariables>(QA_FEEDBACKS, {
    variables: {
      status,
      page,
      total: 20,
      lookup: null,
    },
  });

  const [modalActive, setModalActive] = useState(false);
  const [feedbackId, setFeedbackId] = useState('');
  const [feedback, setFeedback] = useState(null);

  const columns = [
    {
      title: 'Date',
      dataIndex: 'job',
      key: 'date',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (item: any) => dayjs(item.realEnd).format('DD/MM/YYYY'),
    },
    {
      title: 'Technician',
      dataIndex: 'user',
      key: 'technician',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (user: any) => `${user.firstName} ${user.lastName}`,
    },
    {
      title: 'Job Reference',
      dataIndex: 'job',
      key: 'jobReference',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (item: any) => item.ref,
    },
    {
      title: 'Job Type',
      dataIndex: 'job',
      key: 'jobType',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (item: any) => item.type,
    },
    {
      title: 'rating',
      dataIndex: 'rating',
      key: 'rating',
      render: (rating: number) => {
        const stars = [];
        for (let i = 0; i < 5; i += 1) {
          if (i < rating) {
            stars.push(<span key={i}><span style={{ color: colors.primary }}>&#9733;</span></span>);
          } else {
            stars.push(<span key={i}>&#9734;</span>);
          }
        }
        return stars;
      },
    },
    authorized({
      viewer,
      role: Role.MANAGER,
    }) ? {
        title: 'Actions',
        key: 'actions',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        render: (item: any) => (
          <Space>
            <Button
              type="link"
              onClick={() => {
                setFeedbackId(item.id || null);
                setFeedback(item || null);
                setModalActive(true);
              }}
            >
              {
                // eslint-disable-next-line no-nested-ternary
                !item.status ? 'Create' : item.status === QaFeedbackStatus.OPEN ? 'Update' : ''
              }
            </Button>
          </Space>
        ),
      } : {},
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTableChange = (pagination: any) => {
    if (pagination?.current) {
      setPage(pagination.current);
    }
    if (pagination?.size) {
      setLimit(pagination.size);
    }
    // if (sorter?.columnKey) {
    //   setOrder({
    //     field: sorter.columnKey,
    //     direction: sorter.order === 'ascend' ? OrderByDirection.ASC : OrderByDirection.DESC,
    //   });
    // }
  };

  return (
    <div className={styles.container}>
      <Table
        dataSource={data?.qaFeedbacks.items}
        columns={columns}
            // dataSource={data?.defaults?.items}
        scroll={{ x: 'max-content' }}
            // loading={loading}
        pagination={{
          defaultPageSize: limit,
          defaultCurrent: page,
          // total: data?.defaults?.total,
        }}
        onChange={handleTableChange}
        rowKey="id"
      />
      <CreateUpdateQaFeedback
        active={modalActive}
        onClose={() => setModalActive(false)}
        feedbackId={feedbackId}
        feedback={feedback}
        viewer={viewer}
        refetch={refetch}
      />
    </div>
  );
};
