import React, { useContext } from 'react';
import { Access, Role } from '../../lib/graphql/globalTypes';
import { ViewerContext } from '../../lib/utils/ViewerContext';

interface Props {
  children: React.ReactNode;
  access?: Access;
  role?: Role
}

export const PrivateRoute: React.FC<Props> = ({
  children,
  access,
  role,
}) => {
  const [viewer] = useContext(ViewerContext);
  if (!viewer.id) {
    return null;
  }

  if (viewer.role !== Role.SUPER_ADMIN) {
    if (role && !viewer.role?.includes(role)) {
      return null;
    }

    if (access && !viewer.access?.includes(access)) {
      return null;
    }
  }

  return (
    <React.Fragment>{children}</React.Fragment>
  );
};
