import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { CommissionStatus, OrderByDirection } from '../../lib/graphql/globalTypes';
import { CREATE_COMMISSION } from '../../lib/graphql/mutation/commissions';
import { CreateCommission as CreateCommissionData, CreateCommissionVariables } from '../../lib/graphql/mutation/commissions/__generated__/CreateCommission';
import { USERS } from '../../lib/graphql/query/users';
import { Users, UsersVariables } from '../../lib/graphql/query/users/__generated__/Users';
import { capitalCase, displayErrorMessage, displaySuccessNotification } from '../../lib/utils';
import { modalWidth } from '../../styles/sizes';
import { createCommissionStyles } from './styles';

interface Props {
  visible: boolean;
  onClose: () => void;
}

const { Item } = Form;

export const CreateCommission: React.FC<Props> = ({
  visible,
  onClose,
}) => {
  const styles = createCommissionStyles();

  const { data: users } = useQuery<Users, UsersVariables>(USERS, {
    variables: {
      page: 1,
      total: 1000,
      lookup: '',
      orderBy: {
        direction: OrderByDirection.ASC,
        field: 'email',
      },
    },
  });

  const [createCommission] = useMutation<CreateCommissionData, CreateCommissionVariables>(CREATE_COMMISSION, {
    onCompleted: (data) => {
      if (data.createCommission) {
        displaySuccessNotification('Successfully created commission');
        onClose();
      }
    },
    onError: () => {
      displayErrorMessage('Sorry! We were unable to create the commission. Please try again later!');
    },
  });

  const [form] = Form.useForm();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (values: any) => {
    createCommission(
      {
        variables: {
          input: {
            reference: values.reference,
            riskLeadReference: values.riskLeadReference,
            client: values.client,
            status: values.status,
            totalExVat: values.totalExVat,
            userIds: values.userIds,
            createdAt: values.createdAt,
            notes: values.notes,
            technicianNotes: values.technicianNotes,
          },
        },
      },
    );
  };

  return (
    <Modal
      open={visible}
      title="Create Commission"
      width={modalWidth}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
    >
      <div className={styles.container}>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Row gutter={24}>
            <Col
              span={12}
            >
              <Item
                name="reference"
                label="Reference"
              >
                <Input />
              </Item>
            </Col>
            <Col
              span={12}
            >
              <Item
                name="riskLeadReference"
                label="Risk Lead Reference"
              >
                <Input />
              </Item>
            </Col>
          </Row>
          <Item
            name="client"
            label="Client"
          >
            <Input />
          </Item>
          <Item
            name="status"
            label="Status"
            initialValue={CommissionStatus.PENDING}
          >
            <Select>
              {
                Object.keys(CommissionStatus).map((status) => (
                  <Select.Option
                    key={status}
                    value={status}
                  >
                    {capitalCase(status)}
                  </Select.Option>
                ))
              }
            </Select>
          </Item>
          <Item
            name="totalExVat"
            label="Total Ex Vat"
          >
            <InputNumber
              prefix="£"
              style={{ width: '100%' }}
            />
          </Item>
          <Item
            name="userIds"
            label="users"
          >
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Please select"
              filterOption={(inputValue, option) => option?.children?.join('').toLowerCase().includes(inputValue.toLowerCase()) || false}
            >
              {
                users?.users?.items.map((user) => (
                  <Select.Option
                    key={user.id}
                    value={user.id}
                  >
                    {user.firstName}
                    {' '}
                    {user.lastName}
                  </Select.Option>
                ))
              }
            </Select>
          </Item>
          <Item
            name="createdAt"
            label="Created"
            initialValue={dayjs()}
          >
            <DatePicker />
          </Item>
          <Item
            name="notes"
            label="Notes"
          >
            <Input.TextArea />
          </Item>
          <Item
            name="technicianNotes"
            label="Technician Notes"
          >
            <Input.TextArea />
          </Item>
        </Form>
        <div className={styles.buttonContainer}>
          <Button
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => form.submit()}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};
