import React from 'react';
import { Color } from '../../styles';
import { statCardStyles } from './styles';

interface Props {
  icon?: React.ReactNode;
  title: string;
  value: string;
  subOne?: string;
  subTwo?: string;
  color?: Color;
}

export const StatCard: React.FC<Props> = ({
  icon,
  title,
  value,
  subOne,
  subTwo,
  color = 'white',
}) => {
  const styles = statCardStyles({ backgroundColor: color });

  return (
    <div className={styles.container}>
      <span className={styles.innerTitleContainer}>
        {
          icon && (
            <span className={styles.icon}>
              {icon}
            </span>
          )
        }
        {title}
      </span>
      <h2>{value}</h2>
      <span className={styles.subValuesContainer}>
        <span>{subOne}</span>
        <span>{subTwo}</span>
      </span>
    </div>
  );
};
