import { Form, FormInstance, Input, Select } from 'antd';
import React, { useState } from 'react';

interface Props {
  form: FormInstance
}

const { Item } = Form;
const { Option } = Select;

export const CustomerDetail: React.FC<Props> = ({
  form,
}) => {
  const [isExistingCustomer, setIsExistingCustomer] = useState(false);
  const [isSmallBusiness, setIsSmallBusiness] = useState(false);
  return (
    <React.Fragment>
      <Item
        name="customerName"
        label="Customer Name"
        required
      >
        <Input />
      </Item>
      <Item
        name="customerAddress"
        label="Customer Address"
      >
        <Input.TextArea />
      </Item>
      <Item
        name="existingCustomer"
        label="Is this an existing customer?"
        required
        initialValue="no"
      >
        <Select
          onChange={(value) => {
            setIsExistingCustomer(value === 'yes');
            if (value !== 'yes') {
              form.setFieldsValue({
                registeredCompany: null,
              });
            }
          }}
        >
          <Option value="no">No</Option>
          <Option value="yes">Yes</Option>
        </Select>
      </Item>
      {
        isExistingCustomer ? (
          <React.Fragment>
            <Item
              name="registeredCompany"
              label="Is this a registered company?"
              initialValue="no"
            >
              <Select>
                <Option value="no">No</Option>
                <Option value="yes">Yes</Option>
              </Select>
            </Item>
            <Item
              name="smallBusiness"
              label="Is this a small business or sole trader?"
              initialValue="no"
            >
              <Select
                onChange={(value) => {
                  setIsSmallBusiness(value === 'yes');
                  if (value !== 'yes') {
                    form.setFieldsValue({
                      registeredCompany: null,
                    });
                  }
                }}
              >
                <Option value="no">No</Option>
                <Option value="yes">Yes</Option>
              </Select>
            </Item>
            <Item
              name="sourceWork"
              label="Source of work"
            >
              <Select>
                <Option value="enquiry">Enquiry</Option>
                <Option value="enquiry">Enquiry</Option>
                <Option value="enquiry">Enquiry</Option>
                <Option value="enquiry">Enquiry</Option>
                <Option value="enquiry">Enquiry</Option>
                <Option value="enquiry">Enquiry</Option>
              </Select>
            </Item>
            <Item
              name="customerType"
              label="Customer Type"
            >
              <Select>
                <Option value="enquiry">Enquiry</Option>
                <Option value="enquiry">Enquiry</Option>
                <Option value="enquiry">Enquiry</Option>
                <Option value="enquiry">Enquiry</Option>
                <Option value="enquiry">Enquiry</Option>
                <Option value="enquiry">Enquiry</Option>
              </Select>
            </Item>
            {
              isSmallBusiness ? (
                <Item
                  name="directDebitRejectedReason"
                  label="If a small business / trader and NOT agreed to direct debit, who has authorised that we will provide credit terms?"
                >
                  <Input.TextArea />
                </Item>
              ) : null
            }
          </React.Fragment>
        ) : null
      }
      <Item
        name="contegoSurveyerName"
        label="Contego Surveyer Name"
        required
      >
        <Input />
      </Item>
      <Item
        name="contegoReportNumber"
        label="Contego Report Number"
        required
      >
        <Input />
      </Item>
    </React.Fragment>

  );
};
