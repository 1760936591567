import { Pie, PieConfig } from '@ant-design/plots';
import { Datum } from '@antv/g2plot';
import React from 'react';
import { base, colors } from '../../../../styles';

interface Props {
  veryHigh: number | undefined
  high: number | undefined
  medium: number | undefined
  low: number | undefined
}

export const ActionsDonutPlot: React.FC<Props> = ({ veryHigh, high, medium, low }) => {
  const data: Datum[] = [
    {
      type: 'Very High',
      value: veryHigh,
    },
    {
      type: 'High',
      value: high,
    },
    {
      type: 'Medium',
      value: medium,
    },
    {
      type: 'Low',
      value: low,
    },
  ];
  const config: PieConfig = {
    style: {
      height: base(10),
      padding: base(0.5),
    },
    data,
    color: ({ type }: Datum) => {
      switch (type) {
        case 'Very High':
          return colors.black;
        case 'High':
          return colors.red;
        case 'Medium':
          return colors.amber;
        case 'Low':
          return colors.primary;
        default:
          return colors.grey;
      }
    },
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.5,
  };
  return (
    <Pie {...config} />
  );
};
