import gql from 'graphql-tag';

export const QA_FEEDBACKS = gql`
  query QaFeedbacks($status: String!, $total: Int!, $page: Int!, $lookup: String) {
    qaFeedbacks(status: $status, total: $total, page: $page, lookup: $lookup) {
      total
      items {
        id
        user {
          id
          firstName
          lastName
        }
        job {
          id
          jobId
          ref
          type
          realEnd
        }
        rating
        status
        comments {
          id
          user {
            id
            firstName
            lastName
          }
          message
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
