import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Col, Form, Input, InputNumber, Modal, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { PrivateRoute } from '../../components';
import { Access, Region, Role } from '../../lib/graphql/globalTypes';
import { EDIT_USER } from '../../lib/graphql/mutation/users';
import { EditUser as EditUserData, EditUserVariables } from '../../lib/graphql/mutation/users/__generated__/EditUser';
import { TEAMS } from '../../lib/graphql/query/teams';
import { Teams, TeamsVariables } from '../../lib/graphql/query/teams/__generated__/Teams';
import { USER } from '../../lib/graphql/query/users';
import { User, UserVariables } from '../../lib/graphql/query/users/__generated__/User';
import { capitalCase, displayErrorMessage, displaySuccessNotification } from '../../lib/utils';
import { modalWidth } from '../../styles/sizes';
import { editUserStyles } from './styles';

interface Props {
  active: boolean | string;
  onClose: () => void;
}

const { Item } = Form;

export const EditUser: React.FC<Props> = ({
  active,
  onClose,
}) => {
  const styles = editUserStyles();
  const [form] = Form.useForm();
  const [role, setRole] = useState('');
  const [getUser, { data, loading }] = useLazyQuery<User, UserVariables>(USER, {
    variables: {
      id: active as string,
      includes: ['teams'],
    },
    onCompleted: (item) => {
      setRole(item.user.role);
    },
  });

  const { data: teams } = useQuery<Teams, TeamsVariables>(TEAMS, {
    variables: {
      page: 1,
      total: 50,
    },
  });

  const [editUser, { loading: editLoading }] = useMutation<EditUserData, EditUserVariables>(EDIT_USER, {
    onCompleted: (editData) => {
      if (editData.editUser.id) {
        displaySuccessNotification('User edited successfully');
        onClose();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage(err.message);
      }
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (values: any) => {
    if (values.password) {
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      if (!passwordRegex.test(values.password)) {
        displayErrorMessage('Password must be at least 8 characters, 1 uppercase, 1 lowercase, 1 number, 1 special character');
        return;
      }
    }

    editUser({
      variables: {
        input: {
          id: active as string,
          ...values,
        },
      },
    });
  };

  useEffect(() => {
    form.resetFields();
  }, [form, data, active]);

  useEffect(() => {
    if (active) {
      getUser();
    }
  }, [getUser, active]);

  return (
    <PrivateRoute
      role={Role.ADMIN}
      access={Access.ALTER_USERS}
    >
      <Modal
        title="Edit User"
        open={Boolean(active)}
        onCancel={onClose}
        onOk={() => form.submit()}
        confirmLoading={editLoading || loading}
        width={modalWidth}
      >
        <div className={styles.container}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={data?.user}
          >
            <Row gutter={12}>
              <Col
                span={24}
                md={12}
              >
                <Item
                  name="firstName"
                  label="First Name"
                  required
                >
                  <Input />
                </Item>
              </Col>
              <Col
                span={24}
                md={12}
              >
                <Item
                  name="lastName"
                  label="Last Name"
                  required
                >
                  <Input />
                </Item>
              </Col>
            </Row>
            <Item
              name="jobTitle"
              label="Job Title"
            >
              <Input />
            </Item>
            <Item
              name="email"
              label="Email"
              required
              rules={[{
                type: 'email',
              }]}
            >
              <Input />
            </Item>
            <Item
              name="phoneNumber"
              label="Phone Number"
            >
              <Input />
            </Item>
            <Item
              name="password"
              label="Password"
              required
            >
              <Input.Password />
            </Item>
            <Row gutter={12}>
              <Col
                span={24}
                md={12}
              >
                <Item
                  name="region"
                  label="region"
                >
                  <Select showSearch>
                    {
                      Object.values(Region).map((region) => (
                        <Select.Option
                          key={region}
                          value={region}
                        >
                          {capitalCase(region)}
                        </Select.Option>
                      ))
                    }
                  </Select>
                </Item>
              </Col>
              <Col
                span={24}
                md={12}
              >
                <Item
                  name="role"
                  label="role"
                  required
                >
                  <Select
                    onChange={(value) => setRole(value as string)}
                  >
                    {
                      Object.values(Role).map((option) => (
                        <Select.Option
                          key={option}
                          value={option}
                        >
                          {capitalCase(option)}
                        </Select.Option>
                      ))
                    }
                  </Select>
                </Item>
              </Col>
            </Row>
            <Item
              name="teamIds"
              label="Team"
              initialValue={data?.user?.teams?.map((team) => team.id)}
            >
              <Select mode="multiple">
                {
                  teams?.teams?.items.map((team) => (
                    <Select.Option
                      key={team.id}
                      value={team.id}
                    >
                      {team.name}
                    </Select.Option>
                  ))
                }
              </Select>
            </Item>
            {
              role.includes('ADMIN') && (
                <React.Fragment>
                  <h3>Access Rights</h3>
                  <Item
                    name="access"
                    label="Access Rights"
                  >
                    <Select
                      mode="multiple"
                      allowClear
                    >
                      {
                        Object.values(Access).map((access) => (
                          <Select.Option
                            key={access}
                            value={access}
                          >
                            {capitalCase(access)}
                          </Select.Option>
                        ))
                      }
                    </Select>
                  </Item>
                </React.Fragment>
              )
            }
            <Item
              name="revenueTarget"
              label="Revenue Target"
            >
              <InputNumber />
            </Item>
            <h3>Bigchange Integration</h3>
            <Row gutter={12}>
              <Col
                span={24}
                md={12}
              >
                <Item
                  name="resourceLabel"
                  label="Resource Label"
                >
                  <Input />
                </Item>
              </Col>
              <Col
                span={24}
                md={12}
              >
                <Item
                  name="resourceReference"
                  label="Resource Reference"
                >
                  <Input />
                </Item>
              </Col>
            </Row>
            <p>
              Ensure that the Resource Label is the exact same spelling that the technician has on Bigchange
            </p>
          </Form>
        </div>
      </Modal>
    </PrivateRoute>
  );
};
