import { createUseStyles } from 'react-jss';
import { ObjectiveStatus } from '../../lib/graphql/globalTypes';
import { base, colors } from '../../styles';

interface Props {
  status: ObjectiveStatus;
}

export const objectiveCardStyles = createUseStyles({
  container: {
    background: colors.light,
    padding: base(),
    maxWidth: base(18),
    maxHeight: base(15),
    position: 'relative',
    textAlign: 'left',
    paddingBottom: base(3),
    height: '100%',
  },
  trafficLightContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: base(),
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
  trafficLight: ({ status }: Props) => ({
    width: base(),
    height: base(),
    borderRadius: '50%',
    marginRight: base(0.5),
    '&.red': {
      border: `1px solid ${colors.red}`,
      backgroundColor: status === ObjectiveStatus.RED ? colors.red : 'transparent',
    },
    '&.amber': {
      border: `1px solid ${colors.amber}`,
      backgroundColor: status === ObjectiveStatus.AMBER ? colors.amber : 'transparent',
    },
    '&.green': {
      border: `1px solid ${colors.primary}`,
      backgroundColor: status === ObjectiveStatus.GREEN ? colors.primary : 'transparent',
    },
  }),
  priority: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: colors.black,
    width: base(3),
    height: base(3),
    clipPath: 'polygon(0 0, 100% 0, 100% 100%)',
    '& span': {
      color: colors.white,
      position: 'absolute',
      top: 0,
      right: base(0.3),
      fontWeight: 600,
    },
  },
  subObjectives: {

  },
  subObjective: {

  },
});
