import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React from 'react';
import { Viewer } from '../../lib/types';
import { formatDateAndTime } from '../../lib/utils';
import { commentsStyles } from './styles';

interface Props {
  comments: {
    id: string;
    user: {
      id: string;
      firstName: string;
      lastName: string;
    },
    message: string;
    createdAt: string;
  }[] | null;
  viewer: Viewer
  onComposeMessage: (message: string) => void;
}

const { Item } = Form;

export const Comments: React.FC<Props> = ({
  comments,
  viewer,
  onComposeMessage,
}) => {
  const styles = commentsStyles();

  const [form] = useForm();

  if (!comments) return null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (values: any) => {
    onComposeMessage(values.message);
    form.resetFields();
  };

  return (
    <div className={styles.container}>
      <div className={styles.comments}>
        {comments.map((comment) => (
          <div
            className={`${styles.comment} ${viewer.id === comment.user.id ? styles.commentRight : styles.commentLeft}`}
            key={comment.id}
          >
            <div className={styles.commentHeader}>
              <div className={styles.commentUser}>
                <p>
                  {comment.user.firstName}
                  {' '}
                  {comment.user.lastName}
                </p>
              </div>
              <div className={styles.commentDate}>
                <p>{formatDateAndTime(comment.createdAt)}</p>
              </div>
            </div>
            <div className={`${styles.commentMessage} commentMessage`}>
              <p>{comment.message}</p>
            </div>
          </div>
        ))}
      </div>
      <Form
        className={styles.composeMessageForm}
        onFinish={onFinish}
        form={form}
      >
        <Item
          name="message"
          required
        >
          <Input placeholder="Type a new comment" />
        </Item>
        <Item className={styles.composeMessageButtonContainer}>
          <Button
            type="default"
            htmlType="submit"
          >
            Send
          </Button>
        </Item>
      </Form>
    </div>
  );
};
