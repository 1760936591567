import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Col, DatePicker, Form, Input, Modal, Row, Select, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { AlertStatus, OrderByDirection } from '../../lib/graphql/globalTypes';
import { CREATE_ALERT, UPDATE_ALERT } from '../../lib/graphql/mutation/alerts';
import { CreateAlert, CreateAlertVariables } from '../../lib/graphql/mutation/alerts/__generated__/CreateAlert';
import { UpdateAlert, UpdateAlertVariables } from '../../lib/graphql/mutation/alerts/__generated__/UpdateAlert';
import { ALERT } from '../../lib/graphql/query/alerts';
import { Alert, AlertVariables } from '../../lib/graphql/query/alerts/__generated__/Alert';
import { USERS } from '../../lib/graphql/query/users';
import { Users, UsersVariables } from '../../lib/graphql/query/users/__generated__/Users';
import { Viewer } from '../../lib/types';
import { displayErrorMessage, displaySuccessNotification, sentenceCase } from '../../lib/utils';
import { modalWidth } from '../../styles/sizes';
import { addEditAlertStyles } from './styles';

interface Props {
  active: boolean | string;
  onClose: () => void;
  viewer: Viewer;
}

const { Item } = Form;
const { Option } = Select;

export const AddEditAlert: React.FC<Props> = ({
  active,
  onClose,
  viewer,
}) => {
  const styles = addEditAlertStyles();

  const [userLookup, setUserLookup] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [users, setUsers] = useState<any | undefined>([]);

  const handleOnClose = () => {
    form.resetFields();
    onClose();
  };

  useQuery<Users, UsersVariables>(USERS, {
    variables: {
      page: 1,
      total: 1000,
      lookup: userLookup,
      orderBy: {
        direction: OrderByDirection.ASC,
        field: 'email',
      },
    },
    onCompleted: (data) => {
      setUsers(data?.users?.items);
    },
  });

  const [createAlert] = useMutation<CreateAlert, CreateAlertVariables>(CREATE_ALERT, {
    onCompleted: (data) => {
      if (data.createAlert) {
        displaySuccessNotification('Alert created successfully');
        handleOnClose();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Error creating Alert');
      }
    },
  });

  const [getExistingAlert] = useLazyQuery<Alert, AlertVariables>(ALERT);

  const [updateAlert] = useMutation<UpdateAlert, UpdateAlertVariables>(UPDATE_ALERT, {
    onCompleted: (data) => {
      if (data.updateAlert) {
        displaySuccessNotification('Alert updated successfully');
        handleOnClose();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Error updating Alert');
      }
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (values: any) => {
    if (active === true) {
      createAlert({
        variables: {
          input: {
            ...values,
          },
        },
      });
    } else {
      updateAlert({
        variables: {
          input: {
            id: active,
            ...values,
          },
        },
      });
    }
  };

  const [form] = useForm();

  useEffect(() => {
    if (typeof active === 'string') {
      getExistingAlert({
        variables: {
          id: active,
        },
      }).then(({ data }) => {
        form.setFieldsValue({
          ...data?.alert,
          date: dayjs(data?.alert?.date),
        });
      });
    }
  }, [active, getExistingAlert, form]);

  return (
    <Modal
      title={`${typeof active === 'boolean' ? 'Create' : 'Update'} Alert`}
      open={Boolean(active)}
      onCancel={handleOnClose}
      onOk={() => form.submit()}
      width={modalWidth}
    >
      <div className={styles.container}>
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
        >
          <Item
            name="title"
            label="Title"
            required
          >
            <Input />
          </Item>
          <Item
            name="body"
            label="Body"
            required
          >
            <TextArea rows={10} />
          </Item>
          <Item
            name="authorId"
            label="Author"
            required
            initialValue={viewer.id}
          >
            <Select
              onSearch={(value: string) => setUserLookup(value)}
              filterOption={false}
              showSearch
            >
              {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                users.map((user: any) => (
                  <Option
                    key={user.id}
                    value={user.id}
                  >
                    {user.firstName}
                    {' '}
                    {user.lastName}
                  </Option>
                ))
              }
              {
                !users.find((user: { id: string; }) => user.id === viewer.id) && (
                  <Option
                    key={viewer.id}
                    value={viewer.id}
                  >
                    {viewer.firstName}
                    {' '}
                    {viewer.lastName}
                  </Option>
                )
              }
            </Select>
          </Item>
          <Row gutter={24}>
            <Col span={12}>
              <Item
                name="status"
                label="Status"
                required
                initialValue={AlertStatus.DRAFT}
              >
                <Select>
                  {
                    Object.keys(AlertStatus).map((status) => (
                      <Option
                        key={status}
                        value={status}
                      >
                        {sentenceCase(status)}
                      </Option>
                    ))
                  }
                </Select>
              </Item>
            </Col>
            <Col span={6}>
              <Item
                name="date"
                label="Date"
                required
                initialValue={dayjs()}
              >
                <DatePicker />
              </Item>
            </Col>
            <Col span={6}>
              {
                typeof active === 'boolean' && (
                  <Item
                    name="pushNotify"
                    label="Send Push Notifications"
                    required
                  >
                    <Switch
                      checked={
                        form.getFieldValue('pushNotify')
                      }
                      onChange={(value) => form.setFieldsValue({ pushNotify: value })}
                    />
                  </Item>
                )
              }
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};
