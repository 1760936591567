import { BarChartOutlined, FilePdfOutlined, LayoutOutlined, LineChartOutlined, LogoutOutlined, MessageOutlined, MobileOutlined, NotificationOutlined, PoundOutlined, ReconciliationOutlined, TrophyOutlined, UnorderedListOutlined, UsergroupAddOutlined, UserOutlined, WarningOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Affix, Button, Menu } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Access, Role } from '../../../lib/graphql/globalTypes';
import { LOGOUT } from '../../../lib/graphql/mutation/logout';
import { Logout } from '../../../lib/graphql/mutation/logout/__generated__/Logout';
import { Viewer } from '../../../lib/types';
import { authorized, displayErrorMessage } from '../../../lib/utils';
import { Logo } from '../../graphics';
import { appSiderStyles } from './styles';
import { GetJobReport } from '../../../modals';

interface Props {
  viewer: Viewer;
  setViewer: (viewer: Viewer) => void;
}

const initialViewer: Viewer = {
  id: null,
  firstName: null,
  lastName: null,
  role: null,
  access: null,
  token: null,
  didRequest: true,
};

export const AppSider: React.FC<Props> = ({ setViewer, viewer }) => {
  const styles = appSiderStyles();
  const navigate = useNavigate();
  const [selected, setSelected] = useState<null | string[]>(null);
  const [getJobReportModalOpen, setGetJobReportModalOpen] = useState<boolean>(false);

  const [logout, { loading }] = useMutation<Logout>(LOGOUT, {
    onCompleted: (data) => {
      if (data.logout) {
        sessionStorage.removeItem('token');
        setViewer(initialViewer);
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Sorry! We were unable to log you out. Please try again later!');
      }
    },
  });

  const menuItems: ItemType[] = [
    {
      key: '',
      icon: <LayoutOutlined />,
      label: 'Dashboard',
    },
    authorized({ viewer, role: Role.ADMIN }) ? {
      key: 'users',
      icon: <UserOutlined />,
      label: 'Users',
    } : null,
    authorized({ viewer, role: Role.ADMIN }) ? {
      key: 'teams',
      icon: <UsergroupAddOutlined />,
      label: 'Teams',
    } : null,
    {
      key: 'alerts',
      icon: <NotificationOutlined />,
      label: 'Alerts',
    },
    authorized({ viewer, role: Role.ADMIN, access: Access.ALTER_RISK_LEADS }) ? {
      key: 'risk-leads',
      icon: <LineChartOutlined />,
      label: 'Risk Leads',
    } : null,
    {
      key: 'stock-requests',
      icon: <UnorderedListOutlined />,
      label: 'Stock Requests',
    },
    {
      key: 'commissions',
      icon: <BarChartOutlined />,
      label: 'Commissions',
    },
    {
      key: 'on-calls',
      icon: <MessageOutlined />,
      label: 'On Calls',
    },
    {
      key: 'apps',
      icon: <MobileOutlined />,
      label: 'Apps',
    },
    authorized({ viewer, role: Role.MANAGER }) ? {
      key: 'tech-feedback',
      icon: <ReconciliationOutlined />,
      label: 'Tech Feedback',
    } : null,
    authorized({ viewer, role: Role.MANAGER }) ? {
      key: 'objectives',
      icon: <TrophyOutlined />,
      label: 'Objectives',
    } : null,
    authorized({ viewer, role: Role.MANAGER }) ? {
      key: 'kpi-early-warning',
      icon: <WarningOutlined />,
      label: 'Early Warning',
    } : null,
    authorized({ viewer, role: Role.MANAGER }) ? {
      key: 'pricing-docs',
      icon: <PoundOutlined />,
      label: 'Pricing Docs',
    } : null,
    authorized({ viewer, role: Role.MANAGER }) ? {
      key: 'null-get-job-report',
      icon: <FilePdfOutlined />,
      label: 'Job Report',
      onClick: () => {
        setGetJobReportModalOpen(true);
      },
    } : null,
  ];

  const menuItemsRef = useRef(menuItems);

  const onMenuChange = ({ key }: { key: string }) => {
    if (!key.includes('null')) {
      navigate(`/${key}`);
      if (key) {
        setSelected([key]);
      } else {
        setSelected(['']);
      }
    }
  };

  useEffect(() => {
    // if pathname includes menuItem.key
    const selectedItem = menuItemsRef.current.find((menuItem) => menuItem?.key && window.location.pathname.includes(menuItem.key as string));
    if (selectedItem) {
      setSelected([selectedItem?.key as string]);
    } else {
      setSelected(['']);
    }
  }, [menuItemsRef]);

  return (
    <Affix
      offsetTop={0}
    >
      <Sider className={styles.container}>
        <div className={styles.logoContainer}>
          <Link to="/">
            <Logo size={75} />
          </Link>
        </div>
        <div className={styles.menuContainer}>
          {
            selected && (
              <Menu
                className={styles.menu}
                items={menuItems}
                onSelect={onMenuChange}
                onClick={onMenuChange}
                defaultSelectedKeys={selected}
              />
            )
          }
        </div>
        <div className={styles.optionContainer}>
          <Button
            type="default"
            className={styles.logoutButton}
            loading={loading}
            onClick={() => logout()}
          >
            <LogoutOutlined />
            Logout
          </Button>
        </div>
      </Sider>
      {
        getJobReportModalOpen && (
          <GetJobReport
            active={getJobReportModalOpen}
            onClose={() => setGetJobReportModalOpen(false)}
          />
        )
      }
    </Affix>
  );
};
