import { CalendarFilled, CalendarOutlined, DownCircleFilled, FullscreenExitOutlined, FullscreenOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { useQuery } from '@apollo/client';
import { Container } from '../../../components';
import { base } from '../../../styles';
import { InfoCard } from './components';
import { screenTwoStyles } from './styles';
import { DashboardScreenTwoData } from '../../../lib/graphql/query/misc/__generated__/DashboardScreenTwoData';
import { DASHBOARD_SCREEN_TWO_DATA } from '../../../lib/graphql/query/misc';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Pin = ({ name, lat, lng, color }: { name: string | undefined, lat: number, lng: number, color?: string }) => <DownCircleFilled style={{ opacity: 0.8, fontSize: 15, color: color || '#9ac325', border: '2px solid white', borderRadius: '15px', background: 'white', transform: 'translate(-15px, -30px)' }} />;


export const ScreenTwo: React.FC = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const styles = screenTwoStyles({
    isFullscreen,
  });

  const goFullscreen = () => {
    setIsFullscreen(!isFullscreen);
    if (document.fullscreenEnabled && !isFullscreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const { data, refetch } = useQuery<DashboardScreenTwoData>(DASHBOARD_SCREEN_TWO_DATA);


  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 1000 * 60 * 30);

    return () => clearInterval(interval);
  }, [refetch]);

  return (
    <Container
      className={styles.container}
    >
      <div className={styles.titleContainer}>
        {/* <span>Welcome Back</span>
        <h1>Contego (Latin): To Protect; To Shield; To Defend</h1> */}
        <Button
          onClick={goFullscreen}
          className={styles.fullscreenButton}
          type="link"
        >
          {
            isFullscreen ? (
              <FullscreenExitOutlined className={styles.fullscreenIcon} />
            ) : (
              <FullscreenOutlined className={styles.fullscreenIcon} />
            )
          }
        </Button>
      </div>
      <div className={styles.statCardsContainer}>
        <Row
          gutter={24}
          style={{ flex: 1 }}
        >
          <Col span={14}>
            <Row
              gutter={24}
              style={{ marginBottom: base() }}
            >
              <Col span={8}>
                <InfoCard
                  icon={<FullscreenOutlined />}
                  value={data?.dashboardScreenTwoData.activeTechs || 0}
                  label="Active Technicians"
                />
              </Col>
              <Col span={8}>
                <InfoCard
                  icon={<CalendarOutlined />}
                  value={data?.dashboardScreenTwoData.jobsCompletedToday || 0}
                  label="Jobs Completed (Today)"
                />
              </Col>
              <Col span={8}>
                <InfoCard
                  icon={<CalendarFilled />}
                  value={data?.dashboardScreenTwoData.jobsCompletedMonth || 0}
                  label="Jobs Completed (Month)"
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <InfoCard
                  icon={<SettingOutlined />}
                  value={data?.dashboardScreenTwoData.averageJobsCompletedPerTechToday || 0}
                  label="Average Jobs Completed Per Tech (Day)"
                />
              </Col>
              <Col span={8}>
                <InfoCard
                  icon={<FullscreenOutlined />}
                  value={data?.dashboardScreenTwoData.averageJobsCompletedPerTechMonth || 0}
                  label="Average Jobs Completed Per Tech (Month)"
                />
              </Col>
              <Col span={8}>
                <InfoCard
                  icon={<FullscreenOutlined />}
                  value={data?.dashboardScreenTwoData.percentageCallouts ? `${data?.dashboardScreenTwoData.percentageCallouts}%` : '0%'}
                  label="Percentage Call Outs"
                />
              </Col>
            </Row>
          </Col>
          <Col span={9}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string }}
              defaultCenter={{
                lat: 54.30,
                lng: -2.60,
              }}
              defaultZoom={6.3}
              options={{
                zoomControl: false,
              }}
            >
              {
                data && data?.dashboardScreenTwoData.resourceLiveData?.map((live) => (
                  <Pin
                    key={live.id}
                    name={live.resourceReference}
                    lat={live.positionLatitude}
                    lng={live.positionLongitude}
                  />
                ))
              }
            </GoogleMapReact>
          </Col>
        </Row>
      </div>
    </Container>
  );
};
