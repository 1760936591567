import { gql } from 'graphql-tag';

export const STOCK_REQUESTS = gql`
  query stockRequests($total: Int!, $page: Int!, $orderBy: OrderBy, $lookup: String, $userId: String, $status: StockRequestStatus) {
    stockRequests(total: $total, page: $page, orderBy: $orderBy, lookup: $lookup, userId: $userId, status: $status) {
      total
      items {
        id
        status
        lineItems {
          id
          name
          quantity
          price
          notes
        }
        total
        user {
          id
          firstName
          lastName
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const STOCK_REQUEST = gql`
  query stockRequest($id: ID!) {
    stockRequest(id: $id) {
      id
      status
      lineItems {
        id
        name
        quantity
        price
        notes
      }
      total
      user {
        id
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
  }
`;
