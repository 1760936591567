/* eslint-disable camelcase */
import { Table, Typography } from 'antd';
import React from 'react';
import { UserOverview_userOverview_jobs } from '../../../../lib/graphql/query/userOverview/__generated__/UserOverview';
import { formatDateAndTime, formatLargeNumbers } from '../../../../lib/utils';
import { colors } from '../../../../styles';
import { jobsTableStyles } from './styles';

interface Props {
  jobs: UserOverview_userOverview_jobs[] | null | undefined;
  page: number
  setPage: (page: number) => void
}

const { Text } = Typography;

export const JobsTable: React.FC<Props> = ({
  jobs,
  page,
  setPage,
}) => {
  const styles = jobsTableStyles();

  const columns = [
    {
      title: 'Reference',
      key: 'ref',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (item: any) => (
        <a
          style={{ color: colors.primary }}
          target="_blank"
          href={`https://client.bigchange.com/jobschedule.aspx?jobid=${item.jobId}`}
          rel="noreferrer"
        >
          {item.ref}
        </a>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        if (status === 'Completed') {
          return <Text type="success">{status}</Text>;
        }

        if (status === 'On the way') {
          return <Text type="warning">{status}</Text>;
        }

        if (status === 'Cancelled') {
          return <Text type="danger">{status}</Text>;
        }

        if (status === 'Completed with issues') {
          return <Text type="danger">{status}</Text>;
        }

        return status;
      },
    },
    {
      title: 'Total Price',
      key: 'revenue',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (item: any) => (item.revenue?.price ? `£${formatLargeNumbers(item.revenue?.price)}` : '£0'),
    },
    {
      title: 'Planned Start',
      dataIndex: 'plannedStart',
      key: 'plannedStart',
      render: formatDateAndTime,
    },
    {
      title: 'Real Start',
      dataIndex: 'realStart',
      key: 'realStart',
      render: formatDateAndTime,
    },
    {
      title: 'Real Finish',
      dataIndex: 'realEnd',
      key: 'realEnd',
      render: formatDateAndTime,
    },
  ];

  if (!jobs) {
    return null;
  }

  const onPageChange = (num: number) => {
    setPage(num);
  };

  return (
    <div className={styles.container}>
      <Table
        columns={columns}
        dataSource={jobs}
        rowKey="id"
        pagination={{
          defaultPageSize: 20,
          defaultCurrent: page,
          total: jobs.length,
          onChange: onPageChange,
        }}
      />
    </div>
  );
};
