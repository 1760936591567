import { createUseStyles } from 'react-jss';
import { base } from '../../styles';

export const addEarlyWarningStyles = createUseStyles({
  container: {

  },
  table: {
    marginBottom: base(0.5),
  },
});
