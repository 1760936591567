import gql from 'graphql-tag';

export const OBJECTIVES = gql`
  query Objectives($total: Int!, $page: Int!, $orderBy: OrderBy) {
    objectives(total: $total, page: $page, orderBy: $orderBy) {
      total
      items {
        id
        userId
        user {
          firstName
          lastName
        }
        teamId
        team {
          name
        }
        title
        description
        status
        isPrivate
        createdById
        completed
        archived
        priority
        subObjectives {
          id
          title
          completed
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const OBJECTIVE = gql`
  query Objective($id: ID!) {
    objective(id: $id) {
      id
        userId
        user {
          firstName
          lastName
        }
        teamId
        team {
          name
        }
        title
        description
        status
        isPrivate
        createdById
        completed
        archived
        priority
        subObjectives {
          id
          title
          completed
        }
        createdAt
        updatedAt
    }
  }
`;
