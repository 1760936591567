import { Button, Form, Modal, Steps } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { CustomerDetail } from './formParts';
import { SiteDetail } from './formParts/SiteDetails';
import { generateSalesDocumentStyles } from './styles';

export const GenerateSalesDocument: React.FC = () => {
  const styles = generateSalesDocumentStyles();
  const [step, setStep] = useState(0);

  const [form] = useForm();

  return (
    <Modal
      className={styles.container}
      width={800}
      footer={null}
    >
      <h2>Generate Sales Document</h2>
      <Steps
        current={step}
      >
        <Steps.Step title="Customer Details" />
        <Steps.Step title="Site Details" />
        <Steps.Step title="Invoicing & Payment" />
      </Steps>
      <Form
        layout="vertical"
      >
        {
          step === 0 && (
            <CustomerDetail
              form={form}
            />
          )
        }
        {
          step === 1 && (
            <SiteDetail
              form={form}
            />
          )
        }
      </Form>
      <div className={styles.buttonsContainer}>
        <Button
          type="default"
          className={styles.cancelButton}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={() => {
            setStep(step + 1);
          }}
        >
          Next
        </Button>
      </div>
    </Modal>
  );
};
