import { useMutation } from '@apollo/client';
import { Button, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from '../../components';
import { Logo } from '../../components/graphics';
import { RESET_PASSWORD } from '../../lib/graphql/mutation/users';
import { ResetPassword as ResetPasswordData, ResetPasswordVariables } from '../../lib/graphql/mutation/users/__generated__/ResetPassword';
import { displayErrorMessage, displaySuccessNotification } from '../../lib/utils';
import { resetPasswordStyles } from './styles';

const { Item } = Form;

export const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const styles = resetPasswordStyles();
  const [token, setToken] = useState('');
  const [resetPassword, { loading }] = useMutation<ResetPasswordData, ResetPasswordVariables>(RESET_PASSWORD, {
    onCompleted: (data) => {
      if (data && data.resetPassword) {
        displaySuccessNotification('You have successfully reset your password!');
        navigate('/');
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Sorry! We were unable to reset your password. Please try again later!');
      }
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (values: any) => {
    if (values.password !== values.confirmPassword) {
      displayErrorMessage('Passwords do not match!');
      return;
    }

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(values.password)) {
      displayErrorMessage('Password must be at least 8 characters, 1 uppercase, 1 lowercase, 1 number, 1 special character');
    }

    resetPassword({
      variables: {
        token,
        password: values.password,
        confirmPassword: values.confirmPassword,
      },
    });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenParam = urlParams.get('resetToken');
    if (tokenParam) {
      setToken(tokenParam);
    }
  }, []);

  if (!token) {
    return null;
  }

  return (
    <Container className={styles.container}>
      <div className={styles.modalContainer}>
        <div className={styles.logoContainer}>
          <Logo />
        </div>

        <h1 className={styles.title}>Reset Password</h1>
        <p>
          Enter a new password for your account.
        </p>
        <Form
          layout="vertical"
          onFinish={onFinish}
        >
          <Item
            name="password"
            label="Password"
            required
          >
            <Input.Password />
          </Item>
          <Item
            name="confirmPassword"
            label="Confirm Password"
            required
          >
            <Input.Password />
          </Item>
          <div className={styles.buttonContainer}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Reset Password
            </Button>
          </div>
        </Form>
      </div>
    </Container>
  );
};
