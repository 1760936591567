import { createUseStyles } from 'react-jss';
import { base } from '../../styles';

export const addEditObjectiveStyles = createUseStyles({
  container: {
    textAlign: 'left',
  },
  subObjectivesInput: {
    display: 'flex',
  },
  subObjectivesList: {
    marginBottom: base(),
  },
  subObjective: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});
