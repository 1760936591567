import { createUseStyles } from 'react-jss';
import { base } from '../../../../styles';

export const commissionsTableStyles = createUseStyles({
  container: {
    marginTop: base(2),
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: base(),
  },
  boldText: {
    fontWeight: 600,
  },
});
