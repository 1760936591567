import gql from 'graphql-tag';

export const USERS = gql`
  query Users($total: Int!, $page: Int!, $orderBy: OrderBy, $lookup: String) {
    users(total: $total, page: $page, orderBy: $orderBy, lookup: $lookup) {
      total,
      items {
        id
        firstName
        lastName
        email
        role
        region
        profileImageUrl
        jobTitle
        phoneNumber
        contactEnabled
        revenueTarget
        status
        access
      }
    }
  }
`;

export const USER = gql`
  query User($id: ID!, $includes: [String]) {
    user(id: $id, includes: $includes) {
      id
      firstName
      lastName
      email
      role
      region
      profileImageUrl
      jobTitle
      phoneNumber
      teams {
        id
        name
      }
      contactEnabled
      resourceReference
      resourceLabel
      revenueTarget
      access
    }
  }
`;
