import React from 'react';
import { loadingScreenStyles } from './styles';
import { Logo } from '../../graphics';

export const LoadingScreen: React.FC = () => {
  const styles = loadingScreenStyles();

  return (
    <div className={styles.container}>
      <div className={styles.loadingContainer}>
        <Logo
          className={styles.logo}
          size={200}
        />
      </div>
    </div>
  );
};
