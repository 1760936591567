import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Col, DatePicker, Form, Modal, Row, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { CREATE_ON_CALL, UPDATE_ON_CALL } from '../../lib/graphql/mutation/onCalls';
import { CreateOnCall, CreateOnCallVariables } from '../../lib/graphql/mutation/onCalls/__generated__/CreateOnCall';
import { UpdateOnCall, UpdateOnCallVariables } from '../../lib/graphql/mutation/onCalls/__generated__/UpdateOnCall';
import { ON_CALL } from '../../lib/graphql/query/onCall';
import { OnCall, OnCallVariables } from '../../lib/graphql/query/onCall/__generated__/OnCall';
import { USERS } from '../../lib/graphql/query/users';
import { Users, UsersVariables } from '../../lib/graphql/query/users/__generated__/Users';
import { displayErrorMessage, displaySuccessNotification } from '../../lib/utils';
import { modalWidth } from '../../styles/sizes';
import { addEditOnCall } from './styles';
import { OrderByDirection } from '../../lib/graphql/globalTypes';

interface Props {
  active: boolean | string;
  onClose: () => void;
}

const { Item } = Form;
const { Option } = Select;

export const AddEditOnCall: React.FC<Props> = ({
  active,
  onClose,
}) => {
  const styles = addEditOnCall();

  const [userLookup, setUserLookup] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [users, setUsers] = useState<any | undefined>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [existingUsers, setExistingUsers] = useState<any | undefined>([]);

  const handleOnClose = () => {
    form.resetFields();
    onClose();
  };

  useQuery<Users, UsersVariables>(USERS, {
    variables: {
      page: 1,
      total: 1000,
      lookup: userLookup,
      orderBy: {
        direction: OrderByDirection.ASC,
        field: 'email',
      },
    },
    onCompleted: (data) => {
      setUsers(data?.users?.items);
    },
  });

  const [createOnCall] = useMutation<CreateOnCall, CreateOnCallVariables>(CREATE_ON_CALL, {
    onCompleted: (data) => {
      if (data.createOnCall) {
        displaySuccessNotification('OnCall created successfully');
        handleOnClose();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Error creating OnCall');
      }
    },
  });

  const [getExistingOnCall] = useLazyQuery<OnCall, OnCallVariables>(ON_CALL);

  const [updateOnCall] = useMutation<UpdateOnCall, UpdateOnCallVariables>(UPDATE_ON_CALL, {
    onCompleted: (data) => {
      if (data.updateOnCall) {
        displaySuccessNotification('On Call updated successfully');
        handleOnClose();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Error updating On Call');
      }
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (values: any) => {
    if (active === true) {
      createOnCall({
        variables: {
          input: {
            ...values,
          },
        },
      });
    } else {
      updateOnCall({
        variables: {
          id: active as string,
          input: {
            ...values,
          },
        },
      });
    }
  };

  const [form] = useForm();

  useEffect(() => {
    if (typeof active === 'string') {
      getExistingOnCall({
        variables: {
          id: active,
        },
      }).then(({ data }) => {
        form.setFieldsValue({
          ...data?.onCall,
          startDate: dayjs(data?.onCall?.startDate),
          endDate: dayjs(data?.onCall?.endDate),
          userIds: data?.onCall?.users?.map((user) => user.id),
        });
        setExistingUsers(data?.onCall?.users);
      });
    }
  }, [active, getExistingOnCall, form]);

  return (
    <Modal
      title={`${typeof active === 'boolean' ? 'Create' : 'Update'} OnCall`}
      open={Boolean(active)}
      onCancel={handleOnClose}
      onOk={() => form.submit()}
      width={modalWidth}
    >
      <div className={styles.container}>
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Item
                name="startDate"
                label="Start Date"
                required
              >
                <DatePicker />
              </Item>
              <Item
                name="endDate"
                label="End Date"
                required
              >
                <DatePicker />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                name="userIds"
                label="Users"
                required
              >
                <Select
                  onSearch={(value: string) => setUserLookup(value)}
                  filterOption={false}
                  showSearch
                  mode="multiple"
                >
                  {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    users.map((user: any) => (
                      <Option
                        key={user.id}
                        value={user.id}
                      >
                        {user.firstName}
                        {' '}
                        {user.lastName}
                      </Option>
                    ))
                  }
                  {
                    // if any existingUsers no in users
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    existingUsers.map((user: any) => {
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      if (!users.find((u: any) => u.id === user.id)) {
                        return (
                          <Option
                            key={user.id}
                            value={user.id}
                          >
                            {user.firstName}
                            {' '}
                            {user.lastName}
                          </Option>
                        );
                      }
                      return null;
                    })
                  }
                </Select>
              </Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};
