import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Form, Row, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect } from 'react';
import { CREATE_OR_UPDATE_SETTING } from '../../../../lib/graphql/mutation/settings';
import { CreateOrUpdateSetting, CreateOrUpdateSettingVariables } from '../../../../lib/graphql/mutation/settings/__generated__/CreateOrUpdateSetting';
import { GET_JOB_TYPES } from '../../../../lib/graphql/query/misc';
import { GetJobTypes } from '../../../../lib/graphql/query/misc/__generated__/GetJobTypes';
import { SETTINGS } from '../../../../lib/graphql/query/settings';
import { Settings, SettingsVariables } from '../../../../lib/graphql/query/settings/__generated__/Settings';
import { displayErrorMessage, displaySuccessNotification } from '../../../../lib/utils';
import { riskLeadsSettingsStyles } from './styles';

const { Item } = Form;

export const RiskLeadsSettings: React.FC = () => {
  const styles = riskLeadsSettingsStyles();
  const { data: getJobTypesData } = useQuery<GetJobTypes>(GET_JOB_TYPES);
  const { data: existingSettingsData } = useQuery<Settings, SettingsVariables>(SETTINGS, {
    variables: {
      names: ['riskLeadJobTypes', 'riskLeadQuestions'],
    },
  });
  const [createOrUpdateSetting] = useMutation<CreateOrUpdateSetting, CreateOrUpdateSettingVariables>(CREATE_OR_UPDATE_SETTING, {
    onError: (err) => {
      if (err) {
        displayErrorMessage('Sorry! We weren\'t able to update your risk leads settings. Please try again later!');
      }
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (values: any) => {
    const jobTypesResult = await createOrUpdateSetting({
      variables: {
        input: {
          name: 'riskLeadJobTypes',
          value: JSON.stringify(values.jobTypes),
        },
      },
    });
    const questionsResult = await createOrUpdateSetting({
      variables: {
        input: {
          name: 'riskLeadQuestions',
          value: JSON.stringify(values.questions),
        },
      },
    });
    if (jobTypesResult.data?.createOrUpdateSetting.id && questionsResult.data?.createOrUpdateSetting.id) {
      displaySuccessNotification('You\'ve successfully updated your risk leads settings!');
    }
  };

  const [form] = useForm();

  useEffect(() => {
    if (existingSettingsData && existingSettingsData.settings) {
      const jobTypes = existingSettingsData.settings.find((setting) => setting.name === 'riskLeadJobTypes');
      if (jobTypes) {
        form.setFieldValue('jobTypes', JSON.parse(jobTypes.value));
      }
      const questions = existingSettingsData.settings.find((setting) => setting.name === 'riskLeadQuestions');
      if (questions) {
        form.setFieldValue('questions', JSON.parse(questions.value));
      }
    }
  }, [form, existingSettingsData]);

  return (
    <div className={styles.container}>
      <h2>Risk Leads Settings</h2>
      <Form
        layout="vertical"
        onFinish={onSubmit}
        form={form}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Item
              label="Job Types"
              name="jobTypes"
            >
              <Select
                showSearch
                mode="tags"
              >
                {getJobTypesData?.getJobTypes.map((jobType) => (
                  <Select.Option
                    key={jobType.id}
                    value={`${jobType.id}`}
                  >
                    {jobType.label}
                  </Select.Option>
                ))}
              </Select>
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label="Questions"
              name="questions"
            >
              <Select
                showSearch
                mode="tags"
              />
            </Item>
          </Col>
        </Row>
        <Item>
          <div className={styles.saveButtonContainer}>
            <Button
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        </Item>
      </Form>
    </div>
  );
};
