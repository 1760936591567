import { createUseStyles } from 'react-jss';
import { htmlFontSize } from '../base';
import { body, h1, h2, h3, h4 } from '../types';
import invxCss from '../defaults/index.css';
import antdCss from '../defaults/antDefaults.css';

export const globals = createUseStyles({
  '@global': {
    '@import': ["url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap')", antdCss, invxCss],
    html: {
      fontSize: htmlFontSize,
      fontFamily: 'Roboto, sans-serif',
    },
    body: {
      margin: 0,
      padding: 0,
      height: '100%',

    },
    h1,
    h2,
    h3,
    h4,
    'p, div, span': {
      ...body,
    },
    '#root': {
      height: '100%',
    },
    '.invx-btn': {
      height: 'auto',
    },
    '.invx-modal-wrap': {
      maxWidth: '100vw',
      overflowX: 'hidden !important',
    },

    form: {
      '& input': {
        ...body,
      },
      '& .invx-select-selection-item, & .invx-select-selection-item-content': {
        ...body,
      },
    },
  },
  app: {
    height: '100%',
  },
});
