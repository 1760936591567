import { useMutation, useQuery } from '@apollo/client';
import { Button } from 'antd';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container } from '../../components';
import { Role } from '../../lib/graphql/globalTypes';
import { KPI_EARLY_WARNINGS } from '../../lib/graphql/query/kpiEarlyWarnings';
import { kpiEarlyWarnings, kpiEarlyWarningsVariables } from '../../lib/graphql/query/kpiEarlyWarnings/__generated__/kpiEarlyWarnings';
import { Viewer } from '../../lib/types';
import { authorized, displaySuccessNotification } from '../../lib/utils';
import { AddEarlyWarning } from '../../modals';
import { kpiEarlyWarningStyles } from './styles';
import { deleteKpiEarlyWarningCategory, deleteKpiEarlyWarningCategoryVariables } from '../../lib/graphql/mutation/kpiEarlyWarning/__generated__/deleteKpiEarlyWarningCategory';
import { DELETE_KPI_EARLY_WARNING_CATEGORY } from '../../lib/graphql/mutation/kpiEarlyWarning';

interface Props {
  viewer: Viewer
}

export const KpiEarlyWarning: React.FC<Props> = ({
  viewer,
}) => {
  const styles = kpiEarlyWarningStyles();
  const [modalActive, setModalActive] = useState<boolean>(false);

  const params = useParams();

  const { data } = useQuery<kpiEarlyWarnings, kpiEarlyWarningsVariables>(KPI_EARLY_WARNINGS, {
    variables: {
      categoryId: params.categoryId,
    },
  });

  const [deleteCategory] = useMutation<deleteKpiEarlyWarningCategory, deleteKpiEarlyWarningCategoryVariables>(DELETE_KPI_EARLY_WARNING_CATEGORY, {
    onCompleted: () => {
      window.location.href = '/kpi-early-warning';
      displaySuccessNotification('Successfully deleted category');
    },
    onError: () => {
      displaySuccessNotification('Failed to delete category');
    },
  });

  const onModalClose = () => {
    setModalActive(false);
  };

  return (
    <Container className={styles.container}>
      <p>KPI Early Warning</p>
      <div className={styles.titleContainer}>
        <h1 className={styles.title}>
          {
            data?.kpiEarlyWarnings.length && data?.kpiEarlyWarnings[0].categoryName
          }
        </h1>
        {
          (authorized({ viewer, role: Role.SUPER_ADMIN })) && (
            <Button
              type="primary"
              onClick={() => setModalActive(true)}
            >
              Create an objective
            </Button>
          )
        }
      </div>
      <div className={styles.objectivesContainer}>
        <div className={styles.innerContainer}>
          {
            data?.kpiEarlyWarnings.map((kpiEarlyWarning) => (
              <Link to={`/kpi-early-warning/${params.id}/${kpiEarlyWarning.id}`}>
                <div className={`${styles.kpiCard} ${
                  // if amber has a higher value than fail and green
                  kpiEarlyWarning.amber > 0 && kpiEarlyWarning.red <= 0 ? styles.amberCard : ''} ${
                  // if fail has a higher value than amber and green
                  kpiEarlyWarning.red > 0 && kpiEarlyWarning.fail <= 0 ? styles.redCard : ''} ${
                  // if fail has a higher value than amber and green
                  kpiEarlyWarning.fail > 0 ? styles.blackCard : ''}`}
                >
                  <h2 className={styles.cardTitles}>{kpiEarlyWarning.name}</h2>
                  <p className={styles.description}>{kpiEarlyWarning.description}</p>
                  <div className={styles.row}>
                    <div>
                      <span className={styles.black} />
                      <span>{kpiEarlyWarning.fail}</span>
                    </div>
                    <div>
                      <span className={styles.red} />
                      <span>{kpiEarlyWarning.red}</span>
                    </div>
                    <div>
                      <span className={styles.amber} />
                      <span>{kpiEarlyWarning.amber}</span>
                    </div>
                    <div>
                      <span className={styles.green} />
                      <span>{kpiEarlyWarning.pass}</span>
                    </div>
                  </div>
                </div>
              </Link>
            ))
          }
        </div>
      </div>
      {
        (authorized({ viewer, role: Role.SUPER_ADMIN })) && (
          <Button
            type="default"
            onClick={() => deleteCategory({
              variables: {
                id: params.categoryId as string,
              },
            })}
          >
            Delete Category
          </Button>
        )
      }
      {
        Boolean(modalActive) && (
          <AddEarlyWarning
            viewer={viewer}
            active={modalActive}
            onClose={onModalClose}
          />
        )
      }
    </Container>
  );
};
