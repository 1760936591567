import { useMutation, useQuery } from '@apollo/client';
import { Button, Popconfirm, Space, Table, Tag, Typography } from 'antd';
import React, { useState } from 'react';
import { Container } from '../../components';
import { Access, OrderByDirection, Role } from '../../lib/graphql/globalTypes';
import { DELETE_ON_CALL } from '../../lib/graphql/mutation/onCalls';
import { DeleteOnCall, DeleteOnCallVariables } from '../../lib/graphql/mutation/onCalls/__generated__/DeleteOnCall';
import { ON_CALLS } from '../../lib/graphql/query/onCall';
import { OnCalls as OnCallsData, OnCallsVariables } from '../../lib/graphql/query/onCall/__generated__/OnCalls';
import { Viewer } from '../../lib/types';
import { authorized, displayErrorMessage, displaySuccessNotification, formatDate } from '../../lib/utils';
import { AddEditOnCall } from '../../modals';
import { colors } from '../../styles';
import { onCallsStyles } from './styles';

interface Props {
  viewer: Viewer
}

const { Text } = Typography;

export const OnCalls: React.FC<Props> = ({ viewer }) => {
  const styles = onCallsStyles();

  const [modalActive, setModalActive] = useState<boolean | string>(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [order, setOrder] = useState({ field: 'startDate', direction: OrderByDirection.ASC });
  const { data, loading, refetch } = useQuery<OnCallsData, OnCallsVariables>(ON_CALLS, {
    variables: {
      page,
      total: 20,
      orderBy: order,
    },
  });

  const [deleteOnCall] = useMutation<DeleteOnCall, DeleteOnCallVariables>(DELETE_ON_CALL, {
    onCompleted: (team) => {
      if (team.deleteOnCall) {
        displaySuccessNotification('On Call deleted successfully!');
        refetch();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Error deleting On Call');
      }
    },
  });

  const columns = [
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: formatDate,
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: formatDate,
    },
    {
      title: 'Users',
      dataIndex: 'users',
      key: 'users',
      width: '20%',
      render: (users: {id: string, firstName: string, lastName: string, role: Role}[]) => (
        <Space>
          {
            users.map((user) => (
              <Tag
                key={user.id}
                color={user.role === Role.MANAGER ? colors.primary : ''}
              >
                {`${user.firstName} ${user.lastName}`}
              </Tag>
            ))
          }
        </Space>
      ),
    },
    authorized({
      viewer,
      access: Access.ALTER_ON_CALLS,
    }) ? {
        title: 'Actions',
        key: 'actions',
        render: (onCall: {id: string, name: string}) => (
          <Space>
            <Button
              type="link"
              onClick={() => setModalActive(onCall.id)}
            >
              Edit
            </Button>
            <Popconfirm
              title="Are you sure you want to delete this on call?"
              onConfirm={() => deleteOnCall({ variables: { id: onCall.id } })}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link">
                <Text type="danger">
                  Delete
                </Text>
              </Button>
            </Popconfirm>
          </Space>
        ),
      } : {},
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTableChange = (pagination: any, _filters: unknown, sorter: any) => {
    if (pagination?.current) {
      setPage(pagination.current);
    }
    if (pagination?.size) {
      setLimit(pagination.size);
    }
    if (sorter?.columnKey) {
      setOrder({
        field: sorter.columnKey,
        direction: sorter.order === 'ascend' ? OrderByDirection.ASC : OrderByDirection.DESC,
      });
    }
  };

  const onCloseModal = () => {
    setModalActive(false);
    refetch();
  };

  return (
    <Container className={styles.container}>
      <p className={styles.overTitle}>On Calls</p>
      <div className={styles.titleContainer}>
        <h1>View All On Calls</h1>
        {
          authorized({
            viewer,
            access: Access.ALTER_USERS,
          }) && (
            <Button
              type="primary"
              onClick={() => setModalActive(true)}
            >
              Create On Call
            </Button>
          )
        }
      </div>

      <div className={styles.sectionContainer}>
        <Table
          columns={columns}
          dataSource={data?.onCalls?.items}
          scroll={{ x: 'max-content' }}
          loading={loading}
          pagination={{
            defaultPageSize: limit,
            defaultCurrent: page,
            total: data?.onCalls?.total,
          }}
          onChange={handleTableChange}
          rowKey="id"
        />
      </div>
      {
        modalActive && (
          <AddEditOnCall
            active={modalActive}
            onClose={onCloseModal}
          />
        )
      }
    </Container>
  );
};
