import { createUseStyles } from 'react-jss';
import { base, colors } from '../../styles';

export const mapPinsStyles = createUseStyles({
  container: {
    height: '100%',
    display: 'flex',
    '& > div': {
      flex: 1,
    },
  },
  settingsCard: {
    padding: base(1),
    background: colors.white,
    height: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: base(1),
  },
});
