import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Form, Input, InputNumber, Modal, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import { CREATE_PRODUCT, UPDATE_PRODUCT } from '../../lib/graphql/mutation/products';
import { CreateProduct, CreateProductVariables } from '../../lib/graphql/mutation/products/__generated__/CreateProduct';
import { UpdateProduct, UpdateProductVariables } from '../../lib/graphql/mutation/products/__generated__/UpdateProduct';
import { PRODUCT, PRODUCT_CATEGORIES } from '../../lib/graphql/query/products';
import { Product, ProductVariables } from '../../lib/graphql/query/products/__generated__/Product';
import { ProductCategories, ProductCategoriesVariables } from '../../lib/graphql/query/products/__generated__/ProductCategories';
import { displayErrorMessage, displaySuccessNotification } from '../../lib/utils';
import { modalWidth } from '../../styles/sizes';
import { addEditProduct } from './styles';

interface Props {
  active: boolean | string;
  onClose: () => void;
}

const { Item } = Form;
const { Option } = Select;

export const AddEditProduct: React.FC<Props> = ({
  active,
  onClose,
}) => {
  const styles = addEditProduct();

  const [categoryLookup, setCategoryLookup] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [categories, setCategories] = useState<any | undefined>([]);

  const handleOnClose = () => {
    form.resetFields();
    onClose();
  };

  useQuery<ProductCategories, ProductCategoriesVariables>(PRODUCT_CATEGORIES, {
    variables: {
      page: 1,
      total: 50,
      lookup: categoryLookup,
    },
    onCompleted: (data) => {
      setCategories(data?.productCategories?.items);
    },
  });

  const [createProduct] = useMutation<CreateProduct, CreateProductVariables>(CREATE_PRODUCT, {
    onCompleted: (data) => {
      if (data.createProduct) {
        displaySuccessNotification('Product created successfully');
        handleOnClose();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Error creating Product');
      }
    },
  });

  const [getExistingProduct] = useLazyQuery<Product, ProductVariables>(PRODUCT);

  const [updateProduct] = useMutation<UpdateProduct, UpdateProductVariables>(UPDATE_PRODUCT, {
    onCompleted: (data) => {
      if (data.updateProduct) {
        displaySuccessNotification('Product updated successfully');
        handleOnClose();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Error updating Product');
      }
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (values: any) => {
    if (active === true) {
      createProduct({
        variables: {
          input: {
            ...values,
          },
        },
      });
    } else {
      updateProduct({
        variables: {
          id: active as string,
          input: {
            ...values,
          },
        },
      });
    }
  };

  const [form] = useForm();

  useEffect(() => {
    if (typeof active === 'string') {
      getExistingProduct({
        variables: {
          id: active,
        },
      }).then(({ data }) => {
        form.setFieldsValue({
          ...data?.product,
        });
      });
    }
  }, [active, getExistingProduct, form]);
  return (
    <Modal
      title={`${typeof active === 'boolean' ? 'Create' : 'Update'} Product Category`}
      open={Boolean(active)}
      onCancel={handleOnClose}
      onOk={() => form.submit()}
      width={modalWidth}
    >
      <div className={styles.container}>
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
        >
          <Item
            name="name"
            label="Name"
            required
          >
            <Input />
          </Item>
          <Item
            name="categoryId"
            label="Category"
            required
          >
            <Select
              onSearch={(value: string) => setCategoryLookup(value)}
              filterOption={false}
              showSearch
            >
              {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                categories.map((category: any) => (
                  <Option
                    key={category.id}
                    value={category.id}
                  >
                    {category.name}
                  </Option>
                ))
              }
            </Select>
          </Item>
          <Item
            name="make"
            label="Make"
          >
            <Input />
          </Item>
          <Item
            name="price"
            label="Price"
            required
          >
            <InputNumber prefix="£" />
          </Item>
        </Form>
      </div>
    </Modal>
  );
};
