import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useRef, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { AppHeader, AppSider } from './components';
import { appStyles } from './appStyles';
import { colors, globals } from './styles';
import { Apps, Commissions, Dashboard, ForgotPassword, Alerts, Objectives, OnCalls, ResetPassword, RiskLeads, StockRequests, Teams, TechFeedback, Users, Products, ProductCategories, Settings, UserDashboard, MapPins, KpiEarlyWarning, EarlyWarning, PricingDocs, GpcRates, GpcEquipment, ScreenTwo, ScreenOne, KpiEarlyWarningCategories, EarlyWarnings } from './sections';
import { Login } from './sections/Login';
import { Viewer } from './lib/types';
import { Login as LoginData, LoginVariables } from './lib/graphql/mutation/login/__generated__/Login';
import { LOG_IN } from './lib/graphql/mutation/login';
import { displayErrorMessage } from './lib/utils';
import { ViewerContext } from './lib/utils/ViewerContext';
import { GenerateSalesDocument } from './modals/GenerateSalesDocument';

const initialViewer: Viewer = {
  id: null,
  firstName: null,
  lastName: null,
  role: null,
  access: null,
  token: null,
  didRequest: false,
};

function App() {
  const globalStyles = globals();
  const styles = appStyles();
  const [viewer, setViewer] = useState(initialViewer);

  const [login] = useMutation<LoginData, LoginVariables>(LOG_IN, {
    onCompleted(data) {
      if (data && data.login) {
        setViewer(data.login);
        if (data.login.token) {
          sessionStorage.setItem('token', data.login.token);
        } else {
          sessionStorage.removeItem('token');
        }
      } else {
        displayErrorMessage("Sorry, we weren't able to log you in. Please try again later!");
        setViewer(initialViewer);
      }
    },
  });
  const loginRef = useRef(login);

  useEffect(() => {
    loginRef.current();
  }, [loginRef]);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (!viewer.id && viewer.didRequest) {
    return (
      <div className={globalStyles.app}>
        <Layout
          className={styles.container}
          style={{ background: colors.white }}
        >
          <Routes>
            <Route
              path="/login"
              element={<Login setViewer={setViewer} />}
            />
            <Route
              path="/forgot-password"
              element={<ForgotPassword />}
            />
            <Route
              path="/reset-password"
              element={<ResetPassword />}
            />
            <Route
              path="*"
              element={<Login setViewer={setViewer} />}
            />
          </Routes>
        </Layout>
      </div>
    );
  }

  if (!viewer.didRequest) {
    return null;
  }

  return (
    <div className={globalStyles.app}>
      <ViewerContext.Provider value={[viewer, setViewer]}>
        <Layout className={styles.container}>
          <AppSider
            setViewer={setViewer}
            viewer={viewer}
          />
          <Layout className={styles.innerContainer}>
            <AppHeader viewer={viewer} />
            <Content
              className={styles.contentContainer}
            >
              <Routes>
                <Route
                  path="/"
                  element={<Dashboard viewer={viewer} />}
                />
                <Route
                  path="/users"
                  element={<Users viewer={viewer} />}
                />
                <Route
                  path="/users/:id"
                  element={<UserDashboard />}
                />
                <Route
                  path="/teams"
                  element={<Teams viewer={viewer} />}
                />
                <Route
                  path="/alerts"
                  element={<Alerts viewer={viewer} />}
                />
                <Route
                  path="/risk-leads"
                  element={<RiskLeads viewer={viewer} />}
                />
                <Route
                  path="/stock-requests"
                  element={<StockRequests viewer={viewer} />}
                />
                <Route
                  path="/commissions"
                  element={<Commissions viewer={viewer} />}
                />
                <Route
                  path="/on-calls"
                  element={<OnCalls viewer={viewer} />}
                />
                <Route
                  path="/apps"
                  element={<Apps viewer={viewer} />}
                />
                <Route
                  path="/tech-feedback"
                  element={<TechFeedback viewer={viewer} />}
                />
                <Route
                  path="/objectives"
                  element={<Objectives viewer={viewer} />}
                />
                <Route
                  path="/product-categories"
                  element={<ProductCategories viewer={viewer} />}
                />
                <Route
                  path="/products"
                  element={<Products viewer={viewer} />}
                />
                <Route
                  path="/settings/*"
                  element={<Settings />}
                />
                <Route
                  path="map-pins"
                  element={<MapPins />}
                />
                <Route
                  path="kpi-early-warning"
                  element={<KpiEarlyWarningCategories viewer={viewer} />}
                />
                <Route
                  path="kpi-early-warning/:categoryId/"
                  element={<KpiEarlyWarning viewer={viewer} />}
                />
                <Route
                  path="kpi-early-warning/:categoryId/:id"
                  element={<EarlyWarning />}
                />
                <Route
                  path="kpi-early-warning/combined/:ids"
                  element={<EarlyWarnings />}
                />
                <Route
                  path="pricing-docs"
                  element={<PricingDocs viewer={viewer} />}
                />
                <Route
                  path="/pricing-docs/gpc-rates"
                  element={<GpcRates viewer={viewer} />}
                />
                <Route
                  path="/pricing-docs/gpc-equipment"
                  element={<GpcEquipment viewer={viewer} />}
                />
                <Route
                  path="dashboard/one"
                  element={<ScreenOne />}
                />
                <Route
                  path="dashboard/two"
                  element={<ScreenTwo />}
                />
              </Routes>
            </Content>
          </Layout>
        </Layout>
        <GenerateSalesDocument />
      </ViewerContext.Provider>
    </div>
  );
}

export default App;
