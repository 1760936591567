import gql from 'graphql-tag';

export const CREATE_ALERT = gql`
  mutation CreateAlert($input: CreateAlertInput!) {
    createAlert(input: $input) {
      id
    }
  }
`;

export const UPDATE_ALERT = gql`
  mutation UpdateAlert($input: UpdateAlertInput!) {
    updateAlert(input: $input) {
      id
    }
  }
`;

export const DELETE_ALERT = gql`
  mutation DeleteAlert($id: ID!) {
    deleteAlert(id: $id) {
      id
    }
  }
`;
