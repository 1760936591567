import { createUseStyles } from 'react-jss';

export const editUserStyles = createUseStyles({
  container: {

  },
  accessRightsContainer: {

  },
});
