import { createUseStyles } from 'react-jss';
import { base } from '../../styles';

export const createCommissionStyles = createUseStyles({
  container: {},
  paymentsContainer: {},
  noPayments: {
    marginBottom: base(),
  },
  paymentsTable: {
    marginBottom: base(),

    '& table': {
      width: '100%',

      '& thead': {
        '& tr': {
          '& th': {
            textAlign: 'center',
            padding: base(0.5),
          },
        },
      },

      '& tbody': {
        '& tr': {
          '& td': {
            padding: base(0.5),
            textAlign: 'center',
          },
        },
      },
    },
  },
  buttonContainer: {
    marginTop: base(),
    display: 'flex',
    justifyContent: 'flex-end',

    '& button': {
      marginLeft: base(),
    },
  },
});
