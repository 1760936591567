import { useQuery } from '@apollo/client';
import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { Container, ObjectiveCard } from '../../components';
import { Role } from '../../lib/graphql/globalTypes';
import { OBJECTIVES } from '../../lib/graphql/query/objectives';
import { Objectives as ObjectivesTypes, ObjectivesVariables } from '../../lib/graphql/query/objectives/__generated__/Objectives';
import { Viewer } from '../../lib/types';
import { authorized } from '../../lib/utils';
import { AddEditObjective } from '../../modals';
import { objectivesStyles } from './styles';

interface Props {
  viewer: Viewer
}

export const Objectives: React.FC<Props> = ({
  viewer,
}) => {
  const styles = objectivesStyles();
  const [modalActive, setModalActive] = useState<boolean | string>(false);
  const [teamObjectives, setTeamObjectives] = useState<{[key: string]: ObjectivesTypes['objectives']['items']}>({});
  const [userObjectives, setUserObjectives] = useState<{[key: string]: ObjectivesTypes['objectives']['items']}>({});

  const { data, refetch } = useQuery<ObjectivesTypes, ObjectivesVariables>(OBJECTIVES, {
    variables: {
      page: 1,
      total: 99,
    },
  });

  useEffect(() => {
    if (data) {
      // sort objectives into arrays for each team
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const objectivesByTeam = data.objectives.items.reduce((acc: any, objective) => {
        const { teamId, team } = objective;
        if (teamId && team) {
          if (acc[team.name]) {
            acc[team.name].push(objective);
          } else {
            acc[team.name] = [objective];
          }
        }
        return acc;
      }, {});

      setTeamObjectives(objectivesByTeam);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const objectivesByUser = data.objectives.items.reduce((acc: any, objective) => {
        const { userId, user } = objective;
        if (userId && user) {
          if (acc[`${user.firstName} ${user.lastName}`]) {
            acc[`${user.firstName} ${user.lastName}`].push(objective);
          } else {
            acc[`${user.firstName} ${user.lastName}`] = [objective];
          }
        }
        return acc;
      }, {});
      setUserObjectives(objectivesByUser);
    }
  }, [data]);

  const onModalClose = () => {
    setModalActive(false);
    refetch();
  };

  return (
    <Container className={styles.container}>
      <p>Welcome Back</p>
      <h1 className={styles.title}>Company Objective</h1>
      <p>To deliver data to clients outlining pest risk, give clear actions with client focused recommendations. Completing visits on time allowing the customer to make informed decisions to positively affect their environment.</p>
      <div className={styles.objectivesContainer}>
        <div className={styles.titleContainer}>
          <h2>All Objectives</h2>
          {
            (authorized({ viewer, role: Role.MANAGER }) || authorized({ viewer, role: Role.ADMIN })) && (
              <Button
                type="primary"
                onClick={() => setModalActive(true)}
              >
                Create an objective
              </Button>
            )
          }

        </div>
        <div className={styles.innerContainer}>
          <div className={styles.subTitleContainer}>
            <h2>Teams</h2>
          </div>

          {
            teamObjectives && Object.keys(teamObjectives).map((teamId) => (
              <div
                className={styles.objectivesInnerContainer}
                key={teamId}
              >
                <h3>
                  {teamId}
                </h3>
                <div className={styles.cardsContainer}>
                  {
                    teamObjectives[teamId].map((objective: ObjectivesTypes['objectives']['items'][0]) => (
                      <Button
                        key={objective.id}
                        type="link"
                        style={{ whiteSpace: 'unset', padding: 0 }}
                        onClick={() => {
                          setModalActive(objective.id);
                        }}
                      >
                        <ObjectiveCard
                          title={objective.title}
                          description={objective.description}
                          status={objective.status}
                          priority={objective.priority}
                        />
                      </Button>
                    ))
                  }
                </div>
              </div>
            ))
          }
          <div className={styles.subTitleContainer}>
            <hr />
            <h2>User(s)</h2>
          </div>
          {
            userObjectives && Object.keys(userObjectives).map((userId) => (
              <div
                className={styles.objectivesInnerContainer}
                key={userId}
              >
                <h3>
                  {userId}
                </h3>
                <div className={styles.cardsContainer}>
                  {
                    userObjectives[userId].map((objective: ObjectivesTypes['objectives']['items'][0]) => (
                      <Button
                        key={objective.id}
                        type="link"
                        style={{ whiteSpace: 'unset', padding: 0 }}
                        onClick={() => {
                          setModalActive(objective.id);
                        }}
                      >
                        <ObjectiveCard
                          title={objective.title}
                          description={objective.description}
                          status={objective.status}
                          priority={objective.priority}
                        />
                      </Button>
                    ))
                  }
                </div>
              </div>
            ))
          }
        </div>
      </div>
      {
        Boolean(modalActive) && (
          <AddEditObjective
            active={modalActive}
            onClose={onModalClose}
          />
        )
      }
    </Container>
  );
};
