import { createUseStyles } from 'react-jss';
import { base, queries } from '../../../styles';
import { contentWidth } from '../../../styles/sizes';

export const containerStyles = createUseStyles({
  container: {
    maxWidth: contentWidth,
    margin: '0 auto',
    width: '100%',
    padding: base(2),
    [queries.md]: {
      padding: base(),
    },
  },
});
