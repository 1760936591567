import { useQuery } from '@apollo/client';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, LoadingScreen } from '../../components';
import { USER_OVERVIEW } from '../../lib/graphql/query/userOverview';
import { UserOverview as UserOverviewData, UserOverviewVariables } from '../../lib/graphql/query/userOverview/__generated__/UserOverview';
import { JobsTable } from './components';
import { UserOverview } from './components/UserOverview';
import { UserDashboardStyles } from './styles';

export const UserDashboard: React.FC = () => {
  const styles = UserDashboardStyles();
  const params = useParams();

  const startDate = dayjs().startOf('day').toDate();
  const endDate = dayjs().endOf('day').toDate();
  const [page, setPage] = useState(1);
  const { data, loading } = useQuery<UserOverviewData, UserOverviewVariables>(USER_OVERVIEW, {
    variables: {
      id: params.id || '',
      startDate,
      endDate,
      page,
    },
  });

  if (loading || !data?.userOverview) {
    return <LoadingScreen />;
  }

  return (
    <Container className={styles.container}>
      <Row gutter={24}>
        <Col
          span={24}
          md={6}
        >
          {
            !loading ? (
              <div className={styles.profileInfoContainer}>
                <div className={styles.profileImageContainer}>
                  {
                    data?.userOverview.user.profileImageUrl ? (
                      <img
                        className={styles.profileImage}
                        src={data.userOverview.user.profileImageUrl}
                        alt="profile"
                      />
                    ) : (
                      <img
                        className={styles.profileImage}
                        src="/img/blank-profile.jpg"
                        alt="profile"
                      />
                    )
                  }
                </div>
                <div>
                  <h1>
                    {data?.userOverview.user.firstName}
                    {' '}
                    {data?.userOverview.user.lastName}
                  </h1>
                  <p>
                    {data?.userOverview.user.email}
                  </p>
                  <p>{data?.userOverview.user.phoneNumber}</p>
                </div>
              </div>
            ) : (
              <LoadingScreen />
            )
          }
        </Col>
        <Col
          span={24}
          md={18}
        >
          <UserOverview
            bookedRevenue={data?.userOverview.bookedRevenue || 0}
            completedJobs={data?.userOverview.completedJobs || 0}
            completedRevenue={data?.userOverview.completedRevenue || 0}
            jobTypes={data?.userOverview.jobTypes || ''}
            targetRevenue={data?.userOverview.targetRevenue || 0}
            totalJobs={data?.userOverview.totalJobs || 0}
            key={data?.userOverview.user.id}
          />
        </Col>
      </Row>
      <JobsTable
        jobs={data?.userOverview.jobs}
        page={page}
        setPage={setPage}
      />
    </Container>
  );
};
