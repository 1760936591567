import { useMutation, useQuery } from '@apollo/client';
import { Button, Modal, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CLEAR_ALL_NOTIFICATIONS, CLEAR_NOTIFICATION, MARK_NOTIFICATIONS_AS_READ } from '../../lib/graphql/mutation/notifications';
import { ClearAllNotifications } from '../../lib/graphql/mutation/notifications/__generated__/ClearAllNotifications';
import { ClearNotification, ClearNotificationVariables } from '../../lib/graphql/mutation/notifications/__generated__/ClearNotification';
import { MarkNotificationsAsRead, MarkNotificationsAsReadVariables } from '../../lib/graphql/mutation/notifications/__generated__/MarkNotificationsAsRead';
import { NOTIFICATIONS } from '../../lib/graphql/query/notifications';
import { notifications, notificationsVariables } from '../../lib/graphql/query/notifications/__generated__/notifications';
import { displayErrorMessage, displaySuccessNotification, formatDateAndTime } from '../../lib/utils';
import { notificationsModalStyles } from './styles';

interface Props {
  modalVisible: boolean,
  // eslint-disable-next-line no-unused-vars
  setModalVisible: (visible: boolean) => void
  clearHasNotifications: () => void
}

const { Title } = Typography;
const limit = 20;

export const NotificationsModal: React.FC<Props> = ({
  modalVisible,
  setModalVisible,
  clearHasNotifications,
}) => {
  const styles = notificationsModalStyles();
  const [page, setPage] = useState(1);
  const [notificationsList, setNotificationsList] = useState<notifications['notifications']['items']>([]);
  const { data, refetch } = useQuery<notifications, notificationsVariables>(NOTIFICATIONS, {
    variables: {
      total: limit,
      page,
    },
    onCompleted: (notificationsData) => {
      setNotificationsList([...notificationsList, ...notificationsData.notifications.items]);
    },
  });

  const [markNotificationsAsRead] = useMutation<MarkNotificationsAsRead, MarkNotificationsAsReadVariables>(MARK_NOTIFICATIONS_AS_READ);

  const [clearAllNotifications] = useMutation<ClearAllNotifications>(CLEAR_ALL_NOTIFICATIONS, {
    onCompleted: (clearNotifications) => {
      if (clearNotifications.clearAllNotifications) {
        setNotificationsList([]);
        refetch();
        displaySuccessNotification('All notifications cleared!');
      }
    },
    onError(error) {
      if (error) {
        displayErrorMessage('Sorry! We were unable to clear your notifications. Please try again later!');
      }
    },
  });

  const [clearNotification] = useMutation<ClearNotification, ClearNotificationVariables>(CLEAR_NOTIFICATION, {
    onCompleted: (clearNotificationData) => {
      if (clearNotificationData.clearNotification) {
        displaySuccessNotification('Notification cleared!');
      }
    },
    onError(error) {
      if (error) {
        displayErrorMessage('Sorry! We were unable to clear your notification. Please try again later!');
      }
    },
  });

  useEffect(() => {
    if (modalVisible === true) {
      setNotificationsList([]);
      setPage(1);
      refetch();
    }
  }, [modalVisible, refetch]);

  const onClose = () => {
    markNotificationsAsRead({
      variables: {
        notificationIds: notificationsList.map((notification) => notification.id) || [],
      },
    });
    clearHasNotifications();
    setModalVisible(false);
  };

  return (
    <Modal
      open={modalVisible}
      className={styles.contactFormModal}
      footer={null}
      onCancel={onClose}
    >
      <div className={styles.headContainer}>
        <Title
          level={3}
          className={styles.title}
        >
          Notifications
        </Title>
        <Button
          className={styles.clearButton}
          type="primary"
          onClick={() => clearAllNotifications()}
        >
          Clear All
        </Button>
      </div>
      <div className={styles.bodyContainer}>
        {notificationsList.map((notification) => {
          const notificationBlock = (
            <div
              key={notification.id}
              className={`${styles.notificationContainer} ${
                notification.read ? styles.read : styles.unread
              }`}
            >
              <h3
                className={styles.notificationTitle}
              >
                {notification.title}
              </h3>
              <p className={styles.notificationText}>
                {notification.message}
              </p>
              <p className={styles.notificationDate}>
                {formatDateAndTime(notification.createdAt)}
              </p>
              <Button
                type="link"
                className={styles.deleteNotificationButton}
                onClick={() => {
                  setNotificationsList(notificationsList.filter((item) => item.id !== notification.id));
                  clearNotification({
                    variables: {
                      id: notification.id,
                    },
                  });
                }}
              >
                x
              </Button>
            </div>
          );

          if (notification.link) {
            // if link is relative, use next/link
            if (notification.link.startsWith('/')) {
              return (
                <Link
                  to={notification.link}
                  key={notification.id}
                >
                  {notificationBlock}
                </Link>
              );
            }
            return (
              <a
                key={notification.id}
                href={notification.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {notificationBlock}
              </a>
            );
          }
          return notificationBlock;
        })}
        {
          data?.notifications.total && (data?.notifications.total !== 0 && notificationsList.length < data?.notifications.total) ? (
            <Button
              type="primary"
              onClick={() => setPage(page + 1)}
              className={styles.loadMoreButton}
            >
              View More
            </Button>
          ) : null
        }
        {/* if there are no notifications */}
        {data?.notifications.total === 0 && (
          <p className={styles.noNotifications}>No notifications to show, check back later</p>
        )}
      </div>
    </Modal>
  );
};
