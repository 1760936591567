import { createUseStyles } from 'react-jss';
import { base } from '../../styles';

export const exportCommissionsStyles = createUseStyles({
  container: {

  },
  stepContainer: {
    marginTop: base(2),
  },
});
