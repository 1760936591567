import { Tabs } from 'antd';
import React from 'react';
import { Container } from '../../components';
import { Viewer } from '../../lib/types';
import { TechFeedbackTable } from './components';
import { techFeedbacksStyles } from './styles';

interface Props {
  viewer: Viewer
}

export const TechFeedback: React.FC<Props> = ({
  viewer,
}) => {
  const styles = techFeedbacksStyles();

  const tabItems = [
    {
      label: 'Pending',
      key: 'pending',
      children: <TechFeedbackTable
        status="pending"
        viewer={viewer}
      />,
    },
    {
      label: 'Open',
      key: 'open',
      children: <TechFeedbackTable
        status="open"
        viewer={viewer}
      />,
    },
    {
      label: 'Closed',
      key: 'closed',
      children: <TechFeedbackTable
        status="closed"
        viewer={viewer}
      />,
    },
  ];

  return (
    <Container>
      <p className={styles.overTitle}>Q/A</p>
      <div className={styles.titleContainer}>
        <h1>Tech Feedback</h1>
      </div>
      <div className={styles.sectionContainer}>
        <Tabs
          items={tabItems}
        />
      </div>
    </Container>
  );
};
