import { Access, Role } from '../../graphql/globalTypes';
import { Viewer } from '../../types';

interface Props {
  viewer: Viewer
  access?: Access;
  role?: Role
}


export const authorized = ({
  viewer,
  access,
  role,
}: Props): boolean => {
  if (!viewer.id) {
    return false;
  }

  let roleAuthorized: boolean;
  let accessAuthorized: boolean;

  if (role) {
    switch (role) {
      case Role.SUPER_ADMIN:
        roleAuthorized = Boolean(viewer.role?.includes(Role.SUPER_ADMIN));
        break;
      case Role.ADMIN:
        roleAuthorized = Boolean(viewer.role?.includes(Role.SUPER_ADMIN) || viewer.role?.includes(Role.ADMIN));
        break;
      case Role.MANAGER:
        roleAuthorized = Boolean(viewer.role?.includes(Role.SUPER_ADMIN) || viewer.role?.includes(Role.ADMIN) || viewer.role?.includes(Role.MANAGER));
        break;
      default:
        roleAuthorized = false;
    }
  } else {
    roleAuthorized = true;
  }

  if (access) {
    if (viewer.role?.includes(Role.SUPER_ADMIN)) {
      accessAuthorized = true;
    } else if (access && viewer.access?.includes(access)) {
      accessAuthorized = true;
    } else {
      accessAuthorized = false;
    }
  } else {
    accessAuthorized = true;
  }

  if (roleAuthorized && accessAuthorized) {
    return true;
  }

  return false;
};
