import { Button, Modal, Upload } from 'antd';
import React, { useState } from 'react';
import CSVToJSON from 'csvtojson';
import { useMutation } from '@apollo/client';
import { displayErrorMessage, displaySuccessNotification } from '../../lib/utils';
import { ImportCommissions as ImportCommissionsData, ImportCommissionsVariables } from '../../lib/graphql/mutation/commissions/__generated__/ImportCommissions';
import { IMPORT_COMMISSIONS } from '../../lib/graphql/mutation/commissions';

interface Props {
  open?: boolean
  closeModal: () => void
}

export const ImportCommissions: React.FC<Props> = ({
  open,
  closeModal,
}) => {
  const [fileLoading, setFileLoading] = useState(false);
  const [fileString, setFileString] = useState<string | null>(null);

  const [importCommissions, { loading }] = useMutation<ImportCommissionsData, ImportCommissionsVariables>(IMPORT_COMMISSIONS, {
    onCompleted: (data) => {
      if (data.importCommissions) {
        displaySuccessNotification('Commissions successfully imported');
        closeModal();
      }
    },
    onError: (err) => {
      displayErrorMessage(err.message);
    },
  });

  const onSubmit = async () => {
    try {
      const csv = await CSVToJSON().fromString(fileString || '');

      await importCommissions({
        variables: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          input: csv.map((item: any) => {
            const userLabels = [];

            if (item['Sales Lead Sales Person'] !== 'N/A User') {
              userLabels.push(item['Sales Lead Sales Person']);
            }

            if (item['Sales Lead Technician'] !== 'N/A User') {
              userLabels.push(item['Sales Lead Technician']);
            }

            return {
              reference: item.Reference,
              client: item.Contact,
              datePaid: item['Date paid or accepted'],
              totalExVat: item['Total ex VAT'],
              riskLeadReference: item['Sales Lead Original Job Reference'],
              userLabels,
            };
          }),
        },
      });
    } catch (error) {
      displayErrorMessage('Error importing commissions');
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFileUpload = (info: any) => {
    const { file } = info;

    if (file.status === 'uploading') {
      setFileLoading(true);
      return;
    }

    if (file.status === 'done' && file.originFileObj) {
      getFileString(file.originFileObj, (item) => {
        setFileString(item);
        setFileLoading(false);
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dummyRequest = (option: any) => {
    setTimeout(() => {
      option.onSuccess('ok');
    }, 0);
  };

  return (
    <Modal
      open={open}
      title="Import Commission"
      onCancel={closeModal}
      onOk={onSubmit}
      okButtonProps={{
        loading,
      }}
    >
      <Upload
        name="file"
        showUploadList={false}
        customRequest={dummyRequest}
        beforeUpload={beforeFileUpload}
        onChange={handleFileUpload}
      >
        {fileString ? (
          <p>File uploaded!</p>
        ) : (
          <React.Fragment>
            {fileLoading && <p>File uploading</p>}
            <Button
              type="primary"
            >
              Upload CSV File
            </Button>
          </React.Fragment>
        )}
      </Upload>
    </Modal>
  );
};

const beforeFileUpload = (file: File) => {
  const fileIsValidFile = file.type === 'text/csv';
  const fileIsValidSize = file.size / 1024 / 1024 < 10;

  if (!fileIsValidFile) {
    displayErrorMessage('You\'re only able to upload valid CSV files!');
    return false;
  }

  if (!fileIsValidSize) {
    displayErrorMessage(
      'You\'re only able to upload valid files of under 1MB in size!',
    );
    return false;
  }

  return fileIsValidFile && fileIsValidSize;
};

const getFileString = (
  img: File | Blob,
  // eslint-disable-next-line no-unused-vars
  callback: (imageBase64Value: string) => void,
) => {
  const reader = new FileReader();
  reader.readAsText(img);
  reader.onload = () => {
    callback(reader.result as string);
  };
};
