import { useMutation } from '@apollo/client';
import { Form, Input, Modal } from 'antd';
import React from 'react';
import { displayErrorMessage, displaySuccessNotification } from '../../lib/utils';
import { modalWidth } from '../../styles/sizes';
import { addKpiCategoryStyles } from './styles';
import { createKpiEarlyWarningCategory, createKpiEarlyWarningCategoryVariables } from '../../lib/graphql/mutation/kpiEarlyWarning/__generated__/createKpiEarlyWarningCategory';
import { CREATE_KPI_EARLY_WARNING_CATEGORY } from '../../lib/graphql/mutation/kpiEarlyWarning';

interface Props {
  active: boolean;
  onClose: () => void;
}

const { Item } = Form;

export const AddKpiCategory: React.FC<Props> = ({
  active,
  onClose,
}) => {
  const styles = addKpiCategoryStyles();

  const [createKpiCategory, { loading: createLoading }] = useMutation<createKpiEarlyWarningCategory, createKpiEarlyWarningCategoryVariables>(CREATE_KPI_EARLY_WARNING_CATEGORY, {
    onCompleted: (data) => {
      if (data.createKpiEarlyWarningCategory) {
        displaySuccessNotification('Category created successfully');
        onClose();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Error creating category');
      }
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (values: any) => {
    createKpiCategory({
      variables: {
        name: values.name,
      },
    });
  };

  const [form] = Form.useForm();

  return (
    <Modal
      title="Create Category"
      open={active}
      onCancel={onClose}
      onOk={() => form.submit()}
      width={modalWidth}

    >
      <div className={styles.container}>
        <Form
          layout="vertical"
          onFinish={onSubmit}
          form={form}
        >
          <Item
            label="Name"
            name="name"
          >
            <Input />
          </Item>
        </Form>
      </div>
    </Modal>
  );
};
