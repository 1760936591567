/* eslint-disable camelcase */
import { useMutation, useQuery } from '@apollo/client';
import { Button, Popconfirm, Space, Table, Typography } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container } from '../../components';
import { Access, OrderByDirection, Role } from '../../lib/graphql/globalTypes';
import { DELETE_USER } from '../../lib/graphql/mutation/users';
import { DeleteUser, DeleteUserVariables } from '../../lib/graphql/mutation/users/__generated__/DeleteUser';
import { USERS } from '../../lib/graphql/query/users';
import { User_user } from '../../lib/graphql/query/users/__generated__/User';
import { Users as UsersData, UsersVariables } from '../../lib/graphql/query/users/__generated__/Users';
import { Viewer } from '../../lib/types';
import { authorized, displayErrorMessage, displaySuccessNotification, sentenceCase } from '../../lib/utils';
import { AddUser, EditUser } from '../../modals';
import { colors } from '../../styles';
import { usersStyles } from './styles';

interface Props {
  viewer: Viewer
}

const { Text } = Typography;

export const Users: React.FC<Props> = ({ viewer }) => {
  const styles = usersStyles();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [order, setOrder] = useState({ field: 'firstName', direction: OrderByDirection.ASC });
  const [lookup, setLookup] = useState('');
  const { data, loading, refetch } = useQuery<UsersData, UsersVariables>(USERS, {
    variables: {
      page,
      total: 20,
      orderBy: order,
      lookup,
    },
  });
  const [deleteUser] = useMutation<DeleteUser, DeleteUserVariables>(DELETE_USER, {
    onCompleted: (deleteUserData) => {
      if (deleteUserData.deleteUser) {
        displaySuccessNotification('User deleted successfully');
        refetch();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Sorry, we had an error deleting the user. Please try again later.');
      }
    },
  });

  const [addUserModal, setAddUserModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState<boolean | string>(false);
  const closeModals = () => {
    setAddUserModal(false);
    setEditUserModal(false);
    refetch();
  };

  const columns = [
    {
      title: 'First Name',
      key: 'firstName',
      sorter: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (item: any) => (
        <Link
          style={{ color: colors.primary }}
          to={`/users/${item.id}`}
        >
          {item.firstName}
        </Link>
      ),
    },
    {
      title: 'Last Name',
      key: 'lastName',
      sorter: true,
      render: (item: User_user) => (
        <Link
          style={{ color: colors.primary }}
          to={`/users/${item.id}`}
        >
          {item.lastName}
        </Link>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      sorter: true,
      render: (role: string) => sentenceCase(role),
    },
    authorized({
      viewer,
      role: Role.ADMIN,
      access: Access.ALTER_USERS,
    }) ? {
        title: 'Actions',
        key: 'actions',
        render: (user: {id: string}) => (
          <Space>
            <Button
              type="link"
              onClick={() => setEditUserModal(user.id)}
            >
              Edit
            </Button>
            <Popconfirm
              title="Are you sure you want to delete this user?"
              onConfirm={() => deleteUser({ variables: { id: user.id } })}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link">
                <Text type="danger">
                  Delete
                </Text>
              </Button>
            </Popconfirm>
          </Space>
        ),
      } : {},
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTableChange = (pagination: any, _filters: unknown, sorter: any, _: unknown, lookupString?: string) => {
    if (pagination?.current) {
      setPage(pagination.current);
    }
    if (pagination?.size) {
      setLimit(pagination.size);
    }
    if (sorter?.columnKey) {
      setOrder({
        field: sorter.columnKey,
        direction: sorter.order === 'ascend' ? OrderByDirection.ASC : OrderByDirection.DESC,
      });
    }
    if (lookupString) {
      setLookup(lookupString);
    }
  };

  return (
    <Container className={styles.container}>
      <p className={styles.overTitle}>Users</p>
      <div className={styles.titleContainer}>
        <h1>View All Users</h1>
        {
            authorized({
              viewer,
              access: Access.ALTER_USERS,
            }) && (
              <Button
                type="primary"
                onClick={() => setAddUserModal(true)}
              >
                Create User
              </Button>
            )
          }
      </div>

      <div className={styles.sectionContainer}>
        <div className={styles.filterContainer}>
          <div>
            <Search
              onSearch={(str: string) => handleTableChange(null, null, null, null, str)}
            />
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data?.users?.items}
          scroll={{ x: 'max-content' }}
          loading={loading}
          pagination={{
            defaultPageSize: limit,
            defaultCurrent: page,
            total: data?.users?.total,
          }}
          onChange={handleTableChange}
          rowKey="id"
        />
      </div>
      {
          addUserModal && (
            <AddUser
              active={addUserModal}
              onClose={closeModals}
            />
          )
        }
      {
          editUserModal && (
            <EditUser
              active={editUserModal}
              onClose={closeModals}
            />
          )
        }

    </Container>
  );
};
