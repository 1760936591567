import { createUseStyles } from 'react-jss';
import { base, colors, queries } from '../../styles';

export const notificationsModalStyles = createUseStyles({
  container: {

  },
  contactFormModal: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100vh',
    maxWidth: base(20),
    padding: 0,
    '& .invx-modal-content': {
      height: '100%',
    },
    '& .invx-modal-body': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
      padding: 0,
      marginRight: 0,
    },
  },
  headContainer: {
    width: '90%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    [queries.md]: {
      flexDirection: 'column',
    },
    padding: `${base()} ${base()} 0 ${base()}`,
  },
  title: {
    marginBottom: `${0}!important`,
  },
  clearButton: {
    height: base(1.7),
    paddingLeft: base(),
    paddingRight: base(),
  },
  bodyContainer: {
    marginTop: base(),
    borderTop: `1px solid ${colors.light}`,
    paddingTop: base(),
    overflowY: 'auto',
    paddingBottom: base(2),
  },
  notificationContainer: {
    borderBottom: `1px solid ${colors.light}`,
    paddingBottom: base(0.5),
    marginBottom: base(0.5),
    position: 'relative',
    paddingLeft: base(),
  },
  notificationTitle: {
    fontSize: base(1),
  },
  notificationText: {

  },
  notificationDate: {
    fontSize: base(0.8),
    marginLeft: base(0.1),
  },
  read: {
    opacity: 0.5,
  },
  unread: {

  },
  deleteNotificationButton: {
    position: 'absolute',
    right: base(),
    top: 0,
    padding: 0,
    margin: 0,
  },
  noNotifications: {
    textAlign: 'center',
  },
  loadMoreButton: {
    width: '100%',
    textAlign: 'center',
  },
});
