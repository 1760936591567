import gql from 'graphql-tag';

export const CREATE_QA_FEEDBACK = gql`
  mutation CreateQaFeedback($input: QaFeedbackInput!) {
    createQaFeedback(input: $input) {
      id
    }
  }
`;

export const UPDATE_QA_FEEDBACK = gql`
  mutation UpdateQaFeedback($id: ID!, $input: QaFeedbackInput!) {
    updateQaFeedback(id: $id, input: $input) {
      id
    }
  }
`;

export const ADD_QA_FEEDBACK_COMMENT = gql`
  mutation AddQaFeedbackComment($id: ID!, $message: String!) {
    addQaFeedbackComment(id: $id, message: $message) {
      id
    }
  }
`;
