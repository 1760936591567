import gql from 'graphql-tag';

export const LOG_IN = gql`
  mutation Login($email: String, $password: String) {
    login(email: $email, password: $password) {
      id
      firstName
      lastName
      role
      access
      token
      didRequest
    }
  }
`;
