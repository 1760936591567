import { useMutation, useQuery } from '@apollo/client';
import { Button, Popconfirm, Space, Table, Typography } from 'antd';
import React, { useState } from 'react';
import { Container, PrivateRoute } from '../../components';
import { Access, OrderByDirection, Role } from '../../lib/graphql/globalTypes';
import { DELETE_TEAM } from '../../lib/graphql/mutation/teams';
import { DeleteTeam, DeleteTeamVariables } from '../../lib/graphql/mutation/teams/__generated__/DeleteTeam';
import { TEAMS } from '../../lib/graphql/query/teams';
import { Teams as TeamsData, TeamsVariables } from '../../lib/graphql/query/teams/__generated__/Teams';
import { Viewer } from '../../lib/types';
import { authorized, displayErrorMessage, displaySuccessNotification } from '../../lib/utils';
import { AddEditTeam } from '../../modals';
import { teamsStyles } from './styles';

interface Props {
  viewer: Viewer
}

const { Text } = Typography;

export const Teams: React.FC<Props> = ({ viewer }) => {
  const styles = teamsStyles();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [order, setOrder] = useState({ field: 'name', direction: OrderByDirection.ASC });
  const { data, loading, refetch } = useQuery<TeamsData, TeamsVariables>(TEAMS, {
    variables: {
      page,
      total: 20,
      orderBy: order,
    },
  });

  const [deleteTeam] = useMutation<DeleteTeam, DeleteTeamVariables>(DELETE_TEAM, {
    onCompleted: (team) => {
      if (team.deleteTeam) {
        displaySuccessNotification('Team deleted successfully!');
        refetch();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Error deleting team');
      }
    },
  });

  const [addEditTeamModal, setAddEditTeamModal] = useState({
    active: false,
    teamId: '',
    teamName: '',
  });
  const closeModals = () => {
    setAddEditTeamModal({
      active: false,
      teamId: '',
      teamName: '',
    });
    refetch();
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'Members',
      dataIndex: '_count',
      key: 'members',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (count: any) => (
        <Text>{count.members}</Text>
      ),
    },
    authorized({
      viewer,
      role: Role.ADMIN,
      access: Access.ALTER_USERS,
    }) ? {
        title: 'Actions',
        key: 'actions',
        render: (team: {id: string, name: string}) => (
          <Space>
            <Button
              type="link"
              onClick={() => setAddEditTeamModal({
                active: true,
                teamId: team.id,
                teamName: team.name,
              })}
            >
              Edit
            </Button>
            <Popconfirm
              title="Are you sure you want to delete this team?"
              onConfirm={() => deleteTeam({ variables: { id: team.id } })}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link">
                <Text type="danger">
                  Delete
                </Text>
              </Button>
            </Popconfirm>
          </Space>
        ),
      } : {},
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTableChange = (pagination: any, _filters: unknown, sorter: any) => {
    if (pagination?.current) {
      setPage(pagination.current);
    }
    if (pagination?.size) {
      setLimit(pagination.size);
    }
    if (sorter?.columnKey) {
      setOrder({
        field: sorter.columnKey,
        direction: sorter.order === 'ascend' ? OrderByDirection.ASC : OrderByDirection.DESC,
      });
    }
  };

  return (
    <PrivateRoute
      role={Role.ADMIN}
    >
      <Container className={styles.container}>
        <p className={styles.overTitle}>Teams</p>
        <div className={styles.titleContainer}>
          <h1>View All Teams</h1>
          {
            authorized({
              viewer,
              access: Access.ALTER_USERS,
            }) && (
              <Button
                type="primary"
                onClick={() => setAddEditTeamModal({
                  active: true,
                  teamId: '',
                  teamName: '',
                })}
              >
                Create Team
              </Button>
            )
          }

        </div>

        <div className={styles.sectionContainer}>
          <Table
            columns={columns}
            dataSource={data?.teams?.items}
            scroll={{ x: 'max-content' }}
            loading={loading}
            pagination={{
              defaultPageSize: limit,
              defaultCurrent: page,
              total: data?.teams?.total,
            }}
            onChange={handleTableChange}
            rowKey="id"
          />
        </div>
        {
          addEditTeamModal.active && (
            <AddEditTeam
              active={addEditTeamModal.active}
              teamId={addEditTeamModal.teamId}
              teamName={addEditTeamModal.teamName}
              onClose={closeModals}
            />
          )
        }
      </Container>
    </PrivateRoute>
  );
};
