import { useMutation, useQuery } from '@apollo/client';
import { Button, Popconfirm, Space, Table, Typography } from 'antd';
import React, { useState } from 'react';
import { Container } from '../../components';
import { OrderByDirection, Role } from '../../lib/graphql/globalTypes';
import { Apps as AppsData, AppsVariables } from '../../lib/graphql/query/apps/__generated__/Apps';
import { Viewer } from '../../lib/types';
import { authorized, displayErrorMessage, displaySuccessNotification } from '../../lib/utils';
import { appStyles } from './styles';
import { APPS } from '../../lib/graphql/query/apps';
import { DeleteApp, DeleteAppVariables } from '../../lib/graphql/mutation/apps/__generated__/DeleteApp';
import { DELETE_APP } from '../../lib/graphql/mutation/apps';
import { AddEditApp } from '../../modals';

interface Props {
  viewer: Viewer
}

const { Text } = Typography;

export const Apps: React.FC<Props> = ({ viewer }) => {
  const styles = appStyles();

  const [modalActive, setModalActive] = useState<boolean | string>(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [order, setOrder] = useState({ field: 'name', direction: OrderByDirection.ASC });
  const { data, loading, refetch } = useQuery<AppsData, AppsVariables>(APPS, {
    variables: {
      page,
      total: 20,
      orderBy: order,
    },
  });

  const [deleteApp] = useMutation<DeleteApp, DeleteAppVariables>(DELETE_APP, {
    onCompleted: (app) => {
      if (app.deleteApp) {
        displaySuccessNotification('App deleted successfully!');
        refetch();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Error deleting app');
      }
    },
  });

  const onModalClose = () => {
    setModalActive(false);
    refetch();
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    authorized({
      viewer,
      role: Role.ADMIN,
    }) ? {
        title: 'Actions',
        key: 'actions',
        render: (item: {id: string, name: string}) => (
          <Space>
            <Button
              type="link"
              onClick={() => setModalActive(item.id)}
            >
              Edit
            </Button>
            <Popconfirm
              title="Are you sure you want to delete this app?"
              onConfirm={() => deleteApp({ variables: { id: item.id } })}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link">
                <Text type="danger">
                  Delete
                </Text>
              </Button>
            </Popconfirm>
          </Space>
        ),
      } : {},
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTableChange = (pagination: any, _filters: unknown, sorter: any) => {
    if (pagination?.current) {
      setPage(pagination.current);
    }
    if (pagination?.size) {
      setLimit(pagination.size);
    }
    if (sorter?.columnKey) {
      setOrder({
        field: sorter.columnKey,
        direction: sorter.order === 'ascend' ? OrderByDirection.ASC : OrderByDirection.DESC,
      });
    }
  };

  return (
    <Container className={styles.container}>
      <p className={styles.overTitle}>Apps</p>
      <div className={styles.titleContainer}>
        <h1>View All Apps</h1>
        {
          authorized({
            viewer,
            role: Role.ADMIN,
          }) && (
            <Button
              type="primary"
              onClick={() => setModalActive(true)}
            >
              Create App
            </Button>
          )
        }
      </div>

      <div className={styles.sectionContainer}>
        <Table
          columns={columns}
          dataSource={data?.apps?.items}
          scroll={{ x: 'max-content' }}
          loading={loading}
          pagination={{
            defaultPageSize: limit,
            defaultCurrent: page,
            total: data?.apps?.total,
          }}
          onChange={handleTableChange}
          rowKey="id"
        />
      </div>
      <AddEditApp
        active={modalActive}
        onClose={onModalClose}
      />
    </Container>
  );
};
