import { useMutation, useQuery } from '@apollo/client';
import { Button, Popconfirm, Space, Table, Typography } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Access, CommissionStatus, OrderByDirection } from '../../../../lib/graphql/globalTypes';
import { DELETE_COMMISSION, DUPLICATE_COMMISSION } from '../../../../lib/graphql/mutation/commissions';
import { deleteCommission as deleteCommissionType, deleteCommissionVariables } from '../../../../lib/graphql/mutation/commissions/__generated__/deleteCommission';
import { COMMISSIONS } from '../../../../lib/graphql/query/commissions';
import { commissions, commissionsVariables } from '../../../../lib/graphql/query/commissions/__generated__/commissions';
import { Viewer } from '../../../../lib/types';
import { authorized, displayErrorMessage, displaySuccessNotification, formatDate } from '../../../../lib/utils';
import { CreateCommission, EditCommissions } from '../../../../modals';
import { commissionsTableStyles } from './styles';
import { Users, UsersVariables } from '../../../../lib/graphql/query/users/__generated__/Users';
import { USERS } from '../../../../lib/graphql/query/users';
import { DuplicateCommission, DuplicateCommissionVariables } from '../../../../lib/graphql/mutation/commissions/__generated__/DuplicateCommission';

interface Props {
  status: 'pending' | 'scheduled' | 'completed' | 'declined';
  viewer: Viewer;
  createCommissionModalOpen: boolean;
  setCreateCommissionModalOpen: (open: boolean) => void;
}

const { Text } = Typography;

export const CommissionsTable: React.FC<Props> = ({
  status,
  viewer,
  createCommissionModalOpen,
  setCreateCommissionModalOpen,
}) => {
  const styles = commissionsTableStyles();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [lookup, setLookup] = useState('');
  const [userId, setUserId] = useState<null | string>(null);
  const [order, setOrder] = useState({ field: 'createdAt', direction: OrderByDirection.DESC });
  const { data, loading, refetch } = useQuery<commissions, commissionsVariables>(COMMISSIONS, {
    variables: {
      page,
      total: limit,
      orderBy: order,
      lookup,
      status: status.toUpperCase() as CommissionStatus,
      userId,
    },
  });

  const { data: users } = useQuery<Users, UsersVariables>(USERS, {
    variables: {
      page: 1,
      total: 200,
      orderBy: {
        field: 'email',
        direction: OrderByDirection.ASC,
      },
    },
  });

  const [selectedCommission, setSelectedCommission] = React.useState<string | null>(null);

  const [deleteCommission] = useMutation<deleteCommissionType, deleteCommissionVariables>(DELETE_COMMISSION, {
    onCompleted: (commission) => {
      if (commission.deleteCommission) {
        displaySuccessNotification('Team deleted successfully!');
        refetch();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Error deleting commission');
      }
    },
  });

  const [duplicateCommission] = useMutation<DuplicateCommission, DuplicateCommissionVariables>(DUPLICATE_COMMISSION, {
    onCompleted: (commission) => {
      if (commission.duplicateCommission) {
        displaySuccessNotification('Commission duplicated successfully!');
        refetch();
        setSelectedCommission(commission.duplicateCommission.id);
      }
    },
  });

  const handleClose = () => {
    setSelectedCommission(null);
    setCreateCommissionModalOpen(false);
    refetch();
  };


  useEffect(() => {
    refetch();
  }, [refetch]);

  const columns = [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: formatDate,
      sorter: true,
    },
    {
      title: 'Quote Reference',
      key: 'reference',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (item: any) => (
        (!item.lastViewed || dayjs(item.lastViewed).isBefore(dayjs().subtract(30, 'day'))) ? (
          <span className={styles.boldText}>{item.reference}</span>
        ) : (
          <span>{item.reference}</span>
        )
      ),
    },
    {
      title: 'User',
      dataIndex: 'users',
      key: 'users',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (u: any) => u.map((user: any, index: number) => (
        <Text key={user.id}>
          {user.firstName}
          {' '}
          {user.lastName}
          <React.Fragment>
            {
              u.length > 0 && u.length - 1 !== index && ', '
            }
          </React.Fragment>
        </Text>
      )),
      filters: [
        ...(
          users?.users?.items ? users.users.items.map((user) => ({
            text: `${user.firstName} ${user.lastName}`,
            value: user.id,
          })) : []
        ),
      ],
      filterSearch: true,
    },
    {
      title: 'Client',
      dataIndex: 'client',
      key: 'client',
    },
    {
      title: 'Total',
      dataIndex: 'totalExVat',
      key: 'totalExVat',
      render: (totalExVat: number) => `£${totalExVat}`,
    },
    authorized({
      viewer,
      access: Access.ALTER_COMMISSIONS,
    })
      ? {
        title: 'Actions',
        key: 'actions',
        render: (commission: { id: string, name: string }) => (
          <Space>
            <Button
              type="link"
              onClick={() => setSelectedCommission(commission.id)}
            >
              Edit
            </Button>

            <Popconfirm
              title="Are you sure you want to duplicate this commission?"
              onConfirm={() => duplicateCommission({
                variables: {
                  id: commission.id,
                },
              })}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="link"
              >
                Duplicate
              </Button>
            </Popconfirm>
            <Popconfirm
              title="Are you sure you want to delete this commission?"
              onConfirm={() => deleteCommission({ variables: { id: commission.id } })}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link">
                <Text type="danger">
                  Delete
                </Text>
              </Button>
            </Popconfirm>
          </Space>
        ),
      } : {},
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (pagination?.current) {
      setPage(pagination.current);
    }
    if (pagination?.size) {
      setLimit(pagination.size);
    }
    if (filters) {
      if (filters.users) {
        setUserId(filters.users[0]);
      } else {
        setUserId(null);
      }
    }
    if (sorter?.columnKey) {
      setOrder({
        field: sorter.columnKey,
        direction: sorter.order === 'ascend' ? OrderByDirection.ASC : OrderByDirection.DESC,
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.filterContainer}>
        <div>
          <Search
            onSearch={(str: string) => setLookup(str)}
          />
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={data?.commissions.items}
        scroll={{ x: 'max-content' }}
        loading={loading}
        pagination={{
          defaultPageSize: limit,
          defaultCurrent: page,
          total: data?.commissions?.total,
        }}
        onChange={handleTableChange}
        rowKey="id"
      />
      {
        selectedCommission && (
          <EditCommissions
            id={selectedCommission}
            visible={Boolean(selectedCommission)}
            onClose={() => { handleClose(); }}
          />
        )
      }
      <CreateCommission
        visible={createCommissionModalOpen}
        onClose={() => handleClose()}
      />
    </div>
  );
};
