/* eslint-disable camelcase */
import Table from 'antd/lib/table';
import React from 'react';
import { Link } from 'react-router-dom';
import { Overview_overview_team } from '../../../../lib/graphql/query/overview/__generated__/Overview';
import { formatLargeNumbers } from '../../../../lib/utils';
import { colors } from '../../../../styles';
import { overviewTableStyles } from './styles';

interface Props {
  team: Overview_overview_team[] | [];
  totalTeam: number
  page: number
  setPage: (page: number) => void
  setTotal: (total: number) => void
  limit: number
}

const columns = [
  {
    title: 'Name',
    key: 'Name',
    sorter: true,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    render: (item: any) => (
      <Link
        style={{ color: colors.primary }}
        to={`/users/${item.id}`}
      >
        {item.name}
      </Link>
    ),
  },
  {
    title: 'Booked',
    dataIndex: 'bookedJobs',
    key: 'bookedJobs',
  },
  {
    title: 'Completed',
    dataIndex: 'completedJobs',
    key: 'completedJobs',
  },
  {
    title: 'Absences',
    dataIndex: 'absences',
    key: 'absences',
  },
  {
    title: 'Stock Orders',
    dataIndex: 'stockOrders',
    key: 'stockOrders',
  },
  {
    title: 'Call Outs',
    dataIndex: 'callOuts',
    key: 'callOuts',
  },
  {
    title: 'Booked Revenue',
    dataIndex: 'bookedRevenue',
    key: 'bookedRevenue',
    render: (value: number) => `£${formatLargeNumbers(value)}`,
  },
  {
    title: 'Completed Revenue',
    dataIndex: 'completedRevenue',
    key: 'completedRevenue',
    render: (value: number) => `£${formatLargeNumbers(value)}`,
  },
  {
    title: 'Hours On Site',
    dataIndex: 'totalHours',
    key: 'totalHours',
    render: (value: number) => {
      const hours = Math.floor(value / 60);
      const minutes = value % 60;
      return hours > 0 ? `${hours}h ${minutes}m` : '';
    },
  },
];

export const OverviewTable: React.FC<Props> = ({
  team,
  totalTeam,
  page,
  setPage,
  setTotal,
  limit,
}) => {
  const styles = overviewTableStyles();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  const handleTableChange = (pagination: any, _: unknown, sorter: any, __: unknown, lookupString?: string) => {
    if (pagination?.current) {
      setPage(pagination.current);
    }
    if (pagination?.size) {
      setTotal(pagination.size);
    }
  };

  return (
    <div className={styles.container}>
      <h2>A Team Overview</h2>
      <Table
        columns={columns}
        dataSource={team}
        scroll={{ x: 'max-content' }}
        rowKey="id"
        pagination={{
          defaultPageSize: limit,
          defaultCurrent: page,
          total: totalTeam,
        }}
        onChange={handleTableChange}
      />
    </div>
  );
};
