import { createUseStyles } from 'react-jss';
import { base, colors } from '../../styles';

export const objectiveCardsStyles = createUseStyles({
  container: {
    width: '100%',
    padding: base(0.5),
    height: base(8),

    background: colors.primary,
    '& *': {
      color: colors.white,
    },
    '& p': {
      fontSize: base(0.8),
    },
  },
  colContainer: {
    width: '100%',
    height: '100%',
    textAlign: 'right',
  },
});
