import { createUseStyles } from 'react-jss';
import { base, overTitle, queries } from '../../styles';

export const dashboardStyles = createUseStyles({
  container: {

  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: base(2),
    alignItems: 'center',

    [queries.md]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  overTitle: {
    ...overTitle,
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: base(0.5),
      marginLeft: base(0.5),
    },
    '& .invx-select-selector': {
      minWidth: base(10),
    },
  },
});
