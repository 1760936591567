import { Col, DatePicker, Form, FormInstance, Input, Row, Select } from 'antd';
import React, { useState } from 'react';

interface Props {
  form: FormInstance
}

const { Item } = Form;
const { Option } = Select;

export const SiteDetail: React.FC<Props> = ({
  form,
}) => {
  const [differentDetails, setDifferentDetails] = useState(false);
  const [notPaperlessSiteFolder, setNotPaperlessSiteFolder] = useState(false);
  const [contractDifferent, setContractDifferent] = useState(false);

  // TODO default values

  return (
    <React.Fragment>
      <Item
        name="siteAddress"
        label="Site Address"
        required
      >
        <Input.TextArea />
      </Item>
      <Item
        name="primaryContactName"
        label="Primary Contact Name"
        required
      >
        <Input />
      </Item>
      <Row
        gutter={24}
      >
        <Col span={12}>
          <Item
            name="primaryContactPhone"
            label="Primary Contact Phone"
            required
          >
            <Input />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            name="primaryContactEmail"
            label="Primary Contact Email"
            required
            rules={[
              {
                type: 'email',
              },
            ]}
          >
            <Input />
          </Item>
        </Col>
      </Row>
      <Row
        gutter={24}
      >
        <Col span={8}>
          <Item
            name="openingTimes"
            label="Opening Times"
            required
            initialValue="8.00am to 18.00pm "
          >
            <Input />
          </Item>
        </Col>
        <Col span={8}>
          <Item
            name="openingTimesWeekend"
            label="Opening Times Weekend"
            required
          >
            <Input />
          </Item>
        </Col>
        <Col span={8}>
          <Item
            name="restrictedTimes"
            label="Restricted Times"
            required
            initialValue=""
          >
            <Input />
          </Item>
        </Col>
      </Row>
      <Item
        name="differentDetails"
        label="Different Details"
        required
        initialValue="no"
      >
        <Select
          onChange={(value) => {
            setDifferentDetails(value === 'yes');
          }}
        >
          <Option value="no">No</Option>
          <Option value="yes">Yes</Option>
        </Select>
      </Item>
      {
        differentDetails && (
          <React.Fragment>
            <Row gutter={24}>
              <Col span={12}>
                <Item
                  name="customerName"
                  label="Customer Name"
                  required
                >
                  <Input />
                </Item>
              </Col>
              <Col span={12}>
                <Item
                  name="customerContactName"
                  label="Customer Contact Name"
                  required
                >
                  <Input />
                </Item>
              </Col>
            </Row>
            <Item
              name="siteFolder"
              label="Site Folder"
              required
              initialValue="no"
            >
              <Select
                onChange={(value) => {
                  setNotPaperlessSiteFolder(value === 'yesNotPaperless');
                }}
              >
                <Option value="no">No</Option>
                <Option value="yes">Yes</Option>
                <Option value="yesNotPaperless">Yes, None Paperless</Option>
              </Select>
            </Item>
            {
              notPaperlessSiteFolder && (
                <Item
                  name="notPaperlessReson"
                  label="Reasons for none paperless site folder"
                  required
                >
                  <Input.TextArea />
                </Item>
              )
            }
            <Item
              name="customerAddress"
              label="Customer Address"
              required
            >
              <Input.TextArea />
            </Item>
            <Item
              name="contractDifferent"
              label="Different Contract Details"
              required
              initialValue="no"
            >
              <Select
                onChange={(value) => {
                  setContractDifferent(value === 'yes');
                }}
              >
                <Option value="no">No</Option>
                <Option value="yes">Yes</Option>
              </Select>
            </Item>
            {
              contractDifferent && (
                <React.Fragment>
                  <Item
                    name="contractName"
                    label="Contract Name"
                  >
                    <Input />
                  </Item>
                  <Item
                    name="contractAddress"
                    label="Contract Address"
                  >
                    <Input.TextArea />
                  </Item>
                  <Item
                    name="contractContactName"
                    label="Contract Contact Name"
                  >
                    <Input />
                  </Item>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Item
                        name="contractEmail"
                        label="Contract Email"
                        rules={[{
                          type: 'email',
                        }]}
                      >
                        <Input />
                      </Item>
                    </Col>
                    <Col span={12}>
                      <Item
                        name="contractNumber"
                        label="Contract Number"
                      >
                        <Input />
                      </Item>
                    </Col>
                  </Row>
                  <Item
                    name="contractStartDate"
                    label="Contract Start Date"
                    required
                  >
                    <DatePicker />
                  </Item>
                  <Item
                    name="contractNotes"
                    label="Contract Notes"
                    required
                  >
                    <Input.TextArea />
                  </Item>
                </React.Fragment>
              )
            }
          </React.Fragment>
        )
      }
    </React.Fragment>

  );
};
