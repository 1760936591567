import gql from 'graphql-tag';

export const PRODUCT_CATEGORIES = gql`
  query ProductCategories($page: Int!, $total: Int!, $orderBy: OrderBy, $lookup: String) {
    productCategories(page: $page, total: $total, orderBy: $orderBy, lookup: $lookup) {
      total
      items {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;

export const PRODUCT_CATEGORY = gql`
  query ProductCategory($id: ID!) {
    productCategory(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const PRODUCTS = gql`
  query Products($page: Int!, $total: Int!, $orderBy: OrderBy, $lookup: String) {
    products(page: $page, total: $total, orderBy: $orderBy, lookup: $lookup) {
      total
      items {
        id
        name
        categoryId
        category {
          id
          name
        }
        make
        price
        createdAt
        updatedAt
      }
    }
  }
`;

export const PRODUCT = gql`
  query Product($id: ID!) {
    product(id: $id) {
      id
      name
      categoryId
      make
      price
      createdAt
      updatedAt
    }
  }
`;
