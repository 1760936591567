import { Button, Form, Input, Modal } from 'antd';
import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { modalWidth } from '../../styles/sizes';
import { getJobReportStyles } from './styles';
import { GetJobReport as GetJobReportData, GetJobReportVariables } from '../../lib/graphql/query/misc/__generated__/GetJobReport';
import { GET_JOB_REPORT } from '../../lib/graphql/query/misc';
import { displayErrorMessage } from '../../lib/utils';

interface Props {
  active: boolean;
  onClose: () => void;
}

export const GetJobReport: React.FC<Props> = ({
  active,
  onClose,
}) => {
  const styles = getJobReportStyles();

  const [getJobReport, { loading }] = useLazyQuery<GetJobReportData, GetJobReportVariables>(GET_JOB_REPORT, {
    onError(error) {
      if (error) {
        displayErrorMessage('Sorry! We were unable to get the job report. Please try again later!');
      }
    },
    onCompleted(data) {
      if (data && data.getJobReport) {
        const fetchDataModified = `data:application/pdf;base64,${data.getJobReport}`;
        const a = document.createElement('a');
        a.href = fetchDataModified;
        a.download = 'jobCards.pdf';
        a.click();
      }
    },
  });

  const onSubmit = ({ jobRef }: { jobRef: string }) => {
    getJobReport({
      variables: {
        jobRef,
      },
    });
  };

  return (
    <Modal
      title="Got Job Report"
      open={active}
      onCancel={onClose}
      // onOk={() => form.submit()}
      width={modalWidth}
    >
      <div className={styles.container}>
        <Form
          layout="vertical"
          onFinish={onSubmit}
        >
          <Form.Item
            label="Job Reference"
            name="jobRef"
            rules={[{ required: true, message: 'Please input a job Reference!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Generate Report
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
