import { createUseStyles } from 'react-jss';
import { base, colors, queries } from '../../../styles';
import { headerHeight } from '../../../styles/sizes';

export const appSiderStyles = createUseStyles({
  container: {
    backgroundColor: colors.white,
    height: '100vh',

    [queries.md]: {
      display: 'none',
    },
  },
  menuContainer: {
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: headerHeight,
    marginBottom: base(),
  },
  menu: {
    border: 0,
    '& li': {
      margin: base(0.3),

    },
    '& .invx-menu-title-content': {
      fontWeight: 500,
      color: colors.grey,
    },
    '& .anticon': {
      fontSize: base(1),
      color: colors.grey,
    },

    '& .invx-menu-item-selected': {
      boxShadow: '0px 0px 10px 3px rgba(0,0,0,0.1)',
      backgroundColor: `${colors.primary} !important`,
      '& a, & span': {
        color: colors.white,
      },
    },
  },
  optionContainer: {
    position: 'absolute',
    bottom: 20,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  logoutButton: {
    border: 0,
  },
});
