import { useMutation, useQuery } from '@apollo/client';
import { Button, Popconfirm, Space, Table, Typography } from 'antd';
import React, { useState } from 'react';
import { Container } from '../../components';
import { GPC_LABOUT_COSTS } from '../../lib/graphql/query/gpcPricing';
import { GpcLabourCosts } from '../../lib/graphql/query/gpcPricing/__generated__/GpcLabourCosts';
import { Viewer } from '../../lib/types';
import { AddEditGpcRate } from '../../modals';
import { gpcRatesStyles } from './styles';
import { authorized, displayErrorMessage, displaySuccessNotification, sentenceCase } from '../../lib/utils';
import { Role } from '../../lib/graphql/globalTypes';
import { deleteLabourCost, deleteLabourCostVariables } from '../../lib/graphql/mutation/gpcPricing/__generated__/deleteLabourCost';
import { DELETE_LABOUR_COST } from '../../lib/graphql/mutation/gpcPricing';

interface Props {
  viewer: Viewer
}

const { Text } = Typography;

export const GpcRates: React.FC<Props> = ({ viewer }) => {
  const styles = gpcRatesStyles();
  const [addEditGpcRateModalVisible, setAddEditGpcRateModalVisible] = useState(false);
  const [selectedRate, setSelectedRate] = useState<null | string>(null);
  const { data, refetch } = useQuery<GpcLabourCosts>(GPC_LABOUT_COSTS);
  const [deleteGpcRate] = useMutation<deleteLabourCost, deleteLabourCostVariables>(DELETE_LABOUR_COST, {
    onCompleted: (d) => {
      if (d.deleteLabourCost) {
        displaySuccessNotification('GPC Rate deleted successfully!');
        refetch();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Error deleting GPC Rate');
      }
    },
  });

  const onModalClose = () => {
    setAddEditGpcRateModalVisible(false);
    setSelectedRate(null);
    refetch();
  };

  const columns = [
    {
      name: 'name',
      title: 'Name',
      dataIndex: 'name',
    },
    {
      name: 'unit',
      title: 'Unit',
      dataIndex: 'unit',
      render: (unit: string) => sentenceCase(unit),
    },
    {
      name: 'rate',
      title: 'Rate',
      dataIndex: 'rate',
      render: (rate: string) => `£${rate}`,
    },
    authorized({
      viewer,
      role: Role.ADMIN,
    })
      ? {
        title: 'Actions',
        key: 'actions',
        width: 10,
        render: (gpcRate: { id: string, name: string }) => (
          <Space>
            <Button
              type="link"
              onClick={() => {
                setSelectedRate(gpcRate.id);
                setAddEditGpcRateModalVisible(true);
              }}
            >
              Edit
            </Button>
            <Popconfirm
              title="Are you sure you want to delete this Gpc Rate?"
              onConfirm={() => deleteGpcRate({ variables: { id: gpcRate.id } })}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link">
                <Text type="danger">
                  Delete
                </Text>
              </Button>
            </Popconfirm>
          </Space>
        ),
      } : {},
  ];

  return (
    <Container className={styles.container}>
      <p className={styles.overTitle}>Pricing Documents</p>
      <div className={styles.titleContainer}>
        <h1>GPC Rates</h1>
        <Button
          type="primary"
          onClick={() => setAddEditGpcRateModalVisible(true)}
        >
          Add New
        </Button>
      </div>

      <div className={styles.sectionContainer}>
        <Table
          columns={columns}
          dataSource={data?.gpcLabourCosts}
        />
      </div>
      {
        (addEditGpcRateModalVisible) ? (
          <AddEditGpcRate
            active={addEditGpcRateModalVisible}
            onClose={onModalClose}
            selectedRate={selectedRate}
          />
        ) : null
      }
    </Container>
  );
};
