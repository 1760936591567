import { FullscreenExitOutlined, FullscreenOutlined, PoundCircleOutlined, ReconciliationFilled, UserOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Container, ProgressStat, StatCard } from '../../../components';
import { colors } from '../../../styles';
import { screenOneStyles } from './styles';
import { DashboardScreenOneData } from '../../../lib/graphql/query/misc/__generated__/DashboardScreenOneData';
import { DASHBOARD_SCREEN_ONE_DATA } from '../../../lib/graphql/query/misc';
import { ActionsDonutPlot, SiteRiskBarPlot } from '../components';

export const ScreenOne: React.FC = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const styles = screenOneStyles({
    isFullscreen,
  });

  const { data } = useQuery<DashboardScreenOneData>(DASHBOARD_SCREEN_ONE_DATA);

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        setIsFullscreen(false);
      } else {
        setIsFullscreen(true);
      }
    };
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const goFullscreen = () => {
    setIsFullscreen(!isFullscreen);
    if (document.fullscreenEnabled && !isFullscreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  return (
    <Container
      className={styles.container}
    >
      <div className={styles.titleContainer}>
        {/* <span>Welcome Back</span>
        <h1>Contego Dashboard</h1> */}
        <Button
          onClick={goFullscreen}
          className={styles.fullscreenButton}
          type="link"
        >
          {
            isFullscreen ? (
              <FullscreenExitOutlined className={styles.fullscreenIcon} />
            ) : (
              <FullscreenOutlined className={styles.fullscreenIcon} />
            )
          }
        </Button>
      </div>
      <div className={styles.statCardsContainer}>
        <Row gutter={24}>
          <Col span={6}>
            <StatCard
              title="Total Completed Jobs"
              value={`${data?.dashboardScreenOneData.totalJobs}` || '0'}
              icon={<ReconciliationFilled />}
            />
          </Col>
          <Col span={6}>
            <StatCard
              title="Total Techs"
              value={`${data?.dashboardScreenOneData.totalTechs}` || '0'}
              icon={<UserOutlined />}
            />
          </Col>
          <Col span={6}>
            <StatCard
              title="Percentage of Booked Revenue"
              value="41%"
              icon={<PoundCircleOutlined />}
            />
          </Col>
        </Row>
      </div>
      <div className={styles.statCardsContainer}>
        <Row gutter={24}>
          <Col span={6}>
            <StatCard
              title="Total Site Set Ups"
              value={`${data?.dashboardScreenOneData.totalSiteSetUps}` || '0'}
            />
          </Col>
          <Col span={6}>
            <StatCard
              title="Tech Audits"
              value={`${data?.dashboardScreenOneData.totalTechAudits}` || '0'}
            />
          </Col>
          <Col span={6}>
            <StatCard
              title="Complaints"
              value={`${data?.dashboardScreenOneData.complaints}` || '0'}
              color="primary"
            />
          </Col>
        </Row>
      </div>
      <div className={styles.tableContainer}>
        <Row
          gutter={24}
        >
          <Col span={16}>
            {/* {
              data && (
                <BidirectionalBar
                  data={data.dashboardOneData.graphData as unknown as Record<string, any>[]}
                  layout="vertical"
                  xField="months"
                  yField={['completedJobs', 'completedCallOuts']}
                  yAxis={{
                    completedJobs: {
                      nice: true,
                    },
                    completedCallOuts: {
                      nice: true,
                    },
                  }}
                  tooltip={{
                    shared: true,
                    showMarkers: false,
                  }}
                />
              )
            } */}
            <Row>
              <Col span={12}>
                <SiteRiskBarPlot
                  veryHigh={data?.dashboardScreenOneData.sitesSummary.veryHighRisk}
                  high={data?.dashboardScreenOneData.sitesSummary.highRisk}
                  medium={data?.dashboardScreenOneData.sitesSummary.mediumRisk}
                  low={data?.dashboardScreenOneData.sitesSummary.lowRisk}
                />
              </Col>
              <Col span={12}>
                <ActionsDonutPlot
                  high={data?.dashboardScreenOneData.actionsSummary.highRisk}
                  medium={data?.dashboardScreenOneData.actionsSummary.mediumRisk}
                  low={data?.dashboardScreenOneData.actionsSummary.lowRisk}
                  veryHigh={data?.dashboardScreenOneData.actionsSummary.veryHighRisk}
                />
              </Col>
            </Row>
          </Col>
          <Col
            span={8}
            className="dashOne-compliance"
          >
            <h2>Compliance</h2>
            <ProgressStat
              title="Near Miss"
              value={data?.dashboardScreenOneData.nearMisses || 0}
              color={colors.red}
            />
            <ProgressStat
              title="Accidents"
              value={data?.dashboardScreenOneData.accidents || 0}
              color={colors.red}
            />
            <ProgressStat
              title="Investigation"
              value={data?.dashboardScreenOneData.investigations || 0}
              color={colors.red}
            />
          </Col>
        </Row>
      </div>
    </Container>
  );
};
