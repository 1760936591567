import { createUseStyles } from 'react-jss';
import { base, colors } from '../../styles';

export const loginStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  logo: {
    margin: '0 auto',
    marginBottom: base(),
  },
  loginContainer: {
    background: colors.white,

    maxWidth: 500,
    width: '100%',
    border: `1px solid ${colors.border}`,
    padding: `${base(4)} ${base(2)}`,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',

    height: '100%',

    '&:first-child': {
      marginRight: base(2),
    },
  },
  title: {
    fontSize: base(1.3),
  },
  notice: {
    color: colors.red,
  },
  submitButtonContainer: {
    marginTop: base(2),
    '& > *:nth-child(odd)': {
      marginRight: base(),
    },
  },
  appStoresContainer: {
    marginTop: base(),
    '& > *:nth-child(odd)': {
      marginRight: base(),
    },
  },
});
