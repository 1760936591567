import gql from 'graphql-tag';

export const CREATE_LABOUR_COST = gql`
  mutation createLabourCost($input: CreateEditLabourCostInput!) {
    createLabourCost(input: $input) {
      id
    }
  }
`;

export const UPDATE_LABOUR_COST = gql`
  mutation updateLabourCost($id: ID!, $input: CreateEditLabourCostInput!) {
    updateLabourCost(id: $id, input: $input) {
      id
    }
  }
`;

export const DELETE_LABOUR_COST = gql`
  mutation deleteLabourCost($id: ID!) {
    deleteLabourCost(id: $id)
  }
`;

export const CREATE_EQUIPMENT = gql`
  mutation createEquipment($input: CreateEditGpcEquipmentInput!) {
    createEquipment(input: $input) {
      id
    }
  }
`;

export const UPDATE_EQUIPMENT = gql`
  mutation updateEquipment($id: ID!, $input: CreateEditGpcEquipmentInput!) {
    updateEquipment(id: $id, input: $input) {
      id
    }
  }
`;

export const DELETE_EQUIPMENT = gql`
  mutation deleteEquipment($id: ID!) {
    deleteEquipment(id: $id)
  }
`;
