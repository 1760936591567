import { useQuery } from '@apollo/client';
import { Button } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container } from '../../components';
import { Role } from '../../lib/graphql/globalTypes';
import { KPI_EARLY_WARNING_CATEGORIES } from '../../lib/graphql/query/kpiEarlyWarnings';
import { Viewer } from '../../lib/types';
import { authorized } from '../../lib/utils';
import { AddKpiCategory } from '../../modals';
import { kpiEarlyWarningStyles } from './styles';
import { KpiEarlyWarningCategories as KpiEarlyWarningCategoriesData } from '../../lib/graphql/query/kpiEarlyWarnings/__generated__/KpiEarlyWarningCategories';

interface Props {
  viewer: Viewer
}

export const KpiEarlyWarningCategories: React.FC<Props> = ({
  viewer,
}) => {
  const styles = kpiEarlyWarningStyles();
  const [modalActive, setModalActive] = useState<boolean>(false);

  const { data, refetch } = useQuery<KpiEarlyWarningCategoriesData>(KPI_EARLY_WARNING_CATEGORIES);

  const onModalClose = () => {
    refetch();
    setModalActive(false);
  };

  return (
    <Container className={styles.container}>
      <p>KPI Early Warning</p>
      <div className={styles.titleContainer}>
        <h1 className={styles.title}>KPI Early Warning Categories</h1>
        {
          (authorized({ viewer, role: Role.SUPER_ADMIN })) && (
            <Button
              type="primary"
              onClick={() => setModalActive(true)}
            >
              Create a category
            </Button>
          )
        }
      </div>
      <div className={styles.objectivesContainer}>
        <div className={styles.innerContainer}>
          {
            data?.kpiEarlyWarningCategories.map((category) => (
              <Link to={`/kpi-early-warning/${category.id}`}>
                <div className={`${styles.kpiCard}`}>
                  <h2 className={styles.cardTitles}>{category.name}</h2>
                </div>
              </Link>
            ))
          }
        </div>
      </div>
      {
        Boolean(modalActive) && (
          <AddKpiCategory
            active={modalActive}
            onClose={onModalClose}
          />
        )
      }
    </Container>
  );
};
