import gql from 'graphql-tag';

export const CONVERT_POSTCODES_TO_LAT_LNG = gql`
  mutation ConvertPostcodesToLatLong($postcodes: [ConvertPostcodesToLatLngInput!]!) {
    convertPostcodesToLatLng(postcodes: $postcodes) {
      name
      postcode
      lat
      lng   
    }
  }
`;
