import { useMutation } from '@apollo/client';
import { Button, Form, Input } from 'antd';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container } from '../../components';
import { Logo } from '../../components/graphics';
import { FORGOT_PASSWORD } from '../../lib/graphql/mutation/users';
import { ForgotPassword as ForgotPasswordData, ForgotPasswordVariables } from '../../lib/graphql/mutation/users/__generated__/ForgotPassword';
import { displayErrorMessage, displaySuccessNotification } from '../../lib/utils';
import { forgotPasswordStyles } from './styles';

const { Item } = Form;

export const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const styles = forgotPasswordStyles();
  const [forgotPassword, { loading }] = useMutation<ForgotPasswordData, ForgotPasswordVariables>(FORGOT_PASSWORD, {
    onCompleted: (data) => {
      if (data && data.forgotPassword) {
        displaySuccessNotification('Check your email for a reset link!');
        navigate('/');
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Sorry! We were unable to send you a reset link. Please try again later!');
      }
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (values: any) => {
    forgotPassword({
      variables: {
        email: values.email,
      },
    });
  };

  return (
    <Container className={styles.container}>
      <div className={styles.modalContainer}>
        <div className={styles.logoContainer}>
          <Logo />
        </div>

        <h1 className={styles.title}>Forgot Password</h1>
        <p>
          Enter the email address associated with your account and we’ll send you instructions to reset your password.
        </p>
        <Form
          layout="vertical"
          onFinish={onFinish}
        >
          <Item
            name="email"
            label="Email"
            required
          >
            <Input />
          </Item>
          <div className={styles.buttonContainer}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Submit
            </Button>
          </div>
        </Form>
        <div className={styles.goBackContainer}>
          <Link to="/">
            <Button type="link">
              Back to Login
            </Button>
          </Link>
        </div>
      </div>
    </Container>
  );
};
