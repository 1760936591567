import { Button, Tabs } from 'antd';
import React, { useState } from 'react';
import { Container } from '../../components';
import { Access, Role } from '../../lib/graphql/globalTypes';
import { Viewer } from '../../lib/types';
import { authorized } from '../../lib/utils';
import { ExportCommissions, ImportCommissions } from '../../modals';
import { CommissionsTable } from './components';
import { commissionsStyles } from './styles';

interface Props {
  viewer: Viewer
}

export const Commissions: React.FC<Props> = ({ viewer }) => {
  const styles = commissionsStyles();
  const [exportCommissionsModalOpen, setExportCommissionsModalOpen] = useState(false);
  const [importCommissionModalOpen, setImportCommissionModalOpen] = useState(false);
  const [createCommissionModalOpen, setCreateCommissionModalOpen] = useState(false);

  const tabItems: any = [
    authorized({ viewer, role: Role.ADMIN, access: Access.ALTER_COMMISSIONS }) ? {
      label: 'Pending',
      key: 'pending',
      children: <CommissionsTable
        status="pending"
        viewer={viewer}
        createCommissionModalOpen={createCommissionModalOpen}
        setCreateCommissionModalOpen={setCreateCommissionModalOpen}
      />,
    } : null,
    {
      label: 'Scheduled',
      key: 'scheduled',
      children: <CommissionsTable
        status="scheduled"
        viewer={viewer}
        createCommissionModalOpen={createCommissionModalOpen}
        setCreateCommissionModalOpen={setCreateCommissionModalOpen}
      />,
    },
    {
      label: 'Complete',
      key: 'complete',
      children: <CommissionsTable
        status="completed"
        viewer={viewer}
        createCommissionModalOpen={createCommissionModalOpen}
        setCreateCommissionModalOpen={setCreateCommissionModalOpen}
      />,
    },
    {
      label: 'Declined',
      key: 'declined',
      children: <CommissionsTable
        status="declined"
        viewer={viewer}
        createCommissionModalOpen={createCommissionModalOpen}
        setCreateCommissionModalOpen={setCreateCommissionModalOpen}
      />,
    },
  ];

  return (
    <React.Fragment>
      <Container className={styles.container}>
        <p className={styles.overTitle}>Commissions</p>
        <div className={styles.titleContainer}>
          <h1>View All Commissions</h1>
          {
            authorized({
              viewer,
              access: Access.ALTER_COMMISSIONS,
            }) && (
              <React.Fragment>
                <Button
                  type="primary"
                  className={styles.createButton}
                  onClick={() => {
                    setCreateCommissionModalOpen(true);
                  }}
                >
                  Create Commission
                </Button>
                <Button
                  type="default"
                  onClick={() => {
                    setImportCommissionModalOpen(true);
                  }}
                >
                  Import Commissions
                </Button>
              </React.Fragment>
            )
          }
        </div>

        <Tabs items={tabItems} />

        <div className={styles.exportButtonContainer}>
          <Button
            type="primary"
            onClick={() => setExportCommissionsModalOpen(true)}
          >
            Export Commissions
          </Button>
        </div>
      </Container>

      {
        exportCommissionsModalOpen && (
          <ExportCommissions
            visible={exportCommissionsModalOpen}
            onCancel={() => setExportCommissionsModalOpen(false)}
          />
        )
      }

      <ImportCommissions
        open={importCommissionModalOpen}
        closeModal={() => setImportCommissionModalOpen(false)}
      />
    </React.Fragment>
  );
};
