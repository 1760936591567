import { useMutation, useQuery } from '@apollo/client';
import { Button, Popconfirm, Space, Table, Typography } from 'antd';
import React, { useState } from 'react';
import Search from 'antd/lib/input/Search';
import { Container, PrivateRoute } from '../../components';
import { Access, OrderByDirection, RiskLeadStatus, Role } from '../../lib/graphql/globalTypes';
import { DELETE_RISK_LEAD } from '../../lib/graphql/mutation/riskLeads';
import { DeleteRiskLead, DeleteRiskLeadVariables } from '../../lib/graphql/mutation/riskLeads/__generated__/DeleteRiskLead';
import { RISK_LEADS } from '../../lib/graphql/query/riskLeads';
import { RiskLeads as RiskLeadsData, RiskLeadsVariables } from '../../lib/graphql/query/riskLeads/__generated__/RiskLeads';
import { USERS } from '../../lib/graphql/query/users';
import { Users, UsersVariables } from '../../lib/graphql/query/users/__generated__/Users';
import { Viewer } from '../../lib/types';
import { authorized, displayErrorMessage, displaySuccessNotification, formatDate, sentenceCase } from '../../lib/utils';
import { AddEditRiskLead } from '../../modals';
import { colors } from '../../styles';
import { riskLeadsStyles } from './styles';

interface Props {
  viewer: Viewer
}

const { Text } = Typography;

export const RiskLeads: React.FC<Props> = ({ viewer }) => {
  const styles = riskLeadsStyles();
  const [modalActive, setModalActive] = useState<boolean | string>(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [order, setOrder] = useState({ field: 'date', direction: OrderByDirection.DESC });
  const [userId, setUserId] = useState<null | string>(null);
  const [status, setStatus] = useState<RiskLeadStatus | null>(null);
  const [requiresSurvey, setRequiresSurvey] = useState<null | boolean>(null);
  const [lookup, setLookup] = useState('');
  const { data, loading, refetch } = useQuery<RiskLeadsData, RiskLeadsVariables>(RISK_LEADS, {
    variables: {
      page,
      total: limit,
      orderBy: order,
      userId,
      status,
      requiresSurvey,
      lookup,
    },
  });
  const { data: users } = useQuery<Users, UsersVariables>(USERS, {
    variables: {
      page: 1,
      total: 1000,
      orderBy: {
        field: 'email',
        direction: OrderByDirection.DESC,
      },
    },
  });

  const [deleteRiskLead] = useMutation<DeleteRiskLead, DeleteRiskLeadVariables>(DELETE_RISK_LEAD, {
    onCompleted: (team) => {
      if (team.deleteRiskLead) {
        displaySuccessNotification('Team deleted successfully!');
        refetch();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Error deleting team');
      }
    },
  });

  const columns = [
    {
      title: 'View',
      dataIndex: 'jobId',
      key: 'jobId',
      render: (jobId: number) => (
        <a
          href={`https://client.bigchange.com/jobschedule.aspx?jobid=${jobId}`}
          target="_blank"
          rel="noreferrer"
          style={{ color: colors.primary }}
        >
          View
        </a>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: formatDate,
    },
    {
      title: 'Job Reference',
      dataIndex: 'jobReference',
      key: 'jobReference',
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: (user: { id: string, firstName: string, lastName: string }) => (user ? (
        <Text>
          {user.firstName}
          {' '}
          {user.lastName}
        </Text>
      ) : (
        <Text type="warning">
          Unknown
        </Text>
      )),
      filters: [
        ...(
          users?.users?.items ? users.users.items.map((user) => ({
            text: `${user.firstName} ${user.lastName}`,
            value: user.id,
          })) : []
        ),
      ],
      filterSearch: true,
      filterMultiple: false,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (s: string) => {
        switch (s) {
          case RiskLeadStatus.DECLINED:
            return <Text type="danger">{sentenceCase(s)}</Text>;
          case RiskLeadStatus.CANCELLED:
            return <Text type="danger">{sentenceCase(s)}</Text>;
          case RiskLeadStatus.ACCEPTED:
            return <Text type="success">{sentenceCase(s)}</Text>;
          case RiskLeadStatus.QUOTED:
            return <Text type="warning">{sentenceCase(s)}</Text>;
          case RiskLeadStatus.SURVEYING:
            return <Text type="warning">{sentenceCase(s)}</Text>;
          default:
            return <Text>{sentenceCase(s)}</Text>;
        }
      },
      filters: [
        ...(
          Object.keys(RiskLeadStatus) ? Object.keys(RiskLeadStatus).map((s) => (
            {
              text: sentenceCase(s),
              value: s,
            }
          )) : []
        ),
      ],
      filterMultiple: false,
    },
    {
      title: 'Requires Survey',
      dataIndex: 'requiresSurvey',
      key: 'requiresSurvey',
      render: (s: string) => {
        if (s) {
          return <Text>Yes</Text>;
        }
        return <Text>No</Text>;
      },
      filters: [
        {
          text: 'True',
          value: true,
        },
        {
          text: 'False',
          value: false,
        },
      ],
      filterMultiple: false,
    },
    {
      title: 'Client',
      dataIndex: 'client',
      key: 'client',
    },
    authorized({
      viewer,
      access: Access.ALTER_RISK_LEADS,
    })
      ? {
        title: 'Actions',
        key: 'actions',
        render: (riskLead: { id: string }) => (
          <Space>
            <Button
              type="link"
              onClick={() => setModalActive(riskLead.id)}
            >
              Edit
            </Button>
            <Popconfirm
              title="Are you sure you want to delete this risk lead?"
              onConfirm={() => deleteRiskLead({ variables: { id: riskLead.id } })}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="link"
              >
                <Text type="danger">
                  Delete
                </Text>
              </Button>
            </Popconfirm>
          </Space>
        ),
      } : {},
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (pagination?.current) {
      setPage(pagination.current);
    }
    if (pagination?.pageSize) {
      setLimit(pagination.pageSize);
    }
    if (filters) {
      if (filters.user) {
        setUserId(filters.user[0]);
      }

      if (filters.status) {
        setStatus(filters.status[0]);
      }

      if (filters.requiresSurvey !== undefined) {
        setRequiresSurvey(filters.requiresSurvey[0]);
      }
    }
    if (sorter?.columnKey) {
      setOrder({
        field: sorter.columnKey,
        direction: sorter.order === 'ascend' ? OrderByDirection.ASC : OrderByDirection.DESC,
      });
    }
  };

  const onModalClose = () => {
    setModalActive(false);
    refetch();
  };

  return (
    <PrivateRoute
      role={Role.ADMIN}
      access={Access.ALTER_RISK_LEADS}
    >
      <Container className={styles.container}>
        <p className={styles.overTitle}>Risk Leads</p>
        <div className={styles.titleContainer}>
          <h1>View All Risk Leads</h1>
          {
            authorized({
              viewer,
              access: Access.ALTER_USERS,
            }) && (
              <Button
                type="primary"
                onClick={() => setModalActive(true)}
              >
                Create Risk Lead
              </Button>
            )
          }

        </div>

        <div className={styles.sectionContainer}>
          <div className={styles.filterContainer}>
            <div>
              <Search
                onSearch={(str: string) => setLookup(str)}
              />
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={data?.riskLeads?.items}
            scroll={{ x: 'max-content' }}
            loading={loading}
            pagination={{
              defaultPageSize: limit,
              defaultCurrent: page,
              total: data?.riskLeads?.total,
            }}
            onChange={handleTableChange}
            rowKey="id"
          />
        </div>
      </Container>
      <AddEditRiskLead
        active={modalActive}
        onClose={onModalClose}
      />
    </PrivateRoute>
  );
};
