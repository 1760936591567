import { useMutation, useQuery } from '@apollo/client';
import { Col, DatePicker, Form, InputNumber, Modal, Row, Select } from 'antd';
import React from 'react';
import { CREATE_COMMISSION_PAYMENT } from '../../lib/graphql/mutation/commissionPayments';
import { CreateCommissionPayment, CreateCommissionPaymentVariables } from '../../lib/graphql/mutation/commissionPayments/__generated__/CreateCommissionPayment';
import { USERS } from '../../lib/graphql/query/users';
import { Users, UsersVariables } from '../../lib/graphql/query/users/__generated__/Users';
import { displayErrorMessage, displaySuccessNotification } from '../../lib/utils';
import { addCommissionPayment } from './styles';
import { OrderByDirection } from '../../lib/graphql/globalTypes';

interface Props {
  visible: boolean;
  commissionId: string;
  onClose: () => void;
}

const { Item } = Form;
const { Option } = Select;

export const AddCommissionPayment: React.FC<Props> = ({
  visible,
  commissionId,
  onClose,
}) => {
  const styles = addCommissionPayment();
  const [paymentType, setPaymentType] = React.useState('single');

  const [lookup, setLookup] = React.useState('');

  const { data: users } = useQuery<Users, UsersVariables>(USERS, {
    variables: {
      page: 1,
      total: 1000,
      lookup,
      orderBy: {
        direction: OrderByDirection.ASC,
        field: 'email',
      },
    },
  });

  const [createCommissionPayments] = useMutation<CreateCommissionPayment, CreateCommissionPaymentVariables>(CREATE_COMMISSION_PAYMENT, {
    onCompleted: (item) => {
      if (item.createCommissionPayment) {
        displaySuccessNotification('Payment has been added successfully');
        onClose();
      }
    },
    onError: (error) => {
      if (error.message) {
        displayErrorMessage('Unable to add payment');
      }
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (values: any) => {
    createCommissionPayments({
      variables: {
        commissionId,
        input: {
          ...values,
        },
      },
    });
  };

  const [form] = Form.useForm();

  return (
    <Modal
      visible={visible}
      title="Add Commission Payment"
      okText="Add Payment"
      cancelText="Cancel"
      onCancel={onClose}
      onOk={form.submit}
    >
      <div className={styles.container}>
        <Form
          layout="vertical"
          initialValues={{
            type: 'single',
          }}
          onFinish={onFinish}
          form={form}
        >
          <Item
            name="type"
            label="Type"
          >
            <Select onChange={(value) => {
              setPaymentType(value);
            }}
            >
              <Option value="single">One Off</Option>
              <Option value="recurring">Recurring</Option>
            </Select>
          </Item>

          <Item
            name="total"
            label="Total"
          >
            <InputNumber
              style={{
                width: '100%',
              }}
              prefix="£"
            />
          </Item>

          {
            paymentType === 'single' && (
              <React.Fragment>

                <Item
                  name="date"
                  label="Date"
                >
                  <DatePicker
                    picker="month"
                  />
                </Item>
              </React.Fragment>
            )
          }

          {
            paymentType === 'recurring' && (
              <React.Fragment>
                <Row gutter={24}>
                  <Col span={12}>
                    <Item
                      name="startDate"
                      label="Start Date"
                    >
                      <DatePicker
                        picker="month"
                      />
                    </Item>
                  </Col>
                  <Col span={12}>
                    <Item
                      name="totalMonths"
                      label="Total Months"
                    >
                      <InputNumber />
                    </Item>
                  </Col>
                </Row>
              </React.Fragment>
            )
          }

          <Item
            name="userId"
            label="user"
          >
            <Select
              placeholder="Please select"
              onSearch={(value: string) => setLookup(value)}
              filterOption={false}
              showSearch
            >
              {
                users?.users?.items.map((user) => (
                  <Select.Option
                    key={user.id}
                    value={user.id}
                  >
                    {user.firstName}
                    {' '}
                    {user.lastName}
                  </Select.Option>
                ))
              }
            </Select>
          </Item>

        </Form>
      </div>
    </Modal>
  );
};
