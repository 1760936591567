import gql from 'graphql-tag';

export const CREATE_KPI_EARLY_WARNING = gql`
  mutation createKpiEarlyWarning($input: CreareKpiEarlyWarningInput!) {
    createKpiEarlyWarning(input: $input)
  }
`;

export const DELETE_KPI_EARLY_WARNING = gql`
  mutation deleteKpiEarlyWarning($id: String!) {
    deleteKpiEarlyWarning(id: $id)
  }
`;

export const DELETE_JOB = gql`
  mutation DeleteJob($id: String!) {
    deleteJob(id: $id)
  }
`;

export const CREATE_KPI_EARLY_WARNING_CATEGORY = gql`
  mutation createKpiEarlyWarningCategory($name: String!) {
    createKpiEarlyWarningCategory(name: $name)
  }
`;

export const DELETE_KPI_EARLY_WARNING_CATEGORY = gql`
  mutation deleteKpiEarlyWarningCategory($id: String!) {
    deleteKpiEarlyWarningCategory(id: $id)
  }
`;
