import { createUseStyles } from 'react-jss';
import { base } from '../../styles';

export const generateSalesDocumentStyles = createUseStyles({
  container: {
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelButton: {
    marginRight: base(),
  },
});
