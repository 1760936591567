import { DownCircleFilled } from '@ant-design/icons';
import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { Button, Col, Form, Input, Row, Select, Upload } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';
import CSVToJSON from 'csvtojson';
import { Container } from '../../components';
import { mapPinsStyles } from './styles';
import { ResourcesLiveData } from '../../lib/graphql/query/misc/__generated__/ResourcesLiveData';
import { RESOURCES_LIVE_DATA } from '../../lib/graphql/query/misc';
import { displayErrorMessage, displaySuccessNotification } from '../../lib/utils';
import { ConvertPostcodesToLatLong, ConvertPostcodesToLatLongVariables } from '../../lib/graphql/mutation/misc/__generated__/ConvertPostcodesToLatLong';
import { CONVERT_POSTCODES_TO_LAT_LNG } from '../../lib/graphql/mutation/misc';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Pin = ({ name, lat, lng, color }: {name: string | undefined, lat: number, lng: number, color?: string}) => <DownCircleFilled style={{ opacity: 0.8, fontSize: 15, color: color || '#9ac325', border: '2px solid white', borderRadius: '15px', background: 'white', transform: 'translate(-15px, -30px)' }} />;

const { Item } = Form;
export const MapPins = () => {
  const styles = mapPinsStyles();

  const [fileLoading, setFileLoading] = useState(false);
  const [fileString, setFileString] = useState<string | null>(null);

  const [color, setColor] = useState<string | undefined>(undefined);

  const [getResourcesLiveData, { data }] = useLazyQuery<ResourcesLiveData>(RESOURCES_LIVE_DATA);

  const [getLatLngFromPostcodes, { data: postcodesData }] = useMutation<ConvertPostcodesToLatLong, ConvertPostcodesToLatLongVariables>(CONVERT_POSTCODES_TO_LAT_LNG, {
    onCompleted: (res) => {
      if (res.convertPostcodesToLatLng) {
        displaySuccessNotification('Postcodes succesfully mapped');
      }
    },
    onError: () => {
      displayErrorMessage('Error mapping postcodes');
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  const updateMap = async (val: any) => {
    if (val.includeTechs) {
      getResourcesLiveData();
    }

    if (val.color) {
      setColor(val.color);
    }

    if (fileString) {
      const csv = await CSVToJSON().fromString(fileString || '');
      getLatLngFromPostcodes({
        variables: {
          postcodes: csv,
        },
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFileUpload = (info: any) => {
    const { file } = info;

    if (file.status === 'uploading') {
      setFileLoading(true);
      return;
    }

    if (file.status === 'done' && file.originFileObj) {
      getFileString(file.originFileObj, (item) => {
        setFileString(item);
        setFileLoading(false);
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dummyRequest = (option: any) => {
    setTimeout(() => {
      option.onSuccess('ok');
    }, 0);
  };

  return (
    <Container className={styles.container}>
      <Row gutter={24}>
        <Col span={6}>
          <div className={styles.settingsCard}>
            <h2>Settings</h2>
            <Form
              layout="vertical"
              onFinish={updateMap}
            >
              <Item
                name="includeTechs"
                label="includeTechs"
              >
                <Select
                  defaultValue={false}
                >
                  <Select.Option value={false}>No</Select.Option>
                  <Select.Option value>Yes</Select.Option>
                </Select>
              </Item>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>Upload CSV File</label>
              <br />
              <Upload
                name="file"
                showUploadList={false}
                customRequest={dummyRequest}
                beforeUpload={beforeFileUpload}
                onChange={handleFileUpload}
              >
                {fileString ? (
                  <p>File uploaded!</p>
                ) : (
                  <React.Fragment>
                    {fileLoading && <p>File uploading</p>}
                    <Button type="primary">Upload CSV File</Button>
                  </React.Fragment>
                )}
              </Upload>
              <Item
                name="color"
                label="Pin Colour"
              >
                <Input />
              </Item>
              <Item>
                <div className={styles.buttonContainer}>
                  <Button
                    type="primary"
                    htmlType="submit"
                  >
                    Update
                  </Button>
                </div>
              </Item>
            </Form>
          </div>
        </Col>
        <Col span={18}>
          <GoogleMapReact
        // bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string }}
            bootstrapURLKeys={{
              key: 'AIzaSyCNh9dlGtG1kzLnEM7gGiFuzb-kaREsPaQ',
            }}
            defaultCenter={{
              lat: 54.30,
              lng: -2.60,
            }}
            defaultZoom={6.3}
            options={{
              zoomControl: false,
            }}
          >
            {
              postcodesData && postcodesData?.convertPostcodesToLatLng?.map((pin) => (
                <Pin
                  key={pin.postcode}
                  name={pin.name || ''}
                  lat={pin.lat}
                  lng={pin.lng}
                  color={color}
                />
              ))
            }
            {
              data && data?.resourcesLiveData?.map((live) => (
                <Pin
                  key={live.id}
                  name={live.resourceReference}
                  lat={live.positionLatitude}
                  lng={live.positionLongitude}
                />
              ))
            }
          </GoogleMapReact>
        </Col>
      </Row>
    </Container>
  );
};

const beforeFileUpload = (file: File) => {
  const fileIsValidFile = file.type === 'text/csv';
  const fileIsValidSize = file.size / 1024 / 1024 < 1;

  if (!fileIsValidFile) {
    displayErrorMessage('You\'re only able to upload valid CSV files!');
    return false;
  }

  if (!fileIsValidSize) {
    displayErrorMessage(
      'You\'re only able to upload valid files of under 1MB in size!',
    );
    return false;
  }

  return fileIsValidFile && fileIsValidSize;
};

const getFileString = (
  img: File | Blob,
  // eslint-disable-next-line no-unused-vars
  callback: (imageBase64Value: string) => void,
) => {
  const reader = new FileReader();
  reader.readAsText(img);
  reader.onload = () => {
    callback(reader.result as string);
  };
};
