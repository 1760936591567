/* eslint-disable camelcase */
import { useMutation } from '@apollo/client';
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect } from 'react';
import { Comments } from '../../components';
import { ADD_QA_FEEDBACK_COMMENT, CREATE_QA_FEEDBACK, UPDATE_QA_FEEDBACK } from '../../lib/graphql/mutation/qaFeedbacks';
import { AddQaFeedbackComment, AddQaFeedbackCommentVariables } from '../../lib/graphql/mutation/qaFeedbacks/__generated__/AddQaFeedbackComment';
import { CreateQaFeedback, CreateQaFeedbackVariables } from '../../lib/graphql/mutation/qaFeedbacks/__generated__/CreateQaFeedback';
import { UpdateQaFeedback, UpdateQaFeedbackVariables } from '../../lib/graphql/mutation/qaFeedbacks/__generated__/UpdateQaFeedback';
import { QaFeedbacks_qaFeedbacks_items } from '../../lib/graphql/query/qaFeedbacks/__generated__/QaFeedbacks';
import { Viewer } from '../../lib/types';
import { displayErrorMessage, displaySuccessNotification, formatDateAndTime } from '../../lib/utils';
import { modalWidth } from '../../styles/sizes';
import { createUpdateQaFeedbackStyles } from './styles';

interface Props {
  active: boolean;
  feedbackId: string;
  feedback: QaFeedbacks_qaFeedbacks_items | null
  onClose: () => void;
  viewer: Viewer
  refetch: () => void;
}

const { Item } = Form;

export const CreateUpdateQaFeedback: React.FC<Props> = ({
  active,
  feedbackId,
  feedback,
  onClose,
  viewer,
  refetch,
}) => {
  const styles = createUpdateQaFeedbackStyles();
  const [isNew, setIsNew] = React.useState<boolean>(false);

  const [createFeedback] = useMutation<CreateQaFeedback, CreateQaFeedbackVariables>(CREATE_QA_FEEDBACK, {
    onCompleted: (item) => {
      if (item.createQaFeedback.id) {
        displaySuccessNotification('Feedback created successfully');
      }
    },
    onError: (error) => {
      if (error) {
        displayErrorMessage('Error creating feedback');
      }
    },
  });

  const [updateFeedback] = useMutation<UpdateQaFeedback, UpdateQaFeedbackVariables>(UPDATE_QA_FEEDBACK, {
    onCompleted: (item) => {
      if (item.updateQaFeedback.id) {
        displaySuccessNotification('Feedback updated successfully');
        refetch();
        onClose();
      }
    },
    onError: (error) => {
      if (error) {
        displayErrorMessage('Error updating feedback');
      }
    },
  });

  const [createFeedbackComment] = useMutation<AddQaFeedbackComment, AddQaFeedbackCommentVariables>(ADD_QA_FEEDBACK_COMMENT, {
    onCompleted: (item) => {
      if (item.addQaFeedbackComment.id) {
        displaySuccessNotification('Feedback comment created successfully');
        onClose();
      }
    },
    onError: (error) => {
      if (error) {
        displayErrorMessage('Error creating feedback comment');
      }
    },
  });

  const [form] = useForm();

  useEffect(() => {
    if (feedbackId.includes('NEW')) {
      setIsNew(true);
    }
    form.setFieldValue('rating', `${feedback?.rating}`);
  }, [feedbackId, form, feedback]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (values: any) => {
    if (isNew) {
      createFeedback({
        variables: {
          input: {
            ...values,
            rating: parseInt(values.rating, 10),
            jobId: feedback?.job.id,
          },
        },
      });
    } else {
      updateFeedback({
        variables: {
          input: {
            rating: parseInt(values.rating, 10),
          },
          id: feedbackId,
        },
      });
    }
  };

  if (!feedback) {
    return null;
  }

  const onComposeMessage = (message: string) => {
    createFeedbackComment({
      variables: {
        id: feedback.id as string,
        message,
      },
    });
    refetch();
    onClose();
  };

  return (
    <Modal
      title={`${isNew ? 'Create' : 'Edit'} Feedback`}
      okText={`${isNew ? 'Create' : 'Edit'} Feedback`}
      open={active}
      onCancel={onClose}
      onOk={() => form.submit()}
      width={modalWidth}
    >
      <div className={styles.container}>
        <Row gutter={24}>
          <Col span={12}>
            <p>
              <b>Job Reference</b>
              :
              {' '}
              {feedback.job.ref}
            </p>
            <p>
              <b>Date</b>
              :
              {' '}
              {formatDateAndTime(feedback.job.realEnd)}
            </p>
            <p>
              <b>Type</b>
              :
              {' '}
              {feedback.job.type}
            </p>
          </Col>
          <Col span={12}>
            <p>
              <b>Technician</b>
              :
              {' '}
              {feedback.user.firstName}
              {' '}
              {feedback.user.lastName}
            </p>
          </Col>
        </Row>
        <div className={styles.buttonContainer}>
          <a
            href={`https://client.bigchange.com/jobschedule.aspx?jobid=${feedback.job.jobId}`}
            target="_blank"
            rel="noreferrer"
          >
            <Button type="primary">View Job</Button>
          </a>
        </div>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          initialValues={{
            rating: `${feedback.rating}`,
          }}
        >
          <Item
            name="rating"
            label="Star Rating"
            required
          >
            <Select>
              <Select.Option value={1}>1</Select.Option>
              <Select.Option value={2}>2</Select.Option>
              <Select.Option value={3}>3</Select.Option>
              <Select.Option value={4}>4</Select.Option>
              <Select.Option value={5}>5</Select.Option>
            </Select>
          </Item>
          {
            isNew && (
              <Item
                name="comment"
                label="Comment"
              >
                <Input.TextArea
                  rows={5}
                />
              </Item>
            )
          }
        </Form>
        <Comments
          comments={feedback.comments}
          viewer={viewer}
          onComposeMessage={onComposeMessage}
        />
      </div>
    </Modal>
  );
};
