import dayjs from 'dayjs';

export * from './notifications';
export * from './authorized';
export * from './regexValidators';

export const sentenceCase = (str: string) => {
  const string = str.toLowerCase().replace(/_/g, ' ');
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const capitalCase = (str: string) => {
  const string = str.toLowerCase().replace(/_/g, ' ');
  return string.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

export const formatLargeNumbers = (num: number): string => num.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const truncate = (str: string, n = 180) => (str.length > n ? `${str.substr(0, n - 1)}...` : str);


export const formatDate = (date: string | Date) => {
  if (dayjs(date).isValid()) {
    return dayjs(date).format('YYYY-MM-DD');
  }
  return null;
};


export const formatDateAndTime = (date: string | Date) => {
  if (dayjs(date).isValid()) {
    return dayjs(date).format('YYYY-MM-DD HH:mm');
  }
  return null;
};
