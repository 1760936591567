import gql from 'graphql-tag';

export const KPI_EARLY_WARNINGS = gql`
  query kpiEarlyWarnings($categoryId: String) {
    kpiEarlyWarnings(categoryId: $categoryId) {
      id
      name
      description
      categoryName
      pass
      amber
      fail
      red
    }
  }
`;

export const EARLY_WARNING = gql`
  query EarlyWarning($id: String!, $page: Int!, $total: Int!) {
    earlyWarning(id: $id, page: $page, total: $total) {
      total
      name
      description
      items {
        id
        created
        scheduled
        plannedStart
        technician
        client
        reference
        type
        timeLeft
        warningStatus
        notes
        status
      }
    }
  }
`;

export const EARLY_WARNINGS = gql`
  query EarlyWarnings($ids: [String!]!, $page: Int!, $total: Int!) {
    earlyWarnings(ids: $ids, page: $page, total: $total) {
      total
      name
      description
      items {
        id
        created
        scheduled
        plannedStart
        technician
        client
        reference
        type
        timeLeft
        warningStatus
        notes
        status
      }
    }
  }
`;

export const KPI_EARLY_WARNING_CATEGORIES = gql`
  query KpiEarlyWarningCategories {
    kpiEarlyWarningCategories {
      id
      name
    }
  }
`;
