import { useQuery } from '@apollo/client';
import { Col, Form, Input, InputNumber, Modal, Row, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React from 'react';
import { GET_JOB_TYPES } from '../../../lib/graphql/query/misc';
import { GetJobTypes } from '../../../lib/graphql/query/misc/__generated__/GetJobTypes';
import { modalWidth } from '../../../styles/sizes';
import { addMetricStyles } from './styles';

interface Props {
  active: boolean;
  onClose: (values: any) => void;
}

const { Item } = Form;

export const AddMetric: React.FC<Props> = ({
  active,
  onClose,
}) => {
  const styles = addMetricStyles();
  const { data: getJobTypesData } = useQuery<GetJobTypes>(GET_JOB_TYPES);

  const [form] = useForm();

  const onFinish = (values: any) => {
    onClose(values);
  };

  return (
    <Modal
      title="Add Early Warning"
      open={Boolean(active)}
      onCancel={onClose}
      onOk={() => form.submit()}
      width={modalWidth / 1.2}
    >
      <div className={styles.container}>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Item
            name="jobType"
            label="Job Type"
          >
            <Select
              showSearch
              filterOption={(input, option: any) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {getJobTypesData?.getJobTypes.map((jobType) => (
                <Select.Option
                  key={jobType.id}
                  value={`${jobType.id}`}
                >
                  {jobType.label}
                </Select.Option>
              ))}
            </Select>
          </Item>
          <Item
            name="metricType"
            label="Metric Type"
          >
            <Select>
              <Select.Option value="toScheduled">To Scheduled</Select.Option>
              <Select.Option value="fromScheduled">From Scheduled</Select.Option>
              <Select.Option value="fromCreated">From Created</Select.Option>
            </Select>
          </Item>
          <Row>
            <Col span={8}>
              <Item
                name="amberHours"
                label="Amber Hours"
              >
                <InputNumber />
              </Item>
            </Col>
            <Col span={8}>
              <Item
                name="redHours"
                label="Red Hours"
              >
                <InputNumber />
              </Item>
            </Col>
            <Col span={8}>
              <Item
                name="failHours"
                label="Fail Hours"
              >
                <InputNumber />
              </Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};
