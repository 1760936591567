import { Col, Row } from 'antd';
import React from 'react';
import { PairStatCard, StatCard } from '../../../../components';
import { formatLargeNumbers } from '../../../../lib/utils';
import { overviewStyles } from './styles';
import { Viewer } from '../../../../lib/types';
import { Role } from '../../../../lib/graphql/globalTypes';

interface Props {
  totalJobs: number;
  completedJobs: number;
  jobTypes: string
  bookedRevenue: number;
  completedRevenue: number;
  targetRevenue: number;
  totalTeamMembers: number;
  stockRequests: number;
  viewerRole: Viewer['role']
}

export const Overview: React.FC<Props> = ({
  totalJobs,
  completedJobs,
  jobTypes,
  bookedRevenue,
  completedRevenue,
  targetRevenue,
  totalTeamMembers,
  stockRequests,
  viewerRole,
}) => {
  const styles = overviewStyles();

  const types = jobTypes && JSON.parse(jobTypes);

  const freeCallOutsTotal = Object.keys(types)
    .filter((key) => {
      if (key.toLowerCase().includes('call out') && !key.toLowerCase().includes('charge')) {
        return key;
      }
      return false;
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-return-assign, no-param-reassign
    .reduce((obj: any, key: any) => obj += types[key], 0);

  const callOutsTotal = Object.keys(types)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .filter((key) => {
      if (key.toLowerCase().includes('call out')) {
        return key;
      }
      return null;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-return-assign, no-param-reassign
    }).reduce((obj: any, key: any) => obj += types[key], 0);

  const callOutsPercentage = (((freeCallOutsTotal || 0) / (totalJobs || 0)) * 100).toFixed();

  const auditTotal = Object.keys(types)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .filter((key) => key.toLowerCase().includes('quality assurance')).reduce((obj: any, key: any) => {
      // eslint-disable-next-line no-param-reassign
      obj += types[key];
      return obj;
    }, 0);

  const setupsTotal = Object.keys(types)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .filter((key) => key.toLowerCase().includes('set up')).reduce((obj: any, key: any) => {
      // eslint-disable-next-line no-param-reassign
      obj += types[key];
      return obj;
    }, 0);

  const complaintsTotal = Object.keys(types)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .filter((key) => key.toLowerCase().includes('complaint')).reduce((obj: any, key: any) => {
      // eslint-disable-next-line no-param-reassign
      obj += types[key];
      return obj;
    }, 0);

  const percentageTargetRevenue = ((completedRevenue / targetRevenue) * 100).toFixed();


  return (
    <div className={styles.container}>
      <Row
        gutter={[10, 10]}
        style={{ marginBottom: 10 }}
      >
        <Col
          span={12}
          lg={4}
          md={8}
          sm={12}
        >
          <StatCard
            title="Total Jobs"
            value={`${completedJobs}/${totalJobs}`}
          />
        </Col>
        <Col
          span={12}
          lg={4}
          md={8}
          sm={12}
        >
          <StatCard
            title="Techs"
            value={`${totalTeamMembers}`}
          />
        </Col>
        {
          viewerRole !== Role.USER && (
            <Col
              span={24}
              lg={8}
              md={16}
              sm={24}
            >
              <PairStatCard
                pairTitle={`Target: £${formatLargeNumbers(targetRevenue)}`}
                title="Revenue Booked"
                value={`£${formatLargeNumbers(bookedRevenue)}`}
                subOne={bookedRevenue < targetRevenue ? 'Low Booked Revenue!' : 'Good Booked Revenue!'}
                title2="Live Revenue"
                value2={`£${formatLargeNumbers(completedRevenue)}`}
                subTwo2={`${percentageTargetRevenue}% of target`}
                color={bookedRevenue >= targetRevenue ? 'primary' : 'red'}
              />
            </Col>
          )
        }
        {/* <Col
          span={24}
          lg={8}
          md={16}
          sm={24}
        >
          <ObjectivesCard />
        </Col> */}
      </Row>
      <Row gutter={[10, 10]}>
        <Col
          span={12}
          lg={4}
          md={8}
          sm={12}
        >
          <StatCard
            title="Call Outs"
            value={callOutsTotal}
          />
        </Col>
        <Col
          span={12}
          lg={4}
          md={8}
          sm={12}
        >
          <StatCard
            title="Free Call Out Percentage"
            value={typeof callOutsPercentage === 'number' ? `${callOutsPercentage}%` : '0%'}
            color={parseInt(callOutsPercentage, 10) < 20 ? 'primary' : 'red'}
          />
        </Col>
        <Col
          span={12}
          lg={4}
          md={8}
          sm={12}
        >
          <StatCard
            title="Audits Completed"
            value={auditTotal}
            color={auditTotal > 0 ? 'primary' : 'red'}
          />
        </Col>
        <Col
          span={12}
          lg={4}
          md={8}
          sm={12}
        >
          <StatCard
            title="Stock Requests"
            value={`${stockRequests}`}
          />
        </Col>
        <Col
          span={12}
          lg={4}
          md={8}
          sm={12}
        >
          <StatCard
            title="Site Setups"
            value={setupsTotal}
          />
        </Col>
        <Col
          span={12}
          lg={4}
          md={8}
          sm={12}
        >
          <StatCard
            title="Complaints"
            value={complaintsTotal || '0'}
            color={complaintsTotal > 0 ? 'red' : 'primary'}
          />
        </Col>
      </Row>
    </div>
  );
};
