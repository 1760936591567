import { createUseStyles } from 'react-jss';
import { base } from '../../styles';

export const createUpdateQaFeedbackStyles = createUseStyles({
  container: {

  },
  buttonContainer: {
    margin: `${base()} 0`,
  },
});
