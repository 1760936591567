import { createUseStyles } from 'react-jss';
import { base } from '../../../styles';

export const loadingScreenStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  logo: {
    marginBottom: base(),
    animation: '$fade 3s infinite',
  },
  '@keyframes fade': {
    '0%': {
      opacity: 0.4,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0.4,
    },
  },
});
