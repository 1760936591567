import gql from 'graphql-tag';

export const NOTIFICATIONS = gql`
  query notifications($total: Int!, $page: Int!, $unread: Boolean) {
    notifications(total: $total, page: $page, unread: $unread) {
      total
      items {
        id
        userId
        title
        message
        read
        link
        createdAt
        updatedAt
      }
    }
  }
`;

export const HAS_NOTIFICATIONS = gql`
  query hasNotifications {
    hasNotifications
  }
`;
