import { useMutation, useQuery } from '@apollo/client';
import { Button, Popconfirm, Space, Table, Typography } from 'antd';
import React, { useState } from 'react';
import { Container } from '../../components';
import { GPC_EQUIPMENT } from '../../lib/graphql/query/gpcPricing';
import { Viewer } from '../../lib/types';
import { gpcEquipmentStyles } from './styles';
import { authorized, displayErrorMessage, displaySuccessNotification, sentenceCase } from '../../lib/utils';
import { Role } from '../../lib/graphql/globalTypes';
import { DELETE_EQUIPMENT } from '../../lib/graphql/mutation/gpcPricing';
import { GpcEquipment as GpcEquipmentType, GpcEquipmentVariables } from '../../lib/graphql/query/gpcPricing/__generated__/GpcEquipment';
import { deleteEquipment, deleteEquipmentVariables } from '../../lib/graphql/mutation/gpcPricing/__generated__/deleteEquipment';
import { AddGpcEquipment } from '../../modals';

interface Props {
  viewer: Viewer
}

const { Text } = Typography;

export const GpcEquipment: React.FC<Props> = ({ viewer }) => {
  const styles = gpcEquipmentStyles();
  const [addGpcEquipmentModalVisible, setAddGpcEquipmentModalVisible] = useState(false);
  const { data, refetch } = useQuery<GpcEquipmentType, GpcEquipmentVariables>(GPC_EQUIPMENT, {
    variables: {
      lookup: '',
    },
  });
  const [deleteGpcEquipment] = useMutation<deleteEquipment, deleteEquipmentVariables>(DELETE_EQUIPMENT, {
    onCompleted: (d) => {
      if (d.deleteEquipment) {
        displaySuccessNotification('GPC Equipment deleted successfully!');
        refetch();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Error deleting GPC Equipment');
      }
    },
  });

  const onModalClose = () => {
    setAddGpcEquipmentModalVisible(false);
    refetch();
  };

  const columns = [
    {
      name: 'name',
      title: 'Name',
      dataIndex: 'name',
    },
    {
      name: 'type',
      title: 'Types',
      dataIndex: 'type',
      render: (types: string[]) => (
        sentenceCase(types.join(', '))
      ),
    },
    {
      name: 'code',
      title: 'Code',
      dataIndex: 'code',
    },
    {
      name: 'costPrice',
      title: 'Cost Price',
      dataIndex: 'costPrice',
      render: (costPrice: number) => (
        `£${costPrice}`
      ),
    },
    {
      name: 'sellingPrice',
      title: 'Selling Price',
      dataIndex: 'sellingPrice',
      render: (sellingPrice: number) => (
        `£${sellingPrice}`
      ),
    },
    {
      name: 'notes',
      title: 'Notes',
      dataIndex: 'notes',
    },
    authorized({
      viewer,
      role: Role.ADMIN,
    })
      ? {
        title: 'Actions',
        key: 'actions',
        width: 10,
        render: (gpcRate: { id: string, name: string }) => (
          <Space>
            <Popconfirm
              title="Are you sure you want to delete this Gpc Equipment?"
              onConfirm={() => deleteGpcEquipment({ variables: { id: gpcRate.id } })}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link">
                <Text type="danger">
                  Delete
                </Text>
              </Button>
            </Popconfirm>
          </Space>
        ),
      } : {},
  ];

  return (
    <Container className={styles.container}>
      <p className={styles.overTitle}>Pricing Documents</p>
      <div className={styles.titleContainer}>
        <h1>GPC Equipment</h1>
        <Button
          type="primary"
          onClick={() => setAddGpcEquipmentModalVisible(true)}
        >
          Add New
        </Button>
      </div>

      <div className={styles.sectionContainer}>
        <Table
          columns={columns}
          dataSource={data?.gpcEquipment?.items}
        />
      </div>
      {
        (addGpcEquipmentModalVisible) ? (
          <AddGpcEquipment
            active={addGpcEquipmentModalVisible}
            onClose={onModalClose}
          />
        ) : null
      }
    </Container>
  );
};
