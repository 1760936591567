import gql from 'graphql-tag';

export const CREATE_ON_CALL = gql`
  mutation CreateOnCall($input: CreateOnCallInput!) {
    createOnCall(input: $input) {
      id
    }
  }
`;

export const UPDATE_ON_CALL = gql`
  mutation UpdateOnCall($id: ID!, $input: CreateOnCallInput!) {
    updateOnCall(id: $id, input: $input) {
      id
    }
  }
`;

export const DELETE_ON_CALL = gql`
  mutation DeleteOnCall($id: ID!) {
    deleteOnCall(id: $id) {
      id
    }
  }
`;

export const NOTIFY_ON_CALL_USERS = gql`
  mutation NotifyOnCallUsers($id: ID!) {
    notifyOnCallUsers(id: $id)
  }
`;
