/* eslint-disable camelcase */
import { useMutation, useQuery } from '@apollo/client';
import { Button, Popconfirm, Space, Table, Typography } from 'antd';
import React, { useState } from 'react';
import { Container } from '../../components';
import { OrderByDirection, Role } from '../../lib/graphql/globalTypes';
import { DELETE_ALERT } from '../../lib/graphql/mutation/alerts';
import { DeleteAlert, DeleteAlertVariables } from '../../lib/graphql/mutation/alerts/__generated__/DeleteAlert';
import { ALERTS } from '../../lib/graphql/query/alerts';
import { Alerts as AlertsData, AlertsVariables } from '../../lib/graphql/query/alerts/__generated__/Alerts';
import { User_user } from '../../lib/graphql/query/users/__generated__/User';
import { Viewer } from '../../lib/types';
import { authorized, displayErrorMessage, displaySuccessNotification, formatDate, sentenceCase } from '../../lib/utils';
import { AddEditAlert } from '../../modals';
import { notificationsStyles } from './styles';

interface Props {
  viewer: Viewer
}

const { Text } = Typography;

export const Alerts: React.FC<Props> = ({ viewer }) => {
  const styles = notificationsStyles();

  const [modalActive, setModalActive] = useState<boolean | string>(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [order, setOrder] = useState({ field: 'date', direction: OrderByDirection.DESC });
  const { data, loading, refetch } = useQuery<AlertsData, AlertsVariables>(ALERTS, {
    variables: {
      page,
      total: 20,
      orderBy: order,
    },
  });

  const [deleteAlert] = useMutation<DeleteAlert, DeleteAlertVariables>(DELETE_ALERT, {
    onCompleted: (team) => {
      if (team.deleteAlert) {
        displaySuccessNotification('Team deleted successfully!');
        refetch();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Error deleting team');
      }
    },
  });

  const onModalClose = () => {
    setModalActive(false);
    refetch();
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: true,
      render: formatDate,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      sorter: true,
    },
    {
      title: 'Author',
      dataIndex: 'author',
      key: 'author',
      render: (author: User_user) => `${author.firstName} ${author.lastName}`,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: sentenceCase,
    },
    authorized({
      viewer,
      role: Role.MANAGER,
    }) ? {
        title: 'Actions',
        key: 'actions',
        render: (alert: {id: string, name: string}) => (
          <Space>
            <Button
              type="link"
              onClick={() => setModalActive(alert.id)}
            >
              Edit
            </Button>
            <Popconfirm
              title="Are you sure you want to delete this alert?"
              onConfirm={() => deleteAlert({ variables: { id: alert.id } })}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link">
                <Text type="danger">
                  Delete
                </Text>
              </Button>
            </Popconfirm>
          </Space>
        ),
      } : {},
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTableChange = (pagination: any, _filters: unknown, sorter: any) => {
    if (pagination?.current) {
      setPage(pagination.current);
    }
    if (pagination?.size) {
      setLimit(pagination.size);
    }
    if (sorter?.columnKey) {
      setOrder({
        field: sorter.columnKey,
        direction: sorter.order === 'ascend' ? OrderByDirection.ASC : OrderByDirection.DESC,
      });
    }
  };

  return (
    <Container className={styles.container}>
      <p className={styles.overTitle}>Alerts</p>
      <div className={styles.titleContainer}>
        <h1>View All Alerts</h1>
        {
            authorized({
              viewer,
              role: Role.MANAGER,
            }) && (
              <Button
                type="primary"
                onClick={() => setModalActive(true)}
              >
                Create Alert
              </Button>
            )
          }

      </div>

      <div className={styles.sectionContainer}>
        <Table
          columns={columns}
          dataSource={data?.alerts?.items}
          scroll={{ x: 'max-content' }}
          loading={loading}
          pagination={{
            defaultPageSize: limit,
            defaultCurrent: page,
            total: data?.alerts?.total,
          }}
          onChange={handleTableChange}
          rowKey="id"
        />
      </div>
      {
        modalActive && (
          <AddEditAlert
            active={modalActive}
            onClose={onModalClose}
            viewer={viewer}
          />
        )
      }
    </Container>
  );
};
