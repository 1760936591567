import { base, htmlFontSize } from '../base';
import { colors } from '../colors';

const defaultType = {
};

const heading = {
  fontWeight: 'semibold',
  colors: colors.black,
  margin: 0,
  padding: 0,
};

const h1 = {
  ...defaultType,
  ...heading,
  fontSize: base(2.1),
  lineHeight: base(2.6),
  colors: colors.black,
};

const h2 = {
  ...defaultType,
  ...heading,
  fontSize: base(1.8),
  lineHeight: base(2),
  marginBottom: base(),
};

const h3 = {
  ...defaultType,
  ...heading,
  fontSize: base(1.2),
  lineHeight: base(1.2),
};

const h4 = {
  ...defaultType,
  ...heading,
  fontSize: base(1),
  lineHeight: base(1.5),
};

const h5 = {
  ...defaultType,
  ...heading,
  fontSize: base(1),
  lineHeight: base(1.5),
};

const body = {
  ...defaultType,
  fontSize: htmlFontSize,
  lineHeight: base(1.3),
  fontWeight: 300,
  color: colors.black,
  margin: 0,
};

const overTitle = {
  margin: 0,
  fontWeight: 600,
};

export {
  h1,
  h2,
  h3,
  h4,
  h5,
  body,
  overTitle,
};
