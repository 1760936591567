import { createUseStyles } from 'react-jss';
import { base, colors } from '../../../styles';

interface Props {
  isFullscreen: boolean;
}

export const screenTwoStyles = createUseStyles({
  container: ({ isFullscreen }: Props) => ({
    backgroundColor: colors.light,
    position: isFullscreen ? 'fixed' : 'relative',
    maxWidth: isFullscreen && 'unset',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',

    display: 'flex',
    flexDirection: 'column',

    padding: base(5),
  }),
  titleContainer: {
    marginBottom: base(2),
    position: 'relative',
  },
  statCardsContainer: {
    marginBottom: base(),

    flex: 1,
    display: 'flex',
    // flexWrap: 'wrap',
  },
  statCard: {
    background: colors.white,
    padding: base(1),
  },
  value: {
    fontWeight: 600,
    fontSize: base(2),
  },
  fullscreenButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  fullscreenIcon: {
    color: colors.black,
    fontSize: base(1.5),
  },
});
