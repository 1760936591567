import gql from 'graphql-tag';

export const ALERTS = gql`
  query Alerts($total: Int!, $page: Int!, $orderBy: OrderBy, $lookup: String) {
    alerts(total: $total, page: $page, orderBy: $orderBy, lookup: $lookup) {
      total
      items {
        id
        title
        body
        authorId
        author {
          id
          firstName
          lastName
        }
        date
        status
        createdAt
        updatedAt
      }
    }
  }
`;

export const ALERT = gql`
  query Alert($id: ID!) {
    alert(id: $id) {
      id
      title
      body
      authorId
      author {
        id
        firstName
        lastName
      }
      date
      status
      createdAt
      updatedAt
    }
  }
`;
