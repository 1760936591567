import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { DatePicker, Form, Input, Modal, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { OrderByDirection, RiskLeadStatus } from '../../lib/graphql/globalTypes';
import { CREATE_RISK_LEAD, UPDATE_RISK_LEAD } from '../../lib/graphql/mutation/riskLeads';
import { CreateRiskLead as CreateRiskLeadData, CreateRiskLeadVariables } from '../../lib/graphql/mutation/riskLeads/__generated__/CreateRiskLead';
import { UpdateRiskLead, UpdateRiskLeadVariables } from '../../lib/graphql/mutation/riskLeads/__generated__/UpdateRiskLead';
import { RISK_LEAD } from '../../lib/graphql/query/riskLeads';
import { RiskLead, RiskLeadVariables } from '../../lib/graphql/query/riskLeads/__generated__/RiskLead';
import { USERS } from '../../lib/graphql/query/users';
import { Users, UsersVariables } from '../../lib/graphql/query/users/__generated__/Users';
import { displayErrorMessage, displaySuccessNotification, sentenceCase } from '../../lib/utils';
import { modalWidth } from '../../styles/sizes';
import { simpleModalStyles } from './styles';

interface Props {
  active: boolean | string;
  onClose: () => void;
}

const { Item } = Form;
const { Option } = Select;

export const AddEditRiskLead: React.FC<Props> = ({
  active,
  onClose,
}) => {
  const styles = simpleModalStyles();

  const [userLookup, setUserLookup] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [users, setUsers] = useState<any | undefined>([]);

  const handleOnClose = () => {
    form.resetFields();
    onClose();
  };

  useQuery<Users, UsersVariables>(USERS, {
    variables: {
      page: 1,
      total: 1000,
      lookup: userLookup,
      orderBy: {
        direction: OrderByDirection.ASC,
        field: 'email',
      },
    },
    onCompleted: (data) => {
      setUsers(data?.users?.items);
    },
  });

  const [createRiskLead] = useMutation<CreateRiskLeadData, CreateRiskLeadVariables>(CREATE_RISK_LEAD, {
    onCompleted: (data) => {
      if (data.createRiskLead) {
        displaySuccessNotification('Risk Lead created successfully');
        handleOnClose();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Error creating Risk Lead');
      }
    },
  });

  const [getExistingRiskLead] = useLazyQuery<RiskLead, RiskLeadVariables>(RISK_LEAD);

  const [updateRiskLead] = useMutation<UpdateRiskLead, UpdateRiskLeadVariables>(UPDATE_RISK_LEAD, {
    onCompleted: (data) => {
      if (data.updateRiskLead) {
        displaySuccessNotification('Risk Lead updated successfully');
        handleOnClose();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Error updating Risk Lead');
      }
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (values: any) => {
    if (active === true) {
      createRiskLead({
        variables: {
          input: {
            ...values,
            requiresSurvey: values.requiresSurvey === 'true',
          },
        },
      });
    } else {
      updateRiskLead({
        variables: {
          input: {
            id: active,
            ...values,
            requiresSurvey: values.requiresSurvey === 'true',
          },
        },
      });
    }
  };

  const [form] = useForm();

  useEffect(() => {
    if (typeof active === 'string') {
      getExistingRiskLead({
        variables: {
          id: active,
        },
      }).then(({ data }) => {
        form.setFieldsValue({
          ...data?.riskLead,
          date: dayjs(data?.riskLead?.date),
          requiresSurvey: data?.riskLead?.requiresSurvey ? 'true' : 'false',
        });
      });
    }
  }, [active, getExistingRiskLead, form]);

  return (
    <Modal
      title={`${typeof active === 'boolean' ? 'Create' : 'Update'} Risk Leads`}
      open={Boolean(active)}
      onCancel={handleOnClose}
      onOk={() => form.submit()}
      width={modalWidth}
    >
      <div className={styles.container}>
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
        >
          <Item
            name="jobReference"
            label="Job Reference"
            required
          >
            <Input />
          </Item>
          <Item
            name="jobType"
            label="Job Type"
            required
          >
            <Input />
          </Item>
          <Item
            name="userId"
            label="User"
            required
          >
            <Select
              onSearch={(value: string) => setUserLookup(value)}
              filterOption={false}
              showSearch
            >
              {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                users.map((user: any) => (
                  <Option
                    key={user.id !== null ? user.id : '123'}
                    value={user.id !== null ? user.id : '123'}
                  >
                    {user.firstName !== null ? user.firstName : '123'}
                    {' '}
                    {user.lastName !== null ? user.lastName : '123'}
                  </Option>
                ))
              }
            </Select>
          </Item>
          <Item
            name="client"
            label="Client Name"
            required
          >
            <Input />
          </Item>
          <Item
            name="status"
            label="Status"
            required
          >
            <Select>
              {
                Object.keys(RiskLeadStatus).map((status) => (
                  <Option
                    key={status}
                    value={status}
                  >
                    {sentenceCase(status)}
                  </Option>
                ))
              }
            </Select>
          </Item>
          <Item
            name="requiresSurvey"
            label="Requires Survey"
          >
            <Select defaultValue="false">
              <Option value="true">Yes</Option>
              <Option value="false">No</Option>
            </Select>
          </Item>
          <Item
            name="date"
            label="Date"
            required
          >
            <DatePicker />
          </Item>
          <Item
            name="notes"
            label="Technician Notes"
          >
            <Input.TextArea />
          </Item>
          <Item
            name="privateNotes"
            label="private Notes"
          >
            <Input.TextArea />
          </Item>
        </Form>
      </div>
    </Modal>
  );
};
