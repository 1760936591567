import gql from 'graphql-tag';

export const CREATE_COMMISSION_PAYMENT = gql`
  mutation CreateCommissionPayment($commissionId: ID!, $input: CreateCommissionPaymentInput!) {
    createCommissionPayment(commissionId: $commissionId, input: $input)
  }
`;

export const DELETE_COMMISSION_PAYMENT = gql`
  mutation DeleteCommissionPayment($id: ID!) {
    deleteCommissionPayment(id: $id)
  }
`;
