export const colors = {
  black: '#383838',
  white: '#FFFFFF',
  light: '#f6f6f6',
  primary: '#9ac325',
  red: '#ff0a0a',
  grey: '#707070',
  border: '#E3E3E3',
  amber: '#ff9900',
};

export type Color = keyof typeof colors;
