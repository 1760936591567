import gql from 'graphql-tag';

export const CREATE_OBJECTIVE = gql`
  mutation createObjective($input: CreateUpdateObjectiveInput!) {
    createObjective(input: $input) {
      id
    }
  }
`;

export const UPDATE_OBJECTIVE = gql`
  mutation updateObjective($id: String!, $input: CreateUpdateObjectiveInput!) {
    updateObjective(id: $id, input: $input) {
      id
    }
  }
`;

export const DELETE_OBJECTIVE = gql`
  mutation DeleteObjective($id: String!) {
    deleteObjective(id: $id) 
  }
`;
