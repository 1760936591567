import { Form, Input, InputNumber, Modal, Select } from 'antd';
import React, { useEffect, useRef } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { modalWidth } from '../../styles/sizes';
import { addEditGpcRateStyles } from './styles';
import { GpcLabourCostUnit } from '../../lib/graphql/globalTypes';
import { displayErrorMessage, displaySuccessNotification, sentenceCase } from '../../lib/utils';
import { createLabourCost, createLabourCostVariables } from '../../lib/graphql/mutation/gpcPricing/__generated__/createLabourCost';
import { CREATE_LABOUR_COST, UPDATE_LABOUR_COST } from '../../lib/graphql/mutation/gpcPricing';
import { GpcLabourCost, GpcLabourCostVariables } from '../../lib/graphql/query/gpcPricing/__generated__/GpcLabourCost';
import { GPC_LABOUR_COST } from '../../lib/graphql/query/gpcPricing';
import { updateLabourCost, updateLabourCostVariables } from '../../lib/graphql/mutation/gpcPricing/__generated__/updateLabourCost';

interface Props {
  active: boolean;
  onClose: () => void;
  selectedRate?: string | null;
}

const { Item } = Form;

export const AddEditGpcRate: React.FC<Props> = ({
  active,
  onClose,
  selectedRate,
}) => {
  const styles = addEditGpcRateStyles();

  const [createGpcRate] = useMutation<createLabourCost, createLabourCostVariables>(CREATE_LABOUR_COST,
    {
      onCompleted(data) {
        if (data.createLabourCost.id) {
          displaySuccessNotification('GPC Rate added successfully');
          onClose();
        }
      },
      onError(error) {
        if (error) {
          displayErrorMessage('Error adding GPC Rate');
        }
      },
    });

  const [updateGpcRate] = useMutation<updateLabourCost, updateLabourCostVariables>(UPDATE_LABOUR_COST,
    {
      onCompleted(data) {
        if (data.updateLabourCost.id) {
          displaySuccessNotification('GPC Rate updated successfully');
          onClose();
        }
      },
      onError(error) {
        if (error) {
          displayErrorMessage('Error updating GPC Rate');
        }
      },
    });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (input: any) => {
    if (selectedRate) {
      updateGpcRate({
        variables: {
          id: selectedRate,
          input,
        },
      });
    } else {
      createGpcRate({
        variables: {
          input,
        },
      });
    }
  };

  const [getLabourRate, { data }] = useLazyQuery<GpcLabourCost, GpcLabourCostVariables>(GPC_LABOUR_COST);

  const getLabourRateRef = useRef(getLabourRate);

  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedRate) {
      getLabourRateRef.current({
        variables: {
          id: selectedRate,
        },
      });
    }
  }, [selectedRate, getLabourRateRef]);

  if (selectedRate && !data?.gpcLabourCost) return null;

  return (
    <Modal
      title="Add GPC Rate"
      open={active}
      onCancel={onClose}
      onOk={() => form.submit()}
      width={modalWidth}
    >
      <div className={styles.container}>
        <Form
          onFinish={onSubmit}
          layout="vertical"
          form={form}
          initialValues={data?.gpcLabourCost}
        >
          <Item
            name="name"
            required
            label="Label"
          >
            <Input />
          </Item>
          <Item
            name="unit"
            required
            initialValue={GpcLabourCostUnit.HOUR}
            label="Unit"
          >
            <Select>
              {
                Object.values(GpcLabourCostUnit).map((option) => (
                  <Select.Option
                    key={option}
                    value={option}
                  >
                    {sentenceCase(option)}
                  </Select.Option>
                ))
              }
            </Select>
          </Item>
          <Item
            name="rate"
            required
            label="Rate"
          >
            <InputNumber prefix="£" />
          </Item>
        </Form>
      </div>
    </Modal>
  );
};
