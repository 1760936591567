import { Button, Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { BellOutlined, MenuOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Container } from '../Container';
import { appHeaderStyles } from './styles';
import { NotificationsModal } from '../../NotificationsModal';
import { hasNotifications } from '../../../lib/graphql/query/notifications/__generated__/hasNotifications';
import { HAS_NOTIFICATIONS } from '../../../lib/graphql/query/notifications';
import { Viewer } from '../../../lib/types';
import { authorized } from '../../../lib/utils';
import { Role } from '../../../lib/graphql/globalTypes';

interface Props {
  viewer: Viewer
}

const { Header } = Layout;

export const AppHeader: React.FC<Props> = ({
  viewer,
}) => {
  const styles = appHeaderStyles();
  const [notificationsModalActive, setNotificationsModalActive] = useState(false);
  const [hasNotification, setHasNotifications] = useState(false);
  const { refetch } = useQuery<hasNotifications>(HAS_NOTIFICATIONS, {
    onCompleted: (hasNotificationsData) => {
      setHasNotifications(hasNotificationsData.hasNotifications);
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 120000);
    return () => clearInterval(interval);
  }, [refetch]);


  return (
    <Header className={styles.container}>
      <Container className={styles.innerContainer}>
        <Link to="my-account">
          <UserOutlined className={styles.icon} />
        </Link>
        <Button
          type="link"
          onClick={() => setNotificationsModalActive(true)}
        >
          <BellOutlined className={` ${styles.icon} ${hasNotification ? styles.hasNotification : ''}`} />
        </Button>
        <MenuOutlined className={`${styles.icon} ${styles.burgerIcon}`} />
        {
          authorized({
            viewer,
            role: Role.SUPER_ADMIN,
          }) && (
            <Link to="/settings">
              <SettingOutlined className={styles.icon} />
            </Link>
          )
        }
      </Container>
      {
        notificationsModalActive && (
          <NotificationsModal
            modalVisible={notificationsModalActive}
            setModalVisible={setNotificationsModalActive}
            clearHasNotifications={() => setHasNotifications(false)}
          />
        )
      }
    </Header>
  );
};
