import { createUseStyles } from 'react-jss';
import { base, Color, colors } from '../../styles';

interface Props {
  backgroundColor: Color;
}

export const statCardStyles = createUseStyles({
  container: ({ backgroundColor }: Props) => ({
    width: '100%',
    border: `1px solid ${['red', 'primary'].includes(backgroundColor) ? 'transparent' : colors.border}`,
    padding: base(0.5),
    height: base(7),

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    backgroundColor: colors[backgroundColor],
    '& *': {
      color: ['red', 'primary'].includes(backgroundColor) ? colors.white : colors.black,
    },
  }),
  subValuesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '& *': {
      fontSize: base(0.8),
    },
  },
  innerTitleContainer: {
    fontWeight: 700,
  },
  icon: {
    marginRight: base(0.5),
  },
});
