import { createUseStyles } from 'react-jss';
import { base, colors, h3 } from '../../styles';

export const kpiEarlyWarningStyles = createUseStyles({
  container: {

  },
  title: {
    marginBottom: base(2),
  },
  objectivesContainer: {
    marginBottom: base(2),

  },
  innerContainer: {
    padding: base(2),
    marginTop: base(2),
    // 3 columns
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridGap: base(),
    rowGap: base(),
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    '& h1': {
      marginRight: base(),
      marginBottom: 0,
    },
    '& button': {
      height: base(2),
    },
  },
  kpiCard: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: colors.primary,
    color: colors.white,

    '& *': {
      color: colors.white,
    },
    padding: `${base()} ${base(2)}`,
    height: '100%',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  red: {
    display: 'block',
    width: base(),
    height: base(),
    backgroundColor: colors.red,
    border: `1px solid ${colors.border}`,
    borderRadius: '50%',
    marginRight: base(0.25),
  },
  amber: {
    display: 'block',
    width: base(),
    height: base(),
    backgroundColor: colors.amber,
    border: `1px solid ${colors.border}`,
    borderRadius: '50%',
    marginRight: base(0.25),
  },
  green: {
    display: 'block',
    width: base(),
    height: base(),
    backgroundColor: colors.primary,
    border: `1px solid ${colors.border}`,
    borderRadius: '50%',
    marginRight: base(0.25),
  },
  black: {
    display: 'block',
    width: base(),
    height: base(),
    backgroundColor: colors.black,
    border: `1px solid ${colors.border}`,
    borderRadius: '50%',
    marginRight: base(0.25),
  },
  description: {
    marginBottom: base(),
  },
  redCard: {
    backgroundColor: colors.red,
  },
  amberCard: {
    backgroundColor: colors.amber,
  },
  blackCard: {
    backgroundColor: colors.black,
  },
  cardTitles: {
    ...h3,
  },
});
