import { useMutation } from '@apollo/client';
import { Button, DatePicker, Form, Modal, Select, Steps } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { CommissionStatus } from '../../lib/graphql/globalTypes';
import { EXPORT_COMMISSIONS } from '../../lib/graphql/mutation/exportData';
import { exportCommissions, exportCommissionsVariables } from '../../lib/graphql/mutation/exportData/__generated__/exportCommissions';
import { capitalCase, displayErrorMessage, displaySuccessNotification } from '../../lib/utils';
import { modalWidth } from '../../styles/sizes';
import { exportCommissionsStyles } from './styles';

interface Props {
  visible: boolean;
  onCancel: () => void;
}

const { Step } = Steps;
const { Item } = Form;

export const ExportCommissions: React.FC<Props> = ({
  // TODO: remove this
  visible = true,
  onCancel,
}) => {
  const styles = exportCommissionsStyles();
  const [currentStep, setCurrentStep] = React.useState(0);

  const [exportCommission, { loading }] = useMutation<exportCommissions, exportCommissionsVariables>(EXPORT_COMMISSIONS, {
    onCompleted: (item) => {
      if (item.exportCommissions) {
        displaySuccessNotification("You've successfully exported the commissions!");
        setCurrentStep(2);
        const fetchDataModified = `data:application/xlsx;base64,${item.exportCommissions}`;
        const a = document.createElement('a');
        a.href = fetchDataModified;
        a.download = `commissions-${dayjs().format('DD-MM-YYYY')}.xlsx`;
        a.click();
      }
    },
    onError: () => {
      displayErrorMessage("Sorry! We weren't able to export the commissions. Please try again later!");
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (values: any) => {
    exportCommission({
      variables: {
        type: values.type,
        startDate: dayjs(values.month).startOf('month'),
        endDate: dayjs(values.month).endOf('month'),
      },
    });
  };

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      width={modalWidth}
      title="Export Commissions"
      footer={null}
    >
      <div className={styles.container}>
        <Steps
          current={currentStep}
        >
          <Step title="Configure" />
          <Step title="Generating" />
          <Step title="Complete" />
        </Steps>

        <Form
          layout="vertical"
          onFinish={onFinish}
        >
          {
            currentStep === 0 && (
              <div className={styles.stepContainer}>
                <Item
                  name="type"
                  label="Type"
                >
                  <Select>
                    {
                      Object.values(CommissionStatus).map((status) => (
                        <Select.Option
                          key={status}
                          value={status}
                        >
                          {capitalCase(status)}
                        </Select.Option>
                      ))
                    }
                  </Select>
                </Item>
                <Item
                  name="month"
                  label="Month"
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    picker="month"
                  />
                </Item>
                <Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ float: 'right' }}
                    loading={loading}
                  >
                    Finish
                  </Button>
                </Item>
              </div>
            )
          }
        </Form>


      </div>
    </Modal>
  );
};
