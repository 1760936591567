import gql from 'graphql-tag';

export const GET_JOB_TYPES = gql`
  query GetJobTypes {
    getJobTypes {
      id
      label
      defaultDuration
    }
  }
`;

export const RESOURCES_LIVE_DATA = gql`
  query ResourcesLiveData {
    resourcesLiveData {
      id
      resourceReference
      assetRegistration
      assetReference
      positionDate
      positionSpeed
      positionLatitude
      positionLongitude
      createdAt
      updatedAt
    }
  }
`;

export const CONTACT_LIST = gql`
  query ContactList($lookup: String!) {
    contactList(lookup: $lookup) {
      id
      label
    }
  }
`;

export const DASHBOARD_SCREEN_ONE_DATA = gql`
  query DashboardScreenOneData {
    dashboardScreenOneData {
      totalJobs
      totalTechs
      totalSiteSetUps
      totalTechAudits
      complaints
      nearMisses
      accidents
      investigations
      sitesSummary {
        veryHighRisk
        highRisk
        mediumRisk
        lowRisk
        sitesAtRisk
        rats
        mice
        avian
        wild
        flyingInsects
        insects
        contactName
      }
      actionsSummary {
        veryHighRisk
        highRisk
        mediumRisk
        lowRisk
        customerActions
        contegoActions
      }
    }
  }
`;

export const DASHBOARD_SCREEN_TWO_DATA = gql`
  query DashboardScreenTwoData {
    dashboardScreenTwoData {
      activeTechs
      jobsCompletedToday
      jobsCompletedMonth
      averageJobsCompletedPerTechToday
      averageJobsCompletedPerTechMonth
      percentageCallouts
      resourceLiveData {
        id
        resourceReference
        assetRegistration
        assetReference
        positionDate
        positionSpeed
        positionLatitude
        positionLongitude
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_JOB_REPORT = gql`
  query GetJobReport($jobRef: String!) {
    getJobReport(jobRef: $jobRef) 
  }
`;
