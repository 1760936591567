import { createUseStyles } from 'react-jss';
import { base, colors } from '../../../../styles';

export const riskLeadsSettingsStyles = createUseStyles({
  container: {
    backgroundColor: colors.white,
    padding: base(),
  },
  saveButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  questionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});
