import { useLazyQuery, useQuery } from '@apollo/client';
import { Button, DatePicker, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Container, LoadingScreen } from '../../components';
import { OVERVIEW } from '../../lib/graphql/query/overview';
import { Overview as OverviewData, OverviewVariables } from '../../lib/graphql/query/overview/__generated__/Overview';
import { Viewer } from '../../lib/types';
import { Overview, OverviewTable } from './components';
import { dashboardStyles } from './styles';
import { Teams, TeamsVariables } from '../../lib/graphql/query/teams/__generated__/Teams';
import { TEAMS } from '../../lib/graphql/query/teams';
import { Role } from '../../lib/graphql/globalTypes';

interface Props {
  viewer: Viewer;
}

const { RangePicker } = DatePicker;

export const Dashboard: React.FC<Props> = ({ viewer }) => {
  const styles = dashboardStyles();
  const [startDate, setStartDate] = useState(dayjs().startOf('day').toDate());
  const [endDate, setEndDate] = useState(dayjs().endOf('day').toDate());
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);

  const [teamId, setTeamId] = useState<string | null>(null);
  // teamId keeps changing to null when the page loads
  // why


  const { data, loading, refetch } = useQuery<OverviewData, OverviewVariables>(OVERVIEW, {
    variables: {
      startDate,
      endDate,
      page,
      limit,
      teamId,
    },
  });

  const [getTeams, { data: teamsData }] = useLazyQuery<Teams, TeamsVariables>(TEAMS);

  const getTeamsRef = React.useRef(getTeams);

  const viewerRoleRef = React.useRef(viewer.role);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRangeDateChange = (dates: any) => {
    if (dates[0]) {
      setStartDate(dayjs(dates[0]).startOf('day').toDate());
    }
    if (dates[1]) {
      setEndDate(dayjs(dates[1]).endOf('day').toDate());
    }
  };

  useEffect(() => {
    if (viewerRoleRef.current?.includes(Role.ADMIN)) {
      getTeamsRef.current({
        variables: {
          page: 1,
          total: limit,
        },
      });
    }
  }, [getTeamsRef, viewerRoleRef, limit]);

  const onSelectTeam = (value: string) => {
    if (value === 'all') {
      setTeamId(null);
    } else {
      setTeamId(value);
      localStorage.setItem('dashboardSelectedTeamId', value || '');
    }
  };

  useEffect(() => {
    const dashboardSelectedTeamId = localStorage.getItem('dashboardSelectedTeamId');
    if (dashboardSelectedTeamId) {
      setTeamId(dashboardSelectedTeamId);
    }
  }, []);

  return (
    <Container className={styles.container}>
      <div className={styles.headerContainer}>
        <span>
          <p className={styles.overTitle}>Welcome Back,</p>
          <h1>
            {viewer.firstName}
            {' '}
            {viewer.lastName}
            {' '}
            (
            {data?.overview.teamName}
            )
          </h1>
        </span>
        <div className={styles.filterContainer}>
          <Select
            onChange={onSelectTeam}
            value={teamId}
          >
            <Select.Option
              value={null}
            >
              Select A Team (All)
            </Select.Option>
            {
              teamsData?.teams?.items.map((team) => (
                <Select.Option
                  key={team.id}
                  value={team.id}
                >
                  {team.name}
                </Select.Option>
              ))
            }
          </Select>
          <RangePicker
            onCalendarChange={onRangeDateChange}
          />
          <Button
            type="default"
            onClick={() => refetch()}
          >
            Refresh
          </Button>
        </div>


      </div>
      {
        !loading ? (
          <React.Fragment>
            <Overview
              totalJobs={data?.overview.totalJobs || 0}
              completedJobs={data?.overview.completedJobs || 0}
              jobTypes={data?.overview.jobTypes || ''}
              bookedRevenue={data?.overview.bookedRevenue || 0}
              completedRevenue={data?.overview.completedRevenue || 0}
              targetRevenue={data?.overview.targetRevenue || 0}
              totalTeamMembers={data?.overview.totalTechs || 0}
              stockRequests={data?.overview.stockRequests || 0}
              viewerRole={viewer.role}
            />
            <OverviewTable
              team={data?.overview.team ? data?.overview.team : []}
              totalTeam={data?.overview.totalTechs || 0}
              page={page}
              setPage={setPage}
              setTotal={setLimit}
              limit={limit}
            />
          </React.Fragment>
        ) : (
          <LoadingScreen />
        )
      }
    </Container>
  );
};
