import React from 'react';
import { infoCardStyles } from './styles';

interface Props {
  icon: React.ReactNode;
  value: string | number;
  label: string;
}

export const InfoCard: React.FC<Props> = ({
  icon,
  value,
  label,
}) => {
  const styles = infoCardStyles();

  return (
    <div className={styles.container}>
      <span className={styles.icon}>
        {icon}
      </span>
      <h2>{value}</h2>
      <p>{label}</p>
    </div>
  );
};
