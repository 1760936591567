import gql from 'graphql-tag';

export const COMMISSIONS = gql`
  query commissions($total: Int!, $page: Int!, $orderBy: OrderBy, $lookup: String, $status: CommissionStatus, $userId: String) {
    commissions(total: $total, page: $page, orderBy: $orderBy, lookup: $lookup, status: $status, userId: $userId) {
      total
      items {
        id
        quoteId
        reference
        riskLeadReference
        client
        users {
          id
          firstName
          lastName
        }
        totalExVat
        notes
        technicianNotes
        status
        lastViewed
        createdAt
        updatedAt
      }
    }
  }
`;

export const COMMISSION = gql`
  query commission($id: ID!) {
    commission(id: $id) {
      id
      quoteId
      reference
      riskLeadReference
      client
      users {
        id
        firstName
        lastName
      }
      totalExVat
      notes
      technicianNotes
      status
      lastViewed
      payments {
        id
        amount
        user {
          id
          firstName
          lastName
        }
        date
      }
      createdAt
      updatedAt
    }
  }
`;
