import { Progress } from 'antd';
import React from 'react';
import { progressStatStyles } from './styles';

interface Props {
  title: string
  value: number | undefined
  color: string
  max?: number
}

export const ProgressStat: React.FC<Props> = ({
  title,
  value,
  color,
  max = 10,
}) => {
  const styles = progressStatStyles();

  return (
    <div className={styles.container}>
      <span>
        {title}
        :
        {' '}
        {value}
      </span>
      <Progress
        percent={(value as number / max) * 100}
        showInfo={false}
        strokeColor={color}
      />
    </div>
  );
};
