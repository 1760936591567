import gql from 'graphql-tag';

export const APP = gql`
  query App($id: ID!) {
    app(id: $id) {
      id
      name
      description
      url
      username
      password
      createdAt
      updatedAt
    }
  }
`;

export const APPS = gql`
  query Apps($total: Int!, $page: Int!, $orderBy: OrderBy, $lookup: String) {
    apps(total: $total, page: $page, orderBy: $orderBy, lookup: $lookup) {
      total
      items {
        id
        name
        description
        url
        username
        password
        createdAt
        updatedAt
      }
    }
  }
`;
