import { useMutation } from '@apollo/client';
import { Button, Form, Input } from 'antd';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container } from '../../components';
import { Logo } from '../../components/graphics';
import { LOG_IN } from '../../lib/graphql/mutation/login';
import { Login as LoginData, LoginVariables } from '../../lib/graphql/mutation/login/__generated__/Login';
import { Viewer } from '../../lib/types';
import { displayErrorMessage, displaySuccessNotification } from '../../lib/utils';
import { loginStyles } from './styles';

interface Props {
  setViewer: (viewer: Viewer) => void;
}

const { Item } = Form;

export const Login: React.FC<Props> = ({ setViewer }) => {
  const styles = loginStyles();
  const navigate = useNavigate();

  const [login, { loading }] = useMutation<LoginData, LoginVariables>(LOG_IN, {
    onCompleted(data) {
      if (data && data.login && data.login.token && data.login.role) {
        // if (['ADMIN', 'SUPER_ADMIN', 'MANAGER'].includes(data.login.role)) {
        //   sessionStorage.setItem('token', data.login.token);
        //   displaySuccessNotification('You have successfully logged in!');
        //   setViewer(data.login);
        //   navigate('/');
        // } else {
        //   displayErrorMessage('You do not have permission to access this page');
        // }
        sessionStorage.setItem('token', data.login.token);
        displaySuccessNotification('You have successfully logged in!');
        setViewer(data.login);
        navigate('/');
      } else {
        displayErrorMessage('Please try again!');
        sessionStorage.removeItem('token');
      }
    },
    onError: (error) => {
      if (error) {
        displayErrorMessage();
      }
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (values: any) => {
    login({
      variables: values,
    });
  };

  return (
    <Container className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.loginContainer}>
          <h1 className={styles.title}>Technician</h1>
          <p>Download the Contego Hero app</p>
          <div className={styles.appStoresContainer}>
            <a
              href="https://play.google.com/store/apps/details?id=com.contego.contegohero"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                width={130}
                alt="Download on the App Store"
              />
            </a>
            <a
              href="https://apps.apple.com/us/app/contego-hero"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/512px-Google_Play_Store_badge_EN.svg.png?20190913154415"
                width={143}
                alt="Download on the Play Store"
              />
            </a>
          </div>
        </div>
        <div className={styles.loginContainer}>
          <Logo
            size={100}
            className={styles.logo}
          />
          <Form
            layout="vertical"
            onFinish={onFinish}
          >
            <Item
              name="email"
              label="Email"
              rules={[{ required: true }, {
                type: 'email',
              }]}
            >
              <Input />
            </Item>
            <Item
              name="password"
              label="Password"
              rules={[{ required: true }]}
            >
              <Input.Password />
            </Item>
            <div className={styles.submitButtonContainer}>
              <Link to="/forgot-password">
                <Button
                  type="default"
                  htmlType="button"
                >
                  Forgot Password
                </Button>
              </Link>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Login
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Container>
  );
};
