import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Checkbox, Col, Form, Input, InputNumber, InputRef, Modal, Popconfirm, Row, Select, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useRef, useState } from 'react';
import { CREATE_OBJECTIVE, DELETE_OBJECTIVE, UPDATE_OBJECTIVE } from '../../lib/graphql/mutation/objectives';
import { createObjective as createObjectiveType, createObjectiveVariables } from '../../lib/graphql/mutation/objectives/__generated__/createObjective';
import { DeleteObjective, DeleteObjectiveVariables } from '../../lib/graphql/mutation/objectives/__generated__/DeleteObjective';
import { updateObjective as updateObjectiveType, updateObjectiveVariables } from '../../lib/graphql/mutation/objectives/__generated__/updateObjective';
import { OBJECTIVE } from '../../lib/graphql/query/objectives';
import { Objective, ObjectiveVariables } from '../../lib/graphql/query/objectives/__generated__/Objective';
import { TEAMS } from '../../lib/graphql/query/teams';
import { Teams, TeamsVariables } from '../../lib/graphql/query/teams/__generated__/Teams';
import { USERS } from '../../lib/graphql/query/users';
import { Users, UsersVariables } from '../../lib/graphql/query/users/__generated__/Users';
import { displayErrorMessage, displaySuccessNotification } from '../../lib/utils';
import { modalWidth } from '../../styles/sizes';
import { addEditObjectiveStyles } from './styles';
import { OrderByDirection } from '../../lib/graphql/globalTypes';

// TODO: only fetch users and teams depending on which has been selected

interface Props {
  active: boolean | string;
  onClose: () => void;
}

type SubObjective = {
  id?: string;
  title: string;
  completed: boolean;
}

const { Item } = Form;
const { Option } = Select;
const { Text } = Typography;

export const AddEditObjective: React.FC<Props> = ({
  active,
  onClose,
}) => {
  const styles = addEditObjectiveStyles();
  const [form] = useForm();
  const [userOrTeam, setUserOrTeam] = useState<string | null>(null);
  const [userLookup, setUserLookup] = useState('');
  const [existing, setExisting] = useState<Objective['objective'] & { teamOrUser: string } | boolean | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [users, setUsers] = useState<any | undefined>([]);
  const [subObjectives, setSubObjectives] = useState<SubObjective[]>([]);

  const { data: teams } = useQuery<Teams, TeamsVariables>(TEAMS, {
    variables: {
      page: 1,
      total: 20,
    },
  });

  useQuery<Users, UsersVariables>(USERS, {
    variables: {
      page: 1,
      total: 1000,
      lookup: userLookup,
      orderBy: {
        direction: OrderByDirection.ASC,
        field: 'email',
      },
    },
    onCompleted: (data) => {
      setUsers(data?.users?.items);
    },
  });

  const [getObjective] = useLazyQuery<Objective, ObjectiveVariables>(OBJECTIVE, {
    onCompleted: (data) => {
      if (data.objective) {
        setUserOrTeam(data.objective.userId ? 'USER' : 'TEAM');

        if (data.objective.userId) {
          setUsers([
            ...users,
            {
              id: data.objective.userId,
              firstName: data.objective.user?.firstName || '',
              lastName: data.objective.user?.lastName || '',
            },
          ]);
        }

        setExisting({
          ...data.objective,
          teamOrUser: data.objective.userId ? 'USER' : 'TEAM',
          teamId: data.objective.teamId || ' ',
          userId: data.objective.userId || ' ',
        });

        // foreach subobjective, add to state
        if (data.objective.subObjectives) {
          const subObj = data.objective.subObjectives.map((sub) => ({
            id: sub.id,
            title: sub.title,
            completed: sub.completed,
          }));

          setSubObjectives(subObj);
        }
      }
    },
  });

  const [deleteObjective] = useMutation<DeleteObjective, DeleteObjectiveVariables>(DELETE_OBJECTIVE, {
    onCompleted: () => {
      displaySuccessNotification('Objective deleted!');
      onClose();
    },
    onError: () => {
      displayErrorMessage('Unable to delete objective');
    },
  });

  useEffect(() => {
    setExisting(false);
    if (typeof active === 'string') {
      getObjective({
        variables: {
          id: active,
        },
      });
    } else {
      setExisting(true);
    }
  }, [active, getObjective]);


  const [createObjective] = useMutation<createObjectiveType, createObjectiveVariables>(CREATE_OBJECTIVE, {
    onCompleted: () => {
      displaySuccessNotification('Objective created successfully');
      form.resetFields();
      onClose();
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage(`Unable to create objective: ${err}`);
      }
    },
  });

  const [updateObjective] = useMutation<updateObjectiveType, updateObjectiveVariables>(UPDATE_OBJECTIVE, {
    onCompleted: () => {
      displaySuccessNotification('Objective updated successfully');
      form.resetFields();
      onClose();
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage(`Unable to update objective: ${err}`);
      }
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (input: any) => {
    const values = input;
    delete values.teamOrUser;
    values.isPrivate = Boolean(values.isPrivate);

    if (typeof active === 'string') {
      updateObjective({
        variables: {
          id: active,
          input: {
            ...values,
            subObjectives,
          },
        },
      });
    } else {
      createObjective({
        variables: {
          input: {
            ...values,
            subObjectives,
          },
        },
      });
    }
  };

  const subObjectiveInput = useRef<InputRef | null>(null);
  const addSubObjective = () => {
    if (subObjectiveInput.current !== null) {
      setSubObjectives([...subObjectives, { title: subObjectiveInput.current?.input?.value as string, completed: false }]);
    }
  };

  const removeSubObjective = (index: number) => {
    setSubObjectives(subObjectives.filter((_, i) => i !== index));
  };

  const toggleSubObjectiveStatus = (index: number) => {
    setSubObjectives(subObjectives.map((subObjective, i) => {
      if (i === index) {
        return {
          ...subObjective,
          completed: !subObjective.completed,
        };
      }
      return subObjective;
    }));
  };

  if (!existing) return null;

  return (
    <Modal
      title={typeof active === 'boolean' ? 'Add Objective' : 'Edit Objective'}
      open={Boolean(active)}
      onCancel={onClose}
      onOk={() => form.submit()}
      width={modalWidth}
    >
      <div className={styles.container}>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          initialValues={typeof existing === 'object' ? existing : {}}
        >
          <Item
            name="title"
            label="Title"
            required
          >
            <Input />
          </Item>
          <Item
            name="description"
            label="Description"
            required
          >
            <Input.TextArea autoSize={{ minRows: 5 }} />
          </Item>
          <Item>
            <p>Sub Objectives</p>
            <div className={styles.subObjectivesList}>
              {
                subObjectives.map((subObjective, index) => (
                  <div
                    className={styles.subObjective}
                    key={subObjective.id || index}
                  >
                    <Checkbox
                      checked={subObjective.completed}
                      onChange={() => toggleSubObjectiveStatus(index)}
                    >
                      {
                        subObjective.title
                      }
                    </Checkbox>
                    <Button
                      type="link"
                      onClick={() => removeSubObjective(index)}
                    >
                      <Text
                        type="danger"
                      >
                        Remove
                      </Text>
                    </Button>
                  </div>
                ))
              }
            </div>
            <div className={styles.subObjectivesInput}>
              <Input
                placeholder="title"
                ref={subObjectiveInput}
              />
              <Button onClick={addSubObjective}>Add</Button>
            </div>
          </Item>
          <Row gutter={24}>
            <Col span={12}>
              <Item
                name="status"
                label="Status"
                required
              >
                <Select>
                  <Option value="GREEN">Green</Option>
                  <Option value="AMBER">Amber</Option>
                  <Option value="RED">Red</Option>
                </Select>
              </Item>
            </Col>
            <Col span={12}>
              <Item
                name="teamOrUser"
                label="Select Team or User"
                required
              >
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                <Select onChange={(value: any) => setUserOrTeam(value)}>
                  <Option value="TEAM">Team</Option>
                  <Option value="USER">User</Option>
                </Select>
              </Item>
            </Col>
          </Row>
          {
            userOrTeam && (
              <React.Fragment>
                {
                  (userOrTeam === 'TEAM') ? (
                    <Item
                      name="teamId"
                      label="Team"
                      required={userOrTeam === 'TEAM'}
                    >
                      <Select>
                        {
                          teams?.teams?.items.map((team) => (
                            <Option
                              key={team.id}
                              value={team.id}
                            >
                              {team.name}
                            </Option>
                          ))
                        }
                      </Select>
                    </Item>
                  ) : (
                    <Row gutter={24}>
                      <Col span={12}>
                        <Item
                          name="userId"
                          label="User"
                          required={userOrTeam === 'USER'}
                        >
                          <Select
                            onSearch={(value: string) => setUserLookup(value)}
                            filterOption={false}
                            showSearch
                          >
                            {
                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              users.map((user: any) => (
                                <Option
                                  key={user.id !== null ? user.id : '123'}
                                  value={user.id !== null ? user.id : '123'}
                                >
                                  {user.firstName !== null ? user.firstName : '123'}
                                  {' '}
                                  {user.lastName !== null ? user.lastName : '123'}
                                </Option>
                              ))
                            }
                          </Select>
                        </Item>
                      </Col>
                      <Col span={12}>
                        <Item
                          name="isPrivate"
                          label="Is a private objective"
                        >
                          <Select>
                            <Option value>Yes</Option>
                            <Option>No</Option>
                          </Select>
                        </Item>
                      </Col>
                    </Row>
                  )
                }
              </React.Fragment>
            )
          }
          <Item
            name="priority"
            label="Priority"
          >
            <InputNumber max={2} />
          </Item>
        </Form>
        {
          typeof active === 'string' && (
            <Popconfirm
              title="Are you sure you want to delete this objective?"
              onConfirm={() => {
                deleteObjective({
                  variables: {
                    id: active,
                  },
                });
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link">
                <Text type="danger">Delete</Text>
              </Button>
            </Popconfirm>
          )
        }
      </div>
    </Modal>
  );
};
