import gql from 'graphql-tag';

export const SETTINGS = gql`
  query Settings ($names: [String!]!) {
    settings (names: $names) {
      id
      name
      value
    }
  }
`;
