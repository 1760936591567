import gql from 'graphql-tag';

export const OVERVIEW = gql`
  query Overview ($startDate: Date!, $endDate: Date!, $limit: Int!, $page: Int!, $teamId: String) {
    overview(startDate: $startDate, endDate: $endDate, limit: $limit, page: $page, teamId: $teamId) {
      teamName
      totalJobs,
      completedJobs,
      jobTypes,
      bookedRevenue,
      completedRevenue,
      targetRevenue,
      totalTechs,
      stockRequests
      team {
        id
        name
        bookedJobs
        completedJobs
        absences
        callOuts
        bookedRevenue
        completedRevenue
        totalHours
        stockOrders
      }
    }
  }
`;
