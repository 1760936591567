import { createUseStyles } from 'react-jss';
import { base, colors, queries } from '../../styles';

export const objectivesStyles = createUseStyles({
  container: {

  },
  title: {
    marginBottom: base(2),
  },
  objectivesContainer: {
    marginTop: base(2),
  },
  innerContainer: {
    backgroundColor: colors.white,
    padding: base(2),
  },
  titleContainer: {
    display: 'flex',
    '& h2': {
      marginRight: base(),
    },
    '& button': {
      height: base(2),
    },
  },
  objectivesInnerContainer: {
    flex: 1,
    marginTop: base(),
    marginBottom: base(2),
  },
  cardsContainer: {
    marginTop: base(0.5),
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridGap: base(),
    rowGap: base(),

    '& > div': {
      height: '100%',
    },

    [queries.xl]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [queries.lg]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  subTitleContainer: {
    '& h2': {
      marginTop: base(),
      marginBottom: 0,
    },
  },
});
