import gql from 'graphql-tag';

export const CREATE_APP = gql`
  mutation CreateApp($input: CreateAppInput!) {
    createApp(input: $input) {
      id
    }
  }
`;

export const UPDATE_APP = gql`
  mutation UpdateApp($input: UpdateAppInput!) {
    updateApp(input: $input) {
      id
    }
  }
`;

export const DELETE_APP = gql`
  mutation DeleteApp($id: ID!) {
    deleteApp(id: $id) {
      id
    }
  }
`;
