import gql from 'graphql-tag';

export const MARK_NOTIFICATIONS_AS_READ = gql`
  mutation MarkNotificationsAsRead($notificationIds: [String!]!) {
    markNotificationsAsRead(notificationIds: $notificationIds)
  }
`;

export const CLEAR_ALL_NOTIFICATIONS = gql`
  mutation ClearAllNotifications {
    clearAllNotifications
  }
`;

export const CLEAR_NOTIFICATION = gql`
  mutation ClearNotification($id: String!) {
    clearNotification(id: $id)
  }
`;
