import { useLazyQuery, useMutation } from '@apollo/client';
import { Form, Input, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect } from 'react';
import { CREATE_PRODUCT_CATEGORY, UPDATE_PRODUCT_CATEGORY } from '../../lib/graphql/mutation/products';
import { CreateProductCategory, CreateProductCategoryVariables } from '../../lib/graphql/mutation/products/__generated__/CreateProductCategory';
import { UpdateProductCategory, UpdateProductCategoryVariables } from '../../lib/graphql/mutation/products/__generated__/UpdateProductCategory';
import { PRODUCT_CATEGORY } from '../../lib/graphql/query/products';
import { ProductCategory, ProductCategoryVariables } from '../../lib/graphql/query/products/__generated__/ProductCategory';
import { displayErrorMessage, displaySuccessNotification } from '../../lib/utils';
import { modalWidth } from '../../styles/sizes';
import { addEditProductCategory } from './styles';

interface Props {
  active: boolean | string;
  onClose: () => void;
}

const { Item } = Form;

export const AddEditProductCategory: React.FC<Props> = ({
  active,
  onClose,
}) => {
  const styles = addEditProductCategory();

  const handleOnClose = () => {
    form.resetFields();
    onClose();
  };

  const [createProductCategory] = useMutation<CreateProductCategory, CreateProductCategoryVariables>(CREATE_PRODUCT_CATEGORY, {
    onCompleted: (data) => {
      if (data.createProductCategory) {
        displaySuccessNotification('Product Category created successfully');
        handleOnClose();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Error creating Product Category');
      }
    },
  });

  const [getExistingProductCategory] = useLazyQuery<ProductCategory, ProductCategoryVariables>(PRODUCT_CATEGORY);

  const [updateProductCategory] = useMutation<UpdateProductCategory, UpdateProductCategoryVariables>(UPDATE_PRODUCT_CATEGORY, {
    onCompleted: (data) => {
      if (data.updateProductCategory) {
        displaySuccessNotification('Product Category updated successfully');
        handleOnClose();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Error updating Product Category');
      }
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (values: any) => {
    if (active === true) {
      createProductCategory({
        variables: {
          input: {
            ...values,
          },
        },
      });
    } else {
      updateProductCategory({
        variables: {
          id: active as string,
          input: {
            ...values,
          },
        },
      });
    }
  };

  const [form] = useForm();

  useEffect(() => {
    if (typeof active === 'string') {
      getExistingProductCategory({
        variables: {
          id: active,
        },
      }).then(({ data }) => {
        form.setFieldsValue({
          ...data?.productCategory,
        });
      });
    }
  }, [active, getExistingProductCategory, form]);
  return (
    <Modal
      title={`${typeof active === 'boolean' ? 'Create' : 'Update'} Product Category`}
      open={Boolean(active)}
      onCancel={handleOnClose}
      onOk={() => form.submit()}
      width={modalWidth}
    >
      <div className={styles.container}>
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
        >
          <Item
            name="name"
            label="Name"
            required
          >
            <Input />
          </Item>
        </Form>
      </div>
    </Modal>
  );
};
