import { breakpoints } from '../breakpoints';

const xs = `@media(max-width: ${breakpoints.xs}px)`;

const sm = `@media(max-width: ${breakpoints.sm}px)`;

const md = `@media(max-width: ${breakpoints.md}px)`;

const lg = `@media(max-width: ${breakpoints.lg}px)`;

const xl = `@media(max-width: ${breakpoints.xl}px)`;

const xxl = `@media(max-width: ${breakpoints.xxl}px)`;

export const queries = {
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
};
