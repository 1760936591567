import { createUseStyles } from 'react-jss';
import { base, colors } from '../../styles';

export const forgotPasswordStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

  },
  modalContainer: {
    background: colors.white,

    maxWidth: 500,
    width: '100%',
    border: `1px solid ${colors.border}`,
    padding: `${base(4)} ${base(2)}`,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',

    height: '100%',
    position: 'relative',
  },
  title: {
    marginBottom: base(2),
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: base(),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  goBackContainer: {
    position: 'absolute',
    top: 5,
    left: 0,
  },
});
