import React from 'react';
import { ObjectiveStatus } from '../../lib/graphql/globalTypes';
import { truncate } from '../../lib/utils';
import { objectiveCardStyles } from './styles';

interface Props {
  title: string;
  description: string;
  status: ObjectiveStatus;
  priority?: number
}

export const ObjectiveCard: React.FC<Props> = ({
  title,
  description,
  status,
  priority = 0,
}) => {
  const styles = objectiveCardStyles({
    status,
  });

  return (
    <div className={styles.container}>
      {
        priority > 0 && (
          <span className={styles.priority}>
            <span>
              p
              {priority}
            </span>
          </span>
        )
      }
      <h4>{title}</h4>
      <p>
        {
          truncate(description)
        }
      </p>
      <div className={styles.trafficLightContainer}>
        <div className={`${styles.trafficLight} red`} />
        <div className={`${styles.trafficLight} amber`} />
        <div className={`${styles.trafficLight} green`} />
      </div>
    </div>
  );
};
