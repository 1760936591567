import { Form, Input, InputNumber, Modal, Select } from 'antd';
import React from 'react';
import { useMutation } from '@apollo/client';
import { modalWidth } from '../../styles/sizes';
import { addGpcEquipmentStyles } from './styles';
import { displayErrorMessage, displaySuccessNotification, sentenceCase } from '../../lib/utils';
import { CREATE_EQUIPMENT } from '../../lib/graphql/mutation/gpcPricing';
import { createEquipment, createEquipmentVariables } from '../../lib/graphql/mutation/gpcPricing/__generated__/createEquipment';
import { GpcEquipmentType } from '../../lib/graphql/globalTypes';

interface Props {
  active: boolean;
  onClose: () => void;
}

const { Item } = Form;

export const AddGpcEquipment: React.FC<Props> = ({
  active,
  onClose,
}) => {
  const styles = addGpcEquipmentStyles();

  const [createGpcEquipment] = useMutation<createEquipment, createEquipmentVariables>(CREATE_EQUIPMENT,
    {
      onCompleted(data) {
        if (data.createEquipment.id) {
          displaySuccessNotification('GPC Equipment added successfully');
          onClose();
        }
      },
      onError(error) {
        if (error) {
          displayErrorMessage('Error adding GPC Equipment');
        }
      },
    });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (input: any) => {
    createGpcEquipment({
      variables: {
        input,
      },
    });
  };
  const [form] = Form.useForm();

  return (
    <Modal
      title="Add GPC Equipment"
      open={active}
      onCancel={onClose}
      onOk={() => form.submit()}
      width={modalWidth}
    >
      <div className={styles.container}>
        <Form
          onFinish={onSubmit}
          layout="vertical"
          form={form}
        >
          <Item
            name="name"
            required
            label="Label"
          >
            <Input />
          </Item>
          <Item
            name="code"
            required
            label="Code"
          >
            <Input />
          </Item>
          <Item
            name="type"
            required
            label="Type"
          >
            <Select>
              {
                Object.values(GpcEquipmentType).map((type) => (
                  <Select.Option
                    key={type}
                    value={type}
                  >
                    {sentenceCase(type)}
                  </Select.Option>
                ))
              }
            </Select>
          </Item>
          <Item
            name="costPrice"
            required
            label="Cost Price"
          >
            <InputNumber prefix="£" />
          </Item>
          <Item
            name="sellingPrice"
            required
            label="Selling Price"
          >
            <InputNumber prefix="£" />
          </Item>
          <Item
            name="notes"
            required
            label="Notes"
          >
            <Input.TextArea />
          </Item>
        </Form>
      </div>
    </Modal>
  );
};
