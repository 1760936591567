import gql from 'graphql-tag';

export const ON_CALLS = gql`
  query OnCalls($total: Int!, $page: Int!, $orderBy: OrderBy!) {
    onCalls(total: $total, page: $page, orderBy: $orderBy) {
      total
      items {
        id
        startDate
        endDate
        users {
          id
          firstName
          lastName
          role
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const ON_CALL = gql`
  query OnCall($id: ID!) {
    onCall(id: $id) {
      id
      startDate
      endDate
      users {
        id
        firstName
        lastName
        role
      }
      createdAt
      updatedAt
    }
  }
`;
