import { Tabs } from 'antd';
import React from 'react';
import { Container, PrivateRoute } from '../../components';
import { Role } from '../../lib/graphql/globalTypes';
import { RiskLeadsSettings } from './components';
import { settingsStyles } from './styles';

const tabItems = [
  {
    key: 'riskLeads',
    label: 'Risk Leads',
    children: <RiskLeadsSettings />,
  },
];

export const Settings: React.FC = () => {
  const styles = settingsStyles();

  return (
    <PrivateRoute
      role={Role.SUPER_ADMIN}
    >
      <Container className={styles.container}>
        <h1>Settings</h1>
        <Tabs items={tabItems} />
      </Container>
    </PrivateRoute>

  );
};
