import { Col, Row } from 'antd';
import React from 'react';
import { PairStatCard, StatCard } from '../../../../components';
import { formatLargeNumbers } from '../../../../lib/utils';
import { userOverviewStyles } from './styles';

interface Props {
  totalJobs: number;
  completedJobs: number;
  jobTypes: string
  bookedRevenue: number;
  completedRevenue: number;
  targetRevenue: number;

}

export const UserOverview: React.FC<Props> = ({
  totalJobs,
  completedJobs,
  jobTypes,
  bookedRevenue,
  completedRevenue,
  targetRevenue,
}) => {
  const styles = userOverviewStyles();

  const types = jobTypes && JSON.parse(jobTypes);

  const freeCallOutsTotal = Object.keys(types)
    .filter((key) => {
      if (key.toLowerCase().includes('call out') && !key.toLowerCase().includes('charge')) {
        return key;
      }
      return false;
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-return-assign, no-param-reassign
    .reduce((obj: any, key: any) => obj += types[key], 0);

  const callOutsTotal = Object.keys(types)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .filter((key) => {
      if (key.toLowerCase().includes('call out')) {
        return key;
      }
      return null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-return-assign, no-param-reassign
    }).reduce((obj: any, key: any) => obj += types[key], 0);

  const callOutsPercentage = (((freeCallOutsTotal || 0) / (totalJobs || 0)) * 100).toFixed();

  const percentageTargetRevenue = ((completedRevenue / targetRevenue) * 100).toFixed();


  return (
    <div className={styles.container}>
      <Row
        gutter={[10, 10]}
        style={{ marginBottom: 10 }}
      >
        <Col
          span={12}
          lg={6}
          md={8}
          sm={12}
        >
          <StatCard
            title="Completed Jobs"
            value={`${completedJobs}`}
          />
        </Col>
        <Col
          span={12}
          lg={6}
          md={8}
          sm={12}
        >
          <StatCard
            title="Scheduled Jobs"
            value={`${totalJobs}`}
          />
        </Col>
        <Col
          span={24}
          lg={12}
          md={16}
          sm={24}
        >
          <PairStatCard
            pairTitle={`Target: £${formatLargeNumbers(targetRevenue)}`}
            title="Revenue Booked"
            value={`£${formatLargeNumbers(bookedRevenue)}`}
            subOne={bookedRevenue < targetRevenue ? 'Low Booked Revenue!' : 'Good Booked Revenue!'}
            title2="Live Revenue"
            value2={`£${formatLargeNumbers(completedRevenue)}`}
            subTwo2={`${percentageTargetRevenue}% of target`}
            color={bookedRevenue >= targetRevenue ? 'primary' : 'red'}
          />
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col
          span={12}
          lg={6}
          md={8}
          sm={12}
        >
          <StatCard
            title="Call Outs"
            value={callOutsTotal}
          />
        </Col>
        <Col
          span={12}
          lg={6}
          md={8}
          sm={12}
        >
          <StatCard
            title="Free Call Out Percentage"
            value={`${callOutsPercentage}%`}
            color={parseInt(callOutsPercentage, 10) < 20 ? 'primary' : 'red'}
          />
        </Col>
      </Row>
    </div>
  );
};
