import { useLazyQuery, useMutation } from '@apollo/client';
import { Col, Form, Input, Modal, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect } from 'react';
import { CREATE_APP, UPDATE_APP } from '../../lib/graphql/mutation/apps';
import { CreateApp, CreateAppVariables } from '../../lib/graphql/mutation/apps/__generated__/CreateApp';
import { UpdateApp, UpdateAppVariables } from '../../lib/graphql/mutation/apps/__generated__/UpdateApp';
import { APP } from '../../lib/graphql/query/apps';
import { App, AppVariables } from '../../lib/graphql/query/apps/__generated__/App';
import { displayErrorMessage, displaySuccessNotification, validateUrl } from '../../lib/utils';
import { modalWidth } from '../../styles/sizes';
import { addEditAppStyles } from './styles';

interface Props {
  active: boolean | string;
  onClose: () => void;
}

const { Item } = Form;

export const AddEditApp: React.FC<Props> = ({
  active,
  onClose,
}) => {
  const styles = addEditAppStyles();

  const handleOnClose = () => {
    form.resetFields();
    onClose();
  };

  const [createApp] = useMutation<CreateApp, CreateAppVariables>(CREATE_APP, {
    onCompleted: (data) => {
      if (data.createApp) {
        displaySuccessNotification('App created successfully');
        handleOnClose();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Error creating App');
      }
    },
  });

  const [getExistingApp] = useLazyQuery<App, AppVariables>(APP);

  const [updateApp] = useMutation<UpdateApp, UpdateAppVariables>(UPDATE_APP, {
    onCompleted: (data) => {
      if (data.updateApp) {
        displaySuccessNotification('Risk Lead updated successfully');
        handleOnClose();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Error updating Risk Lead');
      }
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (values: any) => {
    if (!validateUrl(values.url)) {
      displayErrorMessage('Please enter a valid URL');
      return;
    }

    if (active === true) {
      createApp({
        variables: {
          input: {
            ...values,
          },
        },
      });
    } else {
      updateApp({
        variables: {
          input: {
            id: active,
            ...values,
          },
        },
      });
    }
  };

  const [form] = useForm();

  useEffect(() => {
    if (typeof active === 'string') {
      getExistingApp({
        variables: {
          id: active,
        },
      }).then(({ data }) => {
        form.setFieldsValue({
          ...data?.app,
        });
      });
    }
  }, [active, getExistingApp, form]);
  return (
    <Modal
      title={`${typeof active === 'boolean' ? 'Create' : 'Update'} App`}
      open={Boolean(active)}
      onCancel={handleOnClose}
      onOk={() => form.submit()}
      width={modalWidth}
    >
      <div className={styles.container}>
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
        >
          <Item
            name="name"
            label="Name"
            required
          >
            <Input />
          </Item>
          <Item
            name="description"
            label="Description"
          >
            <TextArea rows={5} />
          </Item>
          <Item
            name="url"
            label="Url"
            required
          >
            <Input />
          </Item>
          <Row
            gutter={24}
          >
            <Col span={12}>
              <Item
                name="username"
                label="Username/Email"
                required
              >
                <Input />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                name="password"
                label="Password/Access Code"
                required
              >
                <Input />
              </Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};
