import gql from 'graphql-tag';

export const USER_OVERVIEW = gql`
  query UserOverview($id: ID!, $startDate: Date!, $endDate: Date!, $page: Int!) {
    userOverview(id: $id, startDate: $startDate, endDate: $endDate, page: $page) {
      user {
        id
        firstName
        lastName
        email
        role
        region
        profileImageUrl
        jobTitle
        phoneNumber
        contactEnabled
        revenueTarget
      }
      totalJobs
      completedJobs
      jobTypes
      bookedRevenue
      completedRevenue
      targetRevenue
      jobs {
        id
        jobId
        type
        postcode
        location
        ref
        plannedStart
        plannedEnd
        duration
        status
        realStart
        realEnd
        realDuration
        dueDate
        drivingDur
        description
        drivingDist
        printName
        customerNote
        resNote
        actioned
        created
        scheduled
        revenue {
          id
          price
        }
      }
    }
  }
`;
