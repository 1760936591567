import { useMutation, useQuery } from '@apollo/client';
import { Button, Popconfirm, Space, Table, Typography } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container } from '../../components';
import { Access, OrderByDirection, Role, StockRequestStatus } from '../../lib/graphql/globalTypes';
import { DELETE_STOCK_REQUEST } from '../../lib/graphql/mutation/stockRequests';
import { deleteStockRequest, deleteStockRequestVariables } from '../../lib/graphql/mutation/stockRequests/__generated__/deleteStockRequest';
import { STOCK_REQUESTS } from '../../lib/graphql/query/stockRequests';
import { stockRequests, stockRequestsVariables } from '../../lib/graphql/query/stockRequests/__generated__/stockRequests';
import { USERS } from '../../lib/graphql/query/users';
import { Users, UsersVariables } from '../../lib/graphql/query/users/__generated__/Users';
import { Viewer } from '../../lib/types';
import { authorized, displayErrorMessage, displaySuccessNotification, formatDateAndTime, formatLargeNumbers, sentenceCase } from '../../lib/utils';
import { AddEditStockRequest } from '../../modals';
import { stockRequestsStyles } from './styles';

interface Props {
  viewer: Viewer
}

const { Text } = Typography;

export const StockRequests: React.FC<Props> = ({ viewer }) => {
  const styles = stockRequestsStyles();
  const [addStockRequestModalActive, setAddStockRequestModalActive] = useState<boolean | string>(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [order, setOrder] = useState({ field: 'createdAt', direction: OrderByDirection.DESC });
  const [userId, setUserId] = useState<null | string>(null);
  const [status, setStatus] = useState<null | StockRequestStatus>(null);

  const { data, loading, refetch } = useQuery<stockRequests, stockRequestsVariables>(STOCK_REQUESTS, {
    variables: {
      page,
      total: 20,
      orderBy: order,
      userId,
      status,
    },
  });
  const { data: users } = useQuery<Users, UsersVariables>(USERS, {
    variables: {
      page: 1,
      total: 200,
      orderBy: {
        field: 'email',
        direction: OrderByDirection.ASC,
      },
    },
  });

  const [deleteTeam] = useMutation<deleteStockRequest, deleteStockRequestVariables>(DELETE_STOCK_REQUEST, {
    onCompleted: (stockRequest) => {
      if (stockRequest.deleteStockRequest) {
        displaySuccessNotification('Stock Request deleted successfully!');
        refetch();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Error whilst deleting stock request');
      }
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (pagination?.current) {
      setPage(pagination.current);
    }
    if (pagination?.size) {
      setLimit(pagination.size);
    }
    if (filters) {
      if (filters.user) {
        setUserId(filters.user[0]);
      } else {
        setUserId(null);
      }

      if (filters.status) {
        setStatus(filters.status[0]);
      } else {
        setStatus(null);
      }
    }
    if (sorter?.columnKey) {
      setOrder({
        field: sorter.columnKey,
        direction: sorter.order === 'ascend' ? OrderByDirection.ASC : OrderByDirection.DESC,
      });
    }
  };

  const onClodeModal = () => {
    setAddStockRequestModalActive(false);
    refetch();
  };

  return (
    <Container className={styles.container}>
      <p className={styles.overTitle}>Stock Requests</p>
      <div className={styles.titleContainer}>
        <h1>View All Stock Requests</h1>
        {
          authorized({
            viewer,
            role: Role.MANAGER,
          }) && (
            <Button
              type="primary"
              onClick={() => setAddStockRequestModalActive(true)}
            >
              Create Stock Request
            </Button>
          )
        }

      </div>

      <div className={styles.sectionContainer}>
        <Table
          columns={[
            {
              title: 'Date',
              dataIndex: 'updatedAt',
              key: 'updatedAt',
              render: formatDateAndTime,
            },
            {
              title: 'User',
              dataIndex: 'user',
              key: 'user',
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              render: (user: any) => `${user.firstName} ${user.lastName}`,
              filters: [
                ...(
                  users?.users?.items ? users.users.items.map((user) => ({
                    text: `${user.firstName} ${user.lastName}`,
                    value: user.id,
                  })) : []
                ),
              ],
              filterSearch: true,
              filterMultiple: false,
            },
            {
              title: 'Status',
              dataIndex: 'status',
              key: 'status',
              render: (item: string) => {
                switch (item) {
                  case StockRequestStatus.INFORMATION_REQUESTED:
                    return <Text type="warning">{sentenceCase(item)}</Text>;
                  case StockRequestStatus.REJECTED:
                    return <Text type="danger">{sentenceCase(item)}</Text>;
                  case StockRequestStatus.APPROVED:
                    return <Text type="success">{sentenceCase(item)}</Text>;
                  default:
                    return <Text>{sentenceCase(item)}</Text>;
                }
              },
              filters: [
                ...(
                  Object.values(StockRequestStatus).map((item) => ({
                    text: sentenceCase(item),
                    value: item,
                  }))
                ),
              ],
              filterSearch: true,
              filterMultiple: false,
            },
            {
              title: 'Total Items',
              dataIndex: 'lineItems',
              key: 'lineItems',
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              render: (lineItems: any) => lineItems.length,
            },
            {
              title: 'Total Cost',
              dataIndex: 'total',
              key: 'total',
              render: (total: number) => `£${formatLargeNumbers(total)}`,
            },
            authorized({
              viewer,
              role: Role.MANAGER,
            })
              ? {
                title: 'Actions',
                key: 'actions',
                render: (stockRequest: { id: string }) => (
                  <Space>
                    <Button
                      type="link"
                      onClick={() => setAddStockRequestModalActive(stockRequest.id)}
                    >
                      Edit
                    </Button>
                    <Popconfirm
                      title="Are you sure you want to delete this stock request?"
                      onConfirm={() => deleteTeam({ variables: { id: stockRequest.id } })}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="link">
                        <Text type="danger">
                          Delete
                        </Text>
                      </Button>
                    </Popconfirm>
                  </Space>
                ),
              }
              : {},
          ]}
          dataSource={data?.stockRequests?.items}
          scroll={{ x: 'max-content' }}
          loading={loading}
          pagination={{
            defaultPageSize: limit,
            defaultCurrent: page,
            total: data?.stockRequests?.total,
          }}
          onChange={handleTableChange}
          rowKey="id"
        />
        <div className={styles.linksContainer}>
          {
            authorized({
              viewer,
              access: Access.ALTER_STOCK_REQUESTS,
              role: Role.ADMIN,
            }) && (
              <React.Fragment>
                <Link to="/product-categories">
                  <Button
                    type="primary"
                  >
                    Product Categories
                  </Button>
                </Link>
                <Link to="/products">
                  <Button
                    type="primary"
                  >
                    Products
                  </Button>
                </Link>
              </React.Fragment>
            )
          }
        </div>
      </div>
      {
        addStockRequestModalActive && (
          <AddEditStockRequest
            active={addStockRequestModalActive}
            onClose={onClodeModal}
          />
        )
      }
    </Container>
  );
};
