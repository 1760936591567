/* eslint-disable no-nested-ternary */
import { useMutation, useQuery } from '@apollo/client';
import { Button, Popconfirm, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container } from '../../components';
import { DELETE_JOB, DELETE_KPI_EARLY_WARNING } from '../../lib/graphql/mutation/kpiEarlyWarning';
import { deleteKpiEarlyWarning, deleteKpiEarlyWarningVariables } from '../../lib/graphql/mutation/kpiEarlyWarning/__generated__/deleteKpiEarlyWarning';
import { EARLY_WARNINGS } from '../../lib/graphql/query/kpiEarlyWarnings';
import { EarlyWarning as EarlyWarningData } from '../../lib/graphql/query/kpiEarlyWarnings/__generated__/EarlyWarning';
import { displayErrorMessage, displaySuccessNotification } from '../../lib/utils';
import { earlyWarningsStyles } from './styles';
import { DeleteJob, DeleteJobVariables } from '../../lib/graphql/mutation/kpiEarlyWarning/__generated__/DeleteJob';
import { EarlyWarnings as EarlyWarningsData, EarlyWarningsVariables } from '../../lib/graphql/query/kpiEarlyWarnings/__generated__/EarlyWarnings';

export const EarlyWarnings: React.FC = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  // get :id
  const params = useParams();
  const styles = earlyWarningsStyles();
  const { data, refetch } = useQuery<EarlyWarningsData, EarlyWarningsVariables>(EARLY_WARNINGS, {
    variables: {
      ids: params.ids ? params.ids.split(';') : [],
      page,
      total: limit,
    },
  });
  const navigate = useNavigate();

  const [deleteKpi] = useMutation<deleteKpiEarlyWarning, deleteKpiEarlyWarningVariables>(DELETE_KPI_EARLY_WARNING, {
    onCompleted: (d) => {
      if (d.deleteKpiEarlyWarning) {
        displaySuccessNotification('You\'ve successfully deleted the Early Warning!');
        navigate('/kpi-early-warning');
      }
    },
    onError: () => {
      displaySuccessNotification('Failed to delete the Early Warning!');
    },
  });

  const [deleteJob] = useMutation<DeleteJob, DeleteJobVariables>(DELETE_JOB, {
    onCompleted: (d) => {
      if (d.deleteJob) {
        displaySuccessNotification('You\'ve successfully deleted the Job!');
        refetch();
      }
    },
    onError: () => {
      displayErrorMessage('Failed to delete the Job!');
    },
  });

  const rowClassName = (record: EarlyWarningData['earlyWarning']['items']) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    switch (record.warningStatus) {
      case 'fail':
        return styles.fail;
      case 'red':
        return styles.red;
      case 'amber':
        return styles.amber;
      default:
        return styles.pass;
    }
  };

  const column: ColumnsType<EarlyWarningData['earlyWarning']['items'][]> = [
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
      render: (date: string) => dayjs(date).format('DD/MM/YYYY'),
    },
    {
      title: 'Scheduled',
      dataIndex: 'scheduled',
      key: 'scheduled',
      render: (date: string) => (date ? dayjs(date).format('DD/MM/YYYY') : ''),
    },
    {
      title: 'Planned Start',
      dataIndex: 'plannedStart',
      key: 'plannedStart',
      render: (date: string) => (date ? dayjs(date).format('DD/MM/YYYY') : ''),
    },
    {
      title: 'Technician',
      dataIndex: 'technician',
      key: 'technician',
    },
    {
      title: 'Client',
      dataIndex: 'client',
      key: 'client',
    },
    {
      title: 'Job References',
      dataIndex: 'reference',
      key: 'reference',
    },
    {
      title: 'Job Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Time Left',
      dataIndex: 'timeLeft',
      key: 'timeLeft',
      render: (hours: number) => {
        if (
          hours > 72
        ) {
          return `${Math.floor(hours / 24)} days`;
        }
        return `${hours}h`;
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (record: any) => (
        <Popconfirm
          title="Are you sure delete this Job?"
          onConfirm={() => {
            deleteJob(
              {
                variables: {
                  id: record.id,
                },
              },
            );
          }}
        >
          <Button
            type="link"
            className={rowClassName(record)}
          >
            Delete
          </Button>

        </Popconfirm>
      ),
    },
  ];

  return (
    <Container className={styles.container}>
      <p>KPI Early Warning</p>
      <div className={styles.titleContainer}>
        <h1 className={styles.title}>{data?.earlyWarnings.name}</h1>
      </div>
      <div className={styles.tableContainer}>
        <Table
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          columns={column as any}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          dataSource={data?.earlyWarnings.items as any}
          rowKey="id"
          pagination={{
            defaultPageSize: limit,
            defaultCurrent: page,
            total: data?.earlyWarnings?.total,
            onChange(p, pageSize) {
              setPage(p);
              setLimit(pageSize);
            },
          }}
          rowClassName={rowClassName}
          className={
            styles.table
          }
        />
      </div>
      <div className={styles.deleteButtonContainer}>
        <Popconfirm
          title="Are you sure delete this Early Warning?"
          onConfirm={() => {
            deleteKpi({
              variables: {
                id: params.id || '',
              },
            });
          }}
          okText="Yes"
          cancelText="No"
        >
          <button
            type="button"
            className={styles.deleteButton}
          >
            Delete
          </button>
        </Popconfirm>
      </div>
    </Container>
  );
};
