import gql from 'graphql-tag';

export const GPC_LABOUT_COSTS = gql`
  query GpcLabourCosts {
    gpcLabourCosts {
      id
      name
      unit
      rate
      createdAt
      updatedAt
    }
  }
`;

export const GPC_EQUIPMENT = gql`
  query GpcEquipment($lookup: String) {
    gpcEquipment(lookup: $lookup) {
      total
      items {
        id
        name
        type
        code
        costPrice
        sellingPrice
        notes
        createdAt
        updatedAt
      }
    }
  }
`;

export const GPC_LABOUR_COST = gql`
  query GpcLabourCost($id: String!) {
    gpcLabourCost(id: $id) {
      id
      name
      unit
      rate
    }
  }
`;

export const GPC_PRICING_DOCUMENT = gql`
  query GpcPricingDocument {
    gpcPricingDocument
  }
`;
