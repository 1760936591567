import gql from 'graphql-tag';

export const CREATE_RISK_LEAD = gql`
  mutation CreateRiskLead($input: CreateRiskLeadInput!) {
    createRiskLead(input: $input) {
      id
    }
  }
`;

export const UPDATE_RISK_LEAD = gql`
  mutation UpdateRiskLead($input: UpdateRiskLeadInput!) {
    updateRiskLead(input: $input) {
      id
    }
  }
`;

export const DELETE_RISK_LEAD = gql`
  mutation DeleteRiskLead($id: ID!) {
    deleteRiskLead(id: $id) {
      id
    }
  }
`;
