import { createUseStyles } from 'react-jss';
import { base, colors, h3 } from '../../styles';

export const kpiEarlyWarningStyles = createUseStyles({
  container: {

  },
  title: {
    marginBottom: base(2),
  },
  objectivesContainer: {


  },
  innerContainer: {
    padding: base(2),
    marginTop: base(2),
    // 3 columns
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridGap: base(),
    rowGap: base(),
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    '& h1': {
      marginRight: base(),
      marginBottom: 0,
    },
    '& button': {
      height: base(2),
    },
  },
  kpiCard: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: colors.primary,
    color: colors.white,

    '& *': {
      color: colors.white,
    },
    padding: `${base()} ${base(2)}`,
    height: '100%',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  cardTitles: {
    ...h3,
  },
});
