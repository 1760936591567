import { createUseStyles } from 'react-jss';
import { base, overTitle } from '../../styles';

export const notificationsStyles = createUseStyles({
  container: {
  },
  overTitle: {
    ...overTitle,
  },
  sectionContainer: {
    marginTop: base(2),
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > h1': {
      marginRight: base(2),
    },
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: base(),
  },
});
