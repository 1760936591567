import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { CommissionStatus, OrderByDirection } from '../../lib/graphql/globalTypes';
import { DELETE_COMMISSION_PAYMENT } from '../../lib/graphql/mutation/commissionPayments';
import { DeleteCommissionPayment, DeleteCommissionPaymentVariables } from '../../lib/graphql/mutation/commissionPayments/__generated__/DeleteCommissionPayment';
import { UPDATE_COMMISSION } from '../../lib/graphql/mutation/commissions';
import { updateCommission as updateCommissionData, updateCommissionVariables } from '../../lib/graphql/mutation/commissions/__generated__/updateCommission';
import { COMMISSION } from '../../lib/graphql/query/commissions';
import { commission, commissionVariables } from '../../lib/graphql/query/commissions/__generated__/commission';
import { USERS } from '../../lib/graphql/query/users';
import { Users, UsersVariables } from '../../lib/graphql/query/users/__generated__/Users';
import { capitalCase, displayErrorMessage, displaySuccessNotification, formatDateAndTime, formatLargeNumbers } from '../../lib/utils';
import { modalWidth } from '../../styles/sizes';
import { AddCommissionPayment } from '../AddCommissionPayment';
import { editCommissionsStyles } from './styles';

interface Props {
  visible: boolean;
  id: string;
  onClose: () => void;
}

const { Item } = Form;
const { Text } = Typography;

export const EditCommissions: React.FC<Props> = ({
  visible,
  id,
  onClose,
}) => {
  const styles = editCommissionsStyles();
  const [addPaymentModalVisible, setAddPaymentModalVisible] = React.useState(false);

  const { data, refetch } = useQuery<commission, commissionVariables>(COMMISSION, {
    variables: {
      id,
    },
  });

  const { data: users } = useQuery<Users, UsersVariables>(USERS, {
    variables: {
      page: 1,
      total: 200,
      lookup: '',
      orderBy: {
        direction: OrderByDirection.ASC,
        field: 'firstName',
      },
    },
  });

  const [updateCommission] = useMutation<updateCommissionData, updateCommissionVariables>(UPDATE_COMMISSION, {
    onCompleted: (item) => {
      if (item.updateCommission) {
        displaySuccessNotification('You have successfully updated the commission!');
        onClose();
      }
    },
    onError(error) {
      if (error) {
        displayErrorMessage('Sorry! We were unable to update the commission. Please try again later!');
      }
    },
  });

  const [deleteCommissionPayment] = useMutation<DeleteCommissionPayment, DeleteCommissionPaymentVariables>(DELETE_COMMISSION_PAYMENT, {
    onCompleted: (item) => {
      if (item.deleteCommissionPayment) {
        displaySuccessNotification('You have successfully deleted the commission payment!');
        refetch();
      }
    },
    onError(error) {
      if (error) {
        displayErrorMessage('Sorry! We were unable to delete the commission payment. Please try again later!');
      }
    },
  });

  const [form] = Form.useForm();

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data.commission,
        userIds: data.commission?.users?.map((user) => user.id),
        createdAt: formatDateAndTime(data.commission.createdAt),
      });
    }
  }, [form, data]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (values: any) => {
    // eslint-disable-next-line no-param-reassign
    delete values.createdAt;

    updateCommission({
      variables: {
        id,
        input: {
          ...values,
        },
      },
    });
  };

  return (
    <Modal
      open={visible}
      title="Edit Commission"
      width={modalWidth}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
    >
      <div className={styles.container}>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Row gutter={24}>
            <Col
              span={12}
            >
              <Item
                name="reference"
                label="Reference"
              >
                <Input />
              </Item>
            </Col>
            <Col
              span={12}
            >
              <Item
                name="riskLeadReference"
                label="Risk Lead Reference"
              >
                <Input />
              </Item>
            </Col>
          </Row>
          <Item
            name="client"
            label="Client"
          >
            <Input />
          </Item>
          <Item
            name="status"
            label="Status"
          >
            <Select>
              {
                Object.keys(CommissionStatus).map((status) => (
                  <Select.Option
                    key={status}
                    value={status}
                  >
                    {capitalCase(status)}
                  </Select.Option>
                ))
              }
            </Select>
          </Item>
          <Item
            name="totalExVat"
            label="Total Ex Vat"
          >
            <InputNumber
              prefix="£"
              style={{ width: '100%' }}
            />
          </Item>
          <Item
            name="userIds"
            label="users"
          >
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Please select"
            >
              {
                users?.users?.items.map((user) => (
                  <Select.Option
                    key={user.id}
                    value={user.id}
                  >
                    {user.firstName}
                    {' '}
                    {user.lastName}
                  </Select.Option>
                ))
              }
            </Select>
          </Item>
          <Item
            name="createdAt"
            label="Created"
          >
            <Input disabled />
          </Item>
          <Item
            name="notes"
            label="Notes"
          >
            <Input.TextArea />
          </Item>
          <Item
            name="technicianNotes"
            label="Technician Notes"
          >
            <Input.TextArea />
          </Item>
        </Form>

        <div className={styles.paymentsContainer}>
          <h3>Scheduled Payments</h3>
          {
            !data?.commission.payments?.length ? (
              <div className={styles.noPayments}>
                <p>
                  No payments have been scheduled for this commission.
                </p>
              </div>
            ) : (
              <div className={styles.paymentsTable}>
                <table>
                  <thead>
                    <tr>
                      <th>Amount</th>
                      <th>Due Date</th>
                      <th>Payment Date</th>
                      <th>Status</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data?.commission.payments.map((payment) => (
                        <tr key={payment.id}>
                          <td>
                            £
                            {formatLargeNumbers(payment.amount)}
                          </td>
                          <td>
                            {dayjs(payment.date).format(
                              'MMM YYYY',
                            )}
                          </td>
                          <td>
                            {payment.user.firstName}
                            {' '}
                            {payment.user.lastName}
                          </td>
                          <td>
                            {dayjs(payment.date).isAfter(dayjs()) ? 'Pending' : 'Complete'}
                          </td>
                          <td>
                            <Popconfirm
                              title="Are you sure you want to delete this payment?"
                              onConfirm={() => {
                                deleteCommissionPayment({
                                  variables: {
                                    id: payment.id,
                                  },
                                });
                              }}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button
                                type="link"
                              >
                                <Text type="danger">
                                  Delete
                                </Text>
                              </Button>
                            </Popconfirm>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            )
          }
          <Button
            type="primary"
            onClick={() => setAddPaymentModalVisible(true)}
          >
            Add Payment
          </Button>

          {
            addPaymentModalVisible && (
              <AddCommissionPayment
                visible={addPaymentModalVisible}
                onClose={() => {
                  setAddPaymentModalVisible(false);
                  refetch();
                }}
                commissionId={id}
              />
            )
          }

        </div>
        <div className={styles.buttonContainer}>
          <Button
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          {
            data?.commission.status === CommissionStatus.PENDING
              && data?.commission.payments?.length
              ? (
                <Popconfirm
                  title="Do you want to schedule this commission?"
                  onConfirm={() => {
                    form.setFieldsValue({
                      status: CommissionStatus.SCHEDULED,
                    });
                    form.submit();
                  }}
                  okText="Yes"
                  cancelText="No"
                  onCancel={() => form.submit()}
                >
                  <Button
                    type="primary"
                  >
                    Save
                  </Button>
                </Popconfirm>
              ) : (

                <Button
                  type="primary"
                  onClick={() => form.submit()}
                >
                  Save
                </Button>
              )
          }
        </div>
      </div>
    </Modal>
  );
};
