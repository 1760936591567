import { useMutation } from '@apollo/client';
import { Col, Form, Input, Modal, Row, Select } from 'antd';
import React from 'react';
import { PrivateRoute } from '../../components';
import { Access, Region, Role } from '../../lib/graphql/globalTypes';
import { CREATE_USER } from '../../lib/graphql/mutation/users';
import { CreateUser, CreateUserVariables } from '../../lib/graphql/mutation/users/__generated__/CreateUser';
import { capitalCase, displayErrorMessage, displaySuccessNotification } from '../../lib/utils';
import { modalWidth } from '../../styles/sizes';
import { addUserStyles } from './styles';

interface Props {
  active: boolean;
  onClose: () => void;
}

const { Item } = Form;

export const AddUser: React.FC<Props> = ({
  active,
  onClose,
}) => {
  const styles = addUserStyles();
  const [form] = Form.useForm();
  const [createUser, { loading }] = useMutation<CreateUser, CreateUserVariables>(CREATE_USER, {
    onCompleted: (data) => {
      if (data.createUser) {
        displaySuccessNotification('User created successfully');
        form.resetFields();
        onClose();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Unable to create user');
      }
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (values: any) => {
    createUser({
      variables: {
        input: {
          ...values,
        },
      },
    });
  };

  return (
    <PrivateRoute
      role={Role.ADMIN}
      access={Access.ALTER_USERS}
    >
      <Modal
        title="Create New User"
        open={active}
        onCancel={onClose}
        onOk={() => form.submit()}
        confirmLoading={loading}
        width={modalWidth}
      >
        <div className={styles.container}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
          >
            <Row gutter={12}>
              <Col
                span={24}
                md={12}
              >
                <Item
                  name="firstName"
                  label="First Name"
                  required
                >
                  <Input />
                </Item>
              </Col>
              <Col
                span={24}
                md={12}
              >
                <Item
                  name="lastName"
                  label="Last Name"
                  required
                >
                  <Input />
                </Item>
              </Col>
            </Row>
            <Item
              name="jobTitle"
              label="Job Title"
            >
              <Input />
            </Item>
            <Item
              name="email"
              label="Email"
              required
              rules={[{
                type: 'email',
              }]}
            >
              <Input />
            </Item>
            <Item
              name="phoneNumber"
              label="Phone Number"
            >
              <Input />
            </Item>
            <Item
              name="password"
              label="Password"
              required
            >
              <Input.Password />
            </Item>
            <Row gutter={12}>
              <Col
                span={24}
                md={12}
              >
                <Item
                  name="region"
                  label="region"
                  required
                >
                  <Select showSearch>
                    {
                    Object.values(Region).map((region) => (
                      <Select.Option
                        key={region}
                        value={region}
                      >
                        {capitalCase(region)}
                      </Select.Option>
                    ))
                  }
                  </Select>
                </Item>
              </Col>
              <Col
                span={24}
                md={12}
              >
                <Item
                  name="role"
                  label="role"
                  required
                >
                  <Select>
                    {
                    Object.values(Role).map((role) => (
                      <Select.Option
                        key={role}
                        value={role}
                      >
                        {capitalCase(role)}
                      </Select.Option>
                    ))
                  }
                  </Select>
                </Item>
              </Col>
            </Row>
            <h3>Bigchange Integration</h3>
            <Row gutter={12}>
              <Col
                span={24}
                md={12}
              >
                <Item
                  name="resourceLabel"
                  label="Resource Label"
                >
                  <Input />
                </Item>
              </Col>
              <Col
                span={24}
                md={12}
              >
                <Item
                  name="resourceReference"
                  label="Resource Reference"
                >
                  <Input />
                </Item>
              </Col>
            </Row>
            <p>Ensure that the Resource Label is the exact same spelling that the technician has on Bigchange</p>
          </Form>
        </div>
      </Modal>
    </PrivateRoute>
  );
};
