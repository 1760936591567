import { Pie, PieConfig } from '@ant-design/plots';
import { Datum } from '@antv/g2plot';
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { base, colors } from '../../../../styles';

interface Props {
  veryHigh: number | undefined
  high: number | undefined
  medium: number | undefined
  low: number | undefined
}

export const SiteRiskBarPlot: React.FC<Props> = ({ veryHigh, high, medium, low }) => {
  const total = (veryHigh || 0) + (high || 0) + (medium || 0) + (low || 0);

  const data: Datum[] = [
    {
      rating: 'Very High',
      value: veryHigh,
    },
    {
      rating: 'High',
      value: high,
    },
    {
      rating: 'Medium',
      value: medium,
    },
    {
      rating: 'Low',
      value: low,
    },
  ];
  const config: PieConfig = {
    data,
    style: {
      height: base(10),
      padding: base(0.5),
    },
    color: ({ rating }: Datum) => {
      switch (rating) {
        case 'Very High':
          return colors.black;
        case 'High':
          return colors.red;
        case 'Medium':
          return colors.amber;
        case 'Low':
          return colors.primary;
        default:
          return colors.grey;
      }
    },
    angleField: 'value',
    colorField: 'rating',
    radius: 1,
    innerRadius: 0.5,
    label: {
      type: 'outer',
      offset: '-30%',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      content: function content(_ref: any) {
        return `${((_ref.value / total) * 100).toFixed(0)}%`;
      },
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    // interactions: [
    //   {
    //     type: 'element-single-selected',
    //     cfg: {
    //       // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //       start: [{ trigger: 'element:click', action: (node: any) => { setSelected(node.event.data.data.rating); } }],
    //     },

    //   },
    // ],
  };
  return <Pie {...config} />;
};
