import gql from 'graphql-tag';

export const CREATE_COMMISSION = gql`
  mutation CreateCommission($input: CreateCommissionInput!) {
    createCommission(input: $input) {
      id
    }
  }
`;


export const UPDATE_COMMISSION = gql`
  mutation updateCommission($id: ID!, $input: UpdateCommissionInput!) {
    updateCommission(id: $id, input: $input) {
      id
    }
  }
`;

export const DELETE_COMMISSION = gql`
  mutation deleteCommission($id: ID!) {
    deleteCommission(id: $id) {
      id
    }
  }
`;

export const IMPORT_COMMISSIONS = gql`
  mutation ImportCommissions($input: [ImportCommissionInput!]!) {
    importCommissions(input: $input)
  }
`;

export const DUPLICATE_COMMISSION = gql`
  mutation DuplicateCommission($id: ID!) {
    duplicateCommission(id: $id) {
      id
    }
  }
`;
