import { createUseStyles } from 'react-jss';
import { base } from '../../styles';

export const UserDashboardStyles = createUseStyles({
  container: {

  },
  profileInfoContainer: {
    '& h1': {
      fontSize: base(1.5),
    },
    textAlign: 'center',
  },
  profileImageContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginRight: base(),
  },
  profileImage: {
    maxWidth: base(8),
  },
});
