import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Form, Input, Modal, Select, Table } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import { createKpiEarlyWarning, createKpiEarlyWarningVariables } from '../../lib/graphql/mutation/kpiEarlyWarning/__generated__/createKpiEarlyWarning';
import { Viewer } from '../../lib/types';
import { displayErrorMessage, displaySuccessNotification } from '../../lib/utils';
import { modalWidth } from '../../styles/sizes';
import { CREATE_KPI_EARLY_WARNING } from '../../lib/graphql/mutation/kpiEarlyWarning';
import { addEarlyWarningStyles } from './styles';
import { AddMetric } from './AddMetric';
import { ContactList, ContactListVariables } from '../../lib/graphql/query/misc/__generated__/ContactList';
import { CONTACT_LIST } from '../../lib/graphql/query/misc';
import { KpiEarlyWarningCategories } from '../../lib/graphql/query/kpiEarlyWarnings/__generated__/KpiEarlyWarningCategories';
import { KPI_EARLY_WARNING_CATEGORIES } from '../../lib/graphql/query/kpiEarlyWarnings';

interface Props {
  active: boolean;
  onClose: () => void;
  viewer: Viewer;
}

const { Item } = Form;

export const AddEarlyWarning: React.FC<Props> = ({
  active,
  onClose,
  viewer,
}) => {
  const styles = addEarlyWarningStyles();

  const [contactList, { data: contacts }] = useLazyQuery<ContactList, ContactListVariables>(CONTACT_LIST);

  const { data: categories } = useQuery<KpiEarlyWarningCategories>(KPI_EARLY_WARNING_CATEGORIES);

  const [createEarlyWarning] = useMutation<createKpiEarlyWarning, createKpiEarlyWarningVariables>(CREATE_KPI_EARLY_WARNING, {
    onCompleted: (d) => {
      if (d) {
        displaySuccessNotification('Early Warning added successfully');
        onClose();
      }
    },
    onError: (error) => {
      if (error) {
        displayErrorMessage('Unable to add Early Warning');
      }
    },
  });

  const [metrics, setMetrics] = useState<{
    jobType: string;
    metricType: string;
    amberHours: number;
    redHours: number;
    failHours: number;
  }[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleOnClose = () => {
    onClose();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (data: any) => {
    createEarlyWarning({
      variables: {
        input: {
          name: data.name,
          description: data.description,
          contactId: parseInt(data.contact, 10),
          categoryId: data.category,
          metrics,
        },
      },
    });
  };

  const [form] = useForm();

  const columns = [
    {
      title: 'Job Type',
      dataIndex: 'jobType',
      key: 'jobType',
    },
    {
      title: 'Metric Type',
      dataIndex: 'metricType',
      key: 'metricType',
    },
    {
      title: 'metric',
      children: [
        {
          title: 'Amber Hours',
          dataIndex: 'amberHours',
          key: 'amberHours',
        },
        {
          title: 'Red Hours',
          dataIndex: 'redHours',
          key: 'redHours',
        },
        {
          title: 'Fail Hours',
          dataIndex: 'failHours',
          key: 'failHours',
        },
      ],
    },
  ];

  return (
    <Modal
      title="Add Early Warning"
      open={Boolean(active)}
      onCancel={handleOnClose}
      onOk={() => form.submit()}
      width={modalWidth}
    >
      <div className={styles.container}>
        <Form
          layout="vertical"
          form={form}
          onFinish={onSubmit}
        >
          <Item
            name="name"
            label="Name"
          >
            <Input />
          </Item>
          <Item
            name="contact"
            label="Contact"
          >
            <Select
              showSearch
              placeholder="Search for contact"
              optionFilterProp="children"
              onSearch={(value) => {
                if (value.length > 2) {
                  contactList({
                    variables: {
                      lookup: value,
                    },
                  });
                }
              }}
            >
              {
                contacts?.contactList && contacts.contactList.map((c) => (
                  <Select.Option
                    key={c.id}
                    value={c.id}
                  >
                    {c.label}
                  </Select.Option>
                ))
              }
            </Select>
          </Item>
          <Item
            name="description"
            label="Description"
          >
            <Input />
          </Item>
          <Item
            name="category"
            label="Category"
          >
            <Select
              showSearch
              placeholder="Select category"
              optionFilterProp="children"
            >
              {
                categories?.kpiEarlyWarningCategories && categories.kpiEarlyWarningCategories.map((c) => (
                  <Select.Option
                    key={c.id}
                    value={c.id}
                  >
                    {c.name}
                  </Select.Option>
                ))
              }
            </Select>
          </Item>
          <Table
            columns={columns}
            size="small"
            className={styles.table}
            dataSource={metrics}
            pagination={false}
          />
          <Button
            type="primary"
            onClick={() => setModalOpen(true)}
          >
            Add Metric
          </Button>
        </Form>
        {
          modalOpen && (
            <AddMetric
              active={modalOpen}
              onClose={(values) => {
                if (!values) {
                  setModalOpen(false);
                  return;
                }
                setMetrics([...metrics, values]);
                setModalOpen(false);
              }}
            />
          )
        }
      </div>
    </Modal>
  );
};
