import { createUseStyles } from 'react-jss';
import { base, colors } from '../../styles';

export const commentsStyles = createUseStyles({
  container: {
    borderTop: `1px solid ${colors.light}`,
    paddingTop: base(),
  },
  comments: {
    maxHeight: base(15),
    overflowY: 'auto',
    paddingRight: base(0.5),
  },
  comment: {

  },
  commentHeader: {


  },
  commentUser: {

  },
  commentDate: {
    '& p': {
      fontSize: base(0.6),
      lineHeight: base(0.6),
    },
  },
  commentMessage: {
    // bubble
    padding: base(0.5),
    borderRadius: base(0.5),
    backgroundColor: '#f5f5f5',
    marginTop: base(0.5),
  },
  commentRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    '& .commentMessage': {
      backgroundColor: colors.primary,
      '& p': {
        color: colors.white,
      },
    },
  },
  commentLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  composeMessageForm: {
    marginTop: base(1),
    '& .invx-form-item': {
      marginBottom: base(0.3),
    },
  },
  composeMessageButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});
