import { createUseStyles } from 'react-jss';
import { base, colors } from '../../../../../styles';

export const infoCardStyles = createUseStyles({
  container: {
    background: colors.white,
    padding: `${base(2)} ${base()}`,

    height: '100%',
  },
  icon: {
    width: base(2),
    height: base(2),
    background: colors.primary,
    color: colors.white,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    borderRadius: '50%',

    marginBottom: base(0.5),
  },
});
