import { createUseStyles } from 'react-jss';
import { colors } from './styles';

export const appStyles = createUseStyles({
  container: {
    flexDirection: 'row',
    height: '100%',
  },
  innerContainer: {
    display: 'flex',
    width: 'calc(100vw - 300px)',
  },
  contentContainer: {
    flex: 1,
    backgroundColor: colors.light,
  },
});
