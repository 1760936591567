import { createUseStyles } from 'react-jss';
import { base, colors } from '../../styles';

export const earlyWarningsStyles = createUseStyles({
  container: {
    maxWidth: 'unset',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    '& h1': {
      marginRight: base(),
      marginBottom: 0,
    },
    '& button': {
      height: base(2),
    },
    marginBottom: base(2),
  },
  title: {
    marginBottom: base(2),
  },
  tableContainer: {
    marginBottom: base(),
  },
  redColumn: {
    backgroundColor: `${colors.red} !important`,

    '&:hover': {
      backgroundColor: colors.red,
    },
    color: colors.white,
  },
  deleteButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  deleteButton: {

  },
  fail: {
    backgroundColor: colors.black,
    color: colors.white,
    '& *': {
      color: colors.white,
    },
    '&:hover': {
      backgroundColor: colors.black,
      color: colors.white,
      '& *': {
        color: colors.white,
      },
    },
  },
  red: {
    backgroundColor: colors.red,
    color: colors.white,
    '& *': {
      color: colors.white,
    },
    '&:hover': {
      backgroundColor: colors.red,
      color: colors.white,
      '& *': {
        color: colors.white,
      },
    },
  },
  amber: {
    backgroundColor: colors.amber,
    color: colors.white,
    '& *': {
      color: colors.white,
    },
    '&:hover': {
      backgroundColor: colors.amber,
      color: colors.white,
      '& *': {
        color: colors.white,
      },
    },
  },
  pass: {
    backgroundColor: colors.primary,
    color: colors.white,
    '& *': {
      color: colors.white,
    },
    '&:hover': {
      backgroundColor: colors.primary,
      color: colors.white,
      '& *': {
        color: colors.white,
      },
    },
  },
  table: {
    '& .invx-table-cell-row-hover': {
      backgroundColor: colors.white,
      color: colors.black,
    },

    '& .invx-table-tbody .invx-table-cell': {
      padding: `0 ${base(0.5)}`,
    },
  },
});
