import { Col, Row } from 'antd';
import React from 'react';
import { Color } from '../../styles';
import { pairStatCardStyles } from './styles';

interface Props {
  icon?: React.ReactNode;
  title: string;
  value: string;
  subOne?: string;
  subTwo?: string;

  icon2?: React.ReactNode;
  title2: string;
  value2: string;
  subOne2?: string;
  subTwo2?: string;

  pairTitle: string;
  color?: Color;
}

export const PairStatCard: React.FC<Props> = ({
  icon,
  title,
  value,
  subOne,
  subTwo,
  icon2,
  title2,
  value2,
  subOne2,
  subTwo2,
  pairTitle,
  color = 'light',
}) => {
  const styles = pairStatCardStyles({ backgroundColor: color });

  return (
    <div className={styles.container}>
      <span className={styles.titleContainer}>
        {pairTitle}
      </span>

      <Row gutter={12}>
        <Col span={12}>
          <span className={styles.innerTitleContainer}>
            {icon}
            {title}
          </span>
          <h2>{value}</h2>
          <span className={styles.subValuesContainer}>
            <span>{subOne}</span>
            <span>{subTwo}</span>
          </span>
        </Col>
        <Col
          span={12}

        >
          <div className={styles.secondColContainer}>
            <span className={styles.innerTitleContainer}>
              {icon2}
              {title2}
            </span>
            <h2>{value2}</h2>
            <span className={styles.subValuesContainer}>
              <span>{subOne2}</span>
              <span>{subTwo2}</span>
            </span>
          </div>

        </Col>
      </Row>

    </div>
  );
};
