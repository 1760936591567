import { message, notification } from 'antd';

export const displaySuccessNotification = (
  // eslint-disable-next-line no-shadow
  message: string,
  description?: string,
) => notification.success({
  message,
  description,
  placement: 'topLeft',
  style: {
    marginTop: 50,
  },
});

export const displayErrorMessage = (error?: string) => message.error(error || 'Something went wrong! Please try again later.');
