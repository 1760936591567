import { useMutation, useQuery } from '@apollo/client';
import { Button, Popconfirm, Space, Table, Typography } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container } from '../../components';
import { Access, OrderByDirection } from '../../lib/graphql/globalTypes';
import { DELETE_PRODUCT } from '../../lib/graphql/mutation/products';
import { DeleteProduct, DeleteProductVariables } from '../../lib/graphql/mutation/products/__generated__/DeleteProduct';
import { PRODUCTS } from '../../lib/graphql/query/products';
import { Products as ProductsData, ProductsVariables } from '../../lib/graphql/query/products/__generated__/Products';
import { Viewer } from '../../lib/types';
import { authorized, displayErrorMessage, displaySuccessNotification } from '../../lib/utils';
import { AddEditProduct } from '../../modals';
import { productsStyles } from './styles';

interface Props {
  viewer: Viewer
}

const { Text } = Typography;

export const Products: React.FC<Props> = ({ viewer }) => {
  const styles = productsStyles();

  const [modalActive, setModalActive] = useState<boolean | string>(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [order, setOrder] = useState({ field: 'name', direction: OrderByDirection.ASC });
  const [lookup, setLookup] = useState('');
  const { data, loading, refetch } = useQuery<ProductsData, ProductsVariables>(PRODUCTS, {
    variables: {
      page,
      total: 20,
      orderBy: order,
      lookup,
    },
  });

  const [deleteProduct] = useMutation<DeleteProduct, DeleteProductVariables>(DELETE_PRODUCT, {
    onCompleted: (product) => {
      if (product.deleteProduct) {
        displaySuccessNotification('Product deleted successfully!');
        refetch();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Error deleting product');
      }
    },
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Make',
      dataIndex: 'make',
      key: 'make',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (price: number) => `£${price}`,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render: (category: {id: string, name: string}) => category.name,
    },
    authorized({
      viewer,
      access: Access.ALTER_STOCK_REQUESTS,
    }) ? {
        title: 'Actions',
        key: 'actions',
        render: (product: {id: string, name: string}) => (
          <Space>
            <Button
              type="link"
              onClick={() => setModalActive(product.id)}
            >
              Edit
            </Button>
            <Popconfirm
              title="Are you sure you want to delete this product?"
              onConfirm={() => deleteProduct({ variables: { id: product.id } })}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link">
                <Text type="danger">
                  Delete
                </Text>
              </Button>
            </Popconfirm>
          </Space>
        ),
      } : {},
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTableChange = (pagination: any, _filters: unknown, sorter: any) => {
    if (pagination?.current) {
      setPage(pagination.current);
    }
    if (pagination?.size) {
      setLimit(pagination.size);
    }
    if (sorter?.columnKey) {
      setOrder({
        field: sorter.columnKey,
        direction: sorter.order === 'ascend' ? OrderByDirection.ASC : OrderByDirection.DESC,
      });
    }
  };

  const onModalClose = () => {
    setModalActive(false);
    refetch();
  };

  return (
    <Container className={styles.container}>
      <Link to="/stock-requests">
        Go Back To Stock Requests
      </Link>
      <div className={styles.titleContainer}>
        <h1>View All Products</h1>
        {
            authorized({
              viewer,
              access: Access.ALTER_USERS,
            }) && (
              <Button
                type="primary"
                onClick={() => setModalActive(true)}
              >
                Create Product
              </Button>
            )
          }

      </div>

      <div className={styles.sectionContainer}>
        <div className={styles.filterContainer}>
          <div>
            <Search
              onSearch={(str: string) => setLookup(str)}
            />
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data?.products?.items}
          scroll={{ x: 'max-content' }}
          loading={loading}
          pagination={{
            defaultPageSize: limit,
            defaultCurrent: page,
            total: data?.products?.total,
          }}
          onChange={handleTableChange}
          rowKey="id"
        />
      </div>
      {
        modalActive && (
          <AddEditProduct
            active={modalActive}
            onClose={onModalClose}
          />
        )
      }
    </Container>
  );
};
