import { createUseStyles } from 'react-jss';
import { base, colors, queries } from '../../../styles';
import { headerHeight } from '../../../styles/sizes';

export const appHeaderStyles = createUseStyles({
  container: {
    backgroundColor: colors.white,
  },
  innerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: headerHeight,

    '& > *:not(:last-child)': {
      marginRight: base(0.25),
    },

    '& > span:not(:last-child)': {
      marginRight: base(),
    },
    padding: `${base(2)} ${base(2)}`,
    [queries.md]: {
      padding: `0 ${base()}`,
    },
  },
  icon: {
    color: colors.grey,
    fontSize: base(1.25),
    border: `2px solid ${colors.grey}`,
    borderRadius: '100%',
    padding: base(0.2),
  },
  burgerIcon: {
    color: colors.black,
    border: 0,
    padding: base(0.2),
    display: 'none',
    [queries.md]: {
      display: 'block',
    },
  },
  hasNotification: {
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      width: base(0.5),
      height: base(0.5),
      borderRadius: '100%',
      backgroundColor: colors.red,
    },
  },
});
